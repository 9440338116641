import React from "react";
import {ILeague} from "modules/types";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {LeagueNavigation} from "components/LeagueNavigation";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import {PRIVACY_TRANSLATION} from "modules/constants";
import {Exist} from "components/Exist";
import {IconTriangle} from "components/Icons/IconTriangle";
import {getDashedValue} from "modules/utils/helpers";

const Wrapper = styled("div")`
	padding: 12px 20px 12px 16px;
	background: #ffffff;
	border-radius: 4px;

	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.4px;
		text-transform: capitalize;
	}

	p {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.4px;
		text-transform: capitalize;

		&.privacy,
		&.rank {
			font-weight: 700;
		}
	}

	.league-navigation {
		font-size: 12px;
	}
`;

interface IProps {
	league: ILeague;
}

export const MyLeagueItem: React.FC<IProps> = ({league}) => {
	const {t} = useTranslation();
	const user = useSelector(getUser);
	const {id, name, chairman, privacy, overall_rank, position} = league;
	return (
		<Wrapper>
			<h2 className="title">{name}</h2>
			<p className="charmain">
				{t("leagues.page.commissioner")}: {chairman}
			</p>
			<Exist when={Boolean(overall_rank)}>
				<p className="rank">
					<span>{overall_rank}</span>
					<PositionIcon position={position} />
					<span>{getDashedValue(user?.username)}</span>
				</p>
			</Exist>
			<LeagueNavigation leagueId={id} />
			<p className="privacy">
				<span>{t(PRIVACY_TRANSLATION[privacy])}</span>
				<span> {t("leagues.page.league")}</span>
			</p>
		</Wrapper>
	);
};

const IconWrapper = styled("span")`
	padding: 0 6px;
`;

interface IIconProps {
	position: number;
}

const PositionIcon: React.FC<IIconProps> = ({position}) => {
	if (position === 0) {
		return (
			<IconWrapper>
				<span>-</span>
			</IconWrapper>
		);
	}

	if (position === 1) {
		return (
			<IconWrapper>
				<IconTriangle direction="top" />
			</IconWrapper>
		);
	}

	return (
		<IconWrapper>
			<IconTriangle direction="bottom" color="#DD0C0C" />
		</IconWrapper>
	);
};
