import React, {useCallback} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton, SheetCloseButton} from "components/Common";
import {IconClose} from "components/Icons";
import {Exist} from "components/Exist";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
	getContestAnswerById,
	getPredictionById,
	getPredictModalCompleteData,
} from "modules/selectors";
import {LanguagesShort} from "modules/enums";
import {getChipClassAccordingOutcome, getDashedValue} from "modules/utils/helpers";
import styled from "@emotion/styled";
import AssetsTopImage from "assets/img/icons/ModalMakePredictionCoins.png";
import {closePredictionCompleteModal} from "modules/actions";

const SBottomSheet = styled(BottomSheet)`
	min-height: 345px;
	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 12px;
	}
`;

const ChipsBlock = styled("div")`
	position: relative;
	background: url(${AssetsTopImage});
	background-size: 100% 100%;
	width: 205px;
	height: 112px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span,
	h4 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
	}

	h4 {
		font-size: 22px;
		margin-bottom: 6px;
	}
`;

const CalculationWrapper = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;
	margin: 16px 0;

	> div {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 9px 12px 8px 12px;
		gap: 2px;
		border-radius: 4px;

		h4 {
			text-align: center;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
		}

		p {
			text-align: center;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
		}

		&.bet {
			background: ${({theme}) => theme.background.black};
			color: ${({theme}) => theme.text.color.white};
		}

		&.won.incorrect {
			background: ${({theme}) => theme.actionColors.incorrect};
			color: ${({theme}) => theme.text.color.white};
		}

		&.won.correct {
			background: ${({theme}) => theme.actionColors.correct};
			color: ${({theme}) => theme.text.color.dark};
		}

		&.won.picked {
			background: ${({theme}) => theme.actionColors.picks};
			color: ${({theme}) => theme.text.color.dark};
		}
	}
`;

const PlusChipsBlock = styled("div")`
	padding: 3px 8px;
	position: absolute;
	background: #000000;
	border: 1px solid #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 21px;
	top: 4px;
	right: 18%;

	color: ${({theme}) => theme.text.color.white};
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
`;

export const PredictModalComplete: React.FC = () => {
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const {isOpen, predictionId} = useSelector(getPredictModalCompleteData);
	const answer = useSelector(getContestAnswerById)(predictionId);
	const pick = useSelector(getPredictionById)(Number(predictionId));

	const closeModal = useCallback(() => {
		dispatch(closePredictionCompleteModal());
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<SheetCloseButton onClick={closeModal}>
					<IconClose color="#000000" />
				</SheetCloseButton>

				<h2>{pick?.name[language]}</h2>
				<ChipsBlock>
					<h4>{getDashedValue(answer?.bet)}</h4>
					<span>{t("common.chips")}</span>

					<PlusChipsBlock>
						<Exist when={Boolean(pick?.multiplier)}>{pick?.multiplier}X</Exist>
						<Exist when={!pick}>-</Exist>
					</PlusChipsBlock>
				</ChipsBlock>

				<CalculationWrapper>
					<div className="bet">
						<h4>{t("game.prediction.bet")}</h4>
						<p>{getDashedValue(answer?.bet)}</p>
					</div>
					<div className={`won ${getChipClassAccordingOutcome(answer?.outcome)}`}>
						<h4>{t("game.prediction.won")}</h4>
						<p>{getDashedValue(answer?.outcome)}</p>
					</div>
				</CalculationWrapper>

				<PrimaryButton className="active" onClick={closeModal}>
					{t("common.close")}
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
