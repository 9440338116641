import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import ApiError from "./ApiError";
import {API_URL, JSON_URL, TOKEN_KEY} from "modules/constants";
import {
	IApiResponse,
	IBackdoorAuthRequest,
	IChecksums,
	IContactUsPayload,
	IContestAnswersResponse,
	ICreateLeaguePayload,
	IEditPickPayload,
	IFetchOverallRankingsPayload,
	IHelpSection,
	IJoinLeaguePayload,
	IJoinLeagueResponse,
	ILeagueEmailPayload,
	ILeagueForJoinResponse,
	ILeaguesFetchPayload,
	ILeagueUsersResponse,
	ILoginPayload,
	IMatchResponse,
	IMatchResult,
	IOverallRankingsResponse,
	IPickRequestPayload,
	IRegisterPayload,
	IRemoveLeagueUserPayload,
	IRound,
	IUpdateLeaguePayload,
	IUserChipsObject,
	IUserLeagueResponse,
	IUserLeaguesResponse,
	IUserResponse,
} from "modules/types";
import {CANCEL} from "redux-saga";
import {ISquad} from "modules/types/JSON";
import {withTimeStamp} from "modules/utils/helpers";
import {LanguagesShort} from "modules/enums";
import {SharedLanguageService} from "modules/utils/helpers/SharedLanguageService";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<IApiResponse>) => {
	const data = response?.data;
	const error = isObject(data)
		? {
				...data,
				errors: data.errors.map((e) => new ApiError(e.message, response?.status)),
		  }
		: {
				errors: [new ApiError(message, response?.status)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse));
};

export const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

export const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

const withLanguage = (path: string) => {
	return `${SharedLanguageService.getLanguage()}/${path}`;
};

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<IChecksums>(withTimeStamp("checksum.json")),
		matches: () => JSONClient.get<IMatchResponse>("matches.json"),
		squads: () => JSONClient.get<ISquad[]>("squads.json"),
		rounds: () => JSONClient.get<{items: IRound[]; totalCount: number}>("rounds.json"),

		faq: (lang: LanguagesShort) => JSONClient.get<IHelpSection>(`faq/${lang}.json`),
		terms: (lang: LanguagesShort) => JSONClient.get<IHelpSection>(`terms/${lang}.json`),
		prizes: () => JSONClient.get<IHelpSection>("prizes.json"),
		contactUs: (params: IContactUsPayload) =>
			APIClient.post<IApiResponse<unknown>>("contact", params),
	},
	Auth: {
		backdoorLogin: (data: IBackdoorAuthRequest) =>
			APIClient.post<IApiResponse<IUserResponse>>(withLanguage("auth/backdoor"), data),
		getUser: () => APIClient.get<IApiResponse<IUserResponse>>(withLanguage("user")),
		logout: () =>
			APIClient.post<IApiResponse<Record<string, unknown>>>(withLanguage("auth/logout")),
		login: () => APIClient.post<IApiResponse<unknown>>(withLanguage("auth/login")),
		loginInApp: (data: ILoginPayload) =>
			APIClient.get<IApiResponse<IUserResponse>>(
				withLanguage("auth/login/web"),
				{...data.params},
				{headers: {[TOKEN_KEY]: data.headers.token}}
			),
		registerSSO: (data: IRegisterPayload) =>
			APIClient.post<IApiResponse<IUserResponse>>(
				withLanguage("auth/register/sso"),
				{...data.params},
				{headers: {[TOKEN_KEY]: data.headers.token}}
			),
	},
	Picks: {
		getAnswersForContest: (id: number) =>
			APIClient.get<IApiResponse<IContestAnswersResponse>>(withLanguage(`answers/${id}`)),
		saveAnswer: (params: IPickRequestPayload) =>
			APIClient.post<IApiResponse<unknown>>(
				withLanguage(`answer/${params.contestId}`),
				params.pick
			),
		editAnswer: (params: IEditPickPayload) =>
			APIClient.post<IApiResponse<unknown>>(
				withLanguage(`answer/${params.propId}/update`),
				params.pick
			),
		getChips: (id: number) =>
			APIClient.get<IApiResponse<IUserChipsObject>>(withLanguage(`chips/${id}`)),
	},
	Leagues: {
		userLeagues: (params: ILeaguesFetchPayload) =>
			APIClient.get<IApiResponse<IUserLeaguesResponse>>(withLanguage("leagues"), params),
		leagueById: (id: number) =>
			APIClient.get<IApiResponse<IUserLeagueResponse>>(withLanguage(`league/${id}`)),
		create: (params: ICreateLeaguePayload) =>
			APIClient.post<IApiResponse<IUserLeagueResponse>>(withLanguage("league"), params),
		emailInvite: (params: ILeagueEmailPayload) =>
			APIClient.post<unknown>(withLanguage(`league/${params.leagueId}/invite`), {
				invites: params.invites,
			}),
		showForJoin: (params: IJoinLeaguePayload) =>
			APIClient.get<IApiResponse<ILeagueForJoinResponse>>(
				withLanguage("league/show-for-join"),
				params
			),
		joinLeague: (code: string) =>
			APIClient.get<IApiResponse<IJoinLeagueResponse>>(withLanguage(`league/${code}/join`)),
		leaveLeague: (id: number) => APIClient.post<unknown>(withLanguage(`league/${id}/leave`)),
		leagueUsers: (id: number) =>
			APIClient.get<IApiResponse<ILeagueUsersResponse>>(
				withLanguage(`league/${id}/league-users`)
			),
		leagueUserRemove: (params: IRemoveLeagueUserPayload) =>
			APIClient.post<unknown>(
				withLanguage(`league/${params.leagueId}/user/${params.userId}`)
			),
		updateLeague: (params: IUpdateLeaguePayload) =>
			APIClient.post<IApiResponse<unknown>>(
				withLanguage(`league/${params.leagueId}`),
				params.data
			),
	},
	Rankings: {
		overallOrLeague: (params: IFetchOverallRankingsPayload) =>
			APIClient.get<IApiResponse<IOverallRankingsResponse>>(
				withLanguage("ranking/overall"),
				params
			),
		celebrity: (params: IFetchOverallRankingsPayload) =>
			APIClient.get<IApiResponse<IOverallRankingsResponse>>(
				withLanguage("ranking/celebrity"),
				params
			),
		inVenue: (params: IFetchOverallRankingsPayload) =>
			APIClient.get<IApiResponse<IOverallRankingsResponse>>(
				withLanguage("ranking/invenue"),
				params
			),
	},
	Match: {
		result: (params: number) =>
			APIClient.get<IApiResponse<IMatchResult>>(withLanguage(`chips/results/${params}`)),
	},
};

export * from "./ApiError";

export default Api;
