import styled from "@emotion/styled";
import {TableBody, TableCell} from "@mui/material";

export const TBody = styled(TableBody)({
	"& .MuiTableRow-root": {
		background: "#FFFFFF",
		borderBottom: "1px solid #E6E6E6",
	},

	"& .MuiTableCell-root": {
		padding: "10px 8px",
		borderBottom: "none",
	},

	"& .MuiTableCell-root:first-of-type": {
		padding: "10px 8px 10px 18px",
	},
	"& .MuiTableCell-root:last-of-type": {
		padding: "10px 18px 10px 8px",
	},
});

export const CenteredCell = styled(TableCell)`
	text-align: center;
`;
