import React, {useCallback, useEffect, useState} from "react";
import {Table, TableCell, TableRow} from "@mui/material";
import {PrimaryButton, PureButton, TBody, THead} from "components/Common";
import {getLeaguesRequestStateByKey, getShowForJoin} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {ILeagueForJoin} from "modules/types";
import styled from "@emotion/styled";
import {joinToLeagueRequest} from "modules/actions";
import {Exist} from "components/Exist";
import {ButtonPreloader, LocalPreloader} from "components/Preloader";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const STableRow = styled(TableRow)`
	.join {
		text-align: right;
	}

	button {
		padding: 8px 0;
		background: #000000;
		border-color: #000000;
		border-radius: 4px;

		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		text-align: center;
		text-transform: uppercase;

		.loader {
			width: 14px !important;
			height: 14px !important;
		}
	}
`;

interface IRowProps {
	league: ILeagueForJoin;
}

const LeagueRow: React.FC<IRowProps> = ({league}) => {
	const {t} = useTranslation();
	const [localLoading, setLocalLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {isLoading, isSuccess} = useSelector(getLeaguesRequestStateByKey)("joinLeague");

	useEffect(() => {
		if (!isLoading) {
			setLocalLoading(false);
		}
	}, [isLoading]);

	useEffect(() => {
		if (isSuccess) {
			navigate("/leagues");
		}
	}, [isSuccess, navigate]);

	const joinLeagueHandler = useCallback(() => {
		setLocalLoading(true);
		dispatch(joinToLeagueRequest(league.code));
	}, [dispatch, league?.code]);

	return (
		<STableRow>
			<TableCell>{league.name}</TableCell>
			<TableCell className="join">{league.num_teams}</TableCell>
			<TableCell onClick={joinLeagueHandler}>
				<PrimaryButton>
					<Exist when={localLoading}>
						<ButtonPreloader />
					</Exist>
					<Exist when={!localLoading}>
						<span>{t("leagues.page.join")}</span>
					</Exist>
				</PrimaryButton>
			</TableCell>
		</STableRow>
	);
};

const Wrapper = styled("div")`
	background: #ffffff;

	.loader-wrapper {
		span {
			color: ${({theme}) => theme.text.color.dark};
		}
	}

	.position {
		text-align: right;
	}
`;

const LoadMoreButton = styled(PureButton)`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	text-decoration-line: underline;
	text-transform: uppercase;
	width: 100%;
	padding: 20px;
`;

interface IProps {
	onLoadMore: () => void;
}

export const LeagueJoinTable: React.FC<IProps> = ({onLoadMore}) => {
	const {t} = useTranslation();
	const {leagues, nextPage} = useSelector(getShowForJoin);
	const {isLoading} = useSelector(getLeaguesRequestStateByKey)("fetchJoinLeagues");

	const isLoadMoreVisible = !isLoading && nextPage;
	return (
		<Wrapper>
			<Table>
				<THead>
					<TableRow>
						<TableCell>{t("leagues.join.league_name")}</TableCell>
						<TableCell className="position">{t("leagues.join.players")}</TableCell>
						<TableCell> </TableCell>
					</TableRow>
				</THead>
				<TBody>
					{leagues.map((league) => (
						<LeagueRow key={league.id} league={league} />
					))}
				</TBody>
			</Table>
			<Exist when={isLoadMoreVisible}>
				<LoadMoreButton onClick={onLoadMore}>{t("common.load_more")}</LoadMoreButton>
			</Exist>
			<Exist when={isLoading}>
				<LocalPreloader />
			</Exist>
		</Wrapper>
	);
};
