import {createAction} from "redux-act";
import {
	IFetchLeagueRankingsPayload,
	IFetchOverallRankingsPayload,
	IOverallRankingsResponse,
} from "modules/types";

export const fetchOverallRankingsRequest = createAction<IFetchOverallRankingsPayload>();
export const fetchOverallRankingsSuccess = createAction<IOverallRankingsResponse>();
export const fetchOverallRankingsConcat = createAction<IOverallRankingsResponse>();
export const fetchOverallRankingsError = createAction();
export const overallRankingsClear = createAction();

export const fetchLeagueRankingsRequest = createAction<IFetchLeagueRankingsPayload>();
export const fetchLeagueRankingsSuccess = createAction<IOverallRankingsResponse>();
export const fetchLeagueRankingsConcat = createAction<IOverallRankingsResponse>();
export const fetchLeagueRankingsError = createAction();
export const leagueRankingsClear = createAction();

export const fetchCelebrityRankingsRequest = createAction<IFetchOverallRankingsPayload>();
export const fetchCelebrityRankingsSuccess = createAction<IOverallRankingsResponse>();
export const fetchCelebrityRankingsConcat = createAction<IOverallRankingsResponse>();
export const fetchCelebrityRankingsError = createAction();
export const celebrityRankingsClear = createAction();

export const fetchInVenueRankingsRequest = createAction<IFetchOverallRankingsPayload>();
export const fetchInVenueRankingsSuccess = createAction<IOverallRankingsResponse>();
export const fetchInVenueRankingsConcat = createAction<IOverallRankingsResponse>();
export const fetchInVenueRankingsError = createAction();
export const inVenueRankingsClear = createAction();

export const setRequestProcess = createAction();
export const clearRequestProcess = createAction();
