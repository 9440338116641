import React, {useCallback} from "react";
import {FSModal, FSModalBody, FSModalHeading, PureButton} from "components/Common";
import {IconClose} from "components/Icons";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {PredictTutorialButton} from "components/PredictTutorialButton";
import PicksImage1 from "assets/img/icons/ScoringModalPicks1.png";
import PicksImage2 from "assets/img/icons/ScoringModalPicks2.png";
import {useDispatch, useSelector} from "react-redux";
import {getIsScoringModalOpen} from "modules/selectors";
import {toggleScoringModal} from "modules/actions";

const CloseButton = styled(PureButton)`
	width: 24px;
	height: 24px;
	margin-left: auto;
`;

const Wrapper = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	color: ${({theme}) => theme.text.color.white};
	font-family: ${({theme}) => theme.text.fontFamily};
	text-align: center;
	padding: 0 20px 40px 20px;

	.title {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 12px;
	}

	.points {
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 40px;
	}

	.text {
		margin-bottom: 20px;

		b {
			font-weight: bold;
		}
	}

	button {
		margin: 28px auto 0 auto;
		max-width: 250px;
	}
`;

const PointsLabel = styled("div")`
	padding: 11px 19px;
	background: #ffffff;
	border: 1px solid #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 21px;
	width: 66px;
	margin: 0 auto;

	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 15px;
	text-align: center;
`;

const PicksImage = styled("img")`
	width: 204px;
	height: auto;
`;

export const ScoringModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getIsScoringModalOpen);

	const closeModal = useCallback(() => {
		dispatch(toggleScoringModal(false));
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<FSModal>
			<FSModalHeading>
				<CloseButton onClick={closeModal}>
					<IconClose color="#FFFFFF" />
				</CloseButton>
			</FSModalHeading>
			<FSModalBody>
				<Wrapper>
					<section>
						<h4 className="title">{t("game.scoring_modal.title")}</h4>
						{/*Disabled as data from LOCO*/}
						<p
							className="text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{__html: t("game.scoring_modal.text_1")}}
						/>
						<h2 className="points">100</h2>
					</section>
					<section>
						<p
							className="text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{__html: t("game.scoring_modal.text_2")}}
						/>
						<PredictTutorialButton
							customClass="picked"
							text={t("game.tour.prop")}
							points={50}
						/>
					</section>
					<section>
						{/*Disabled as data from LOCO*/}
						<p
							className="text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{__html: t("game.scoring_modal.text_3")}}
						/>
						<PointsLabel>5X</PointsLabel>
					</section>
					<section>
						{/*Disabled as data from LOCO*/}
						<p
							className="text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{__html: t("game.scoring_modal.text_4")}}
						/>
						<PicksImage src={PicksImage1} alt="picks" />
					</section>
					<section>
						{/*Disabled as data from LOCO*/}
						<p
							className="text"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{__html: t("game.scoring_modal.text_5")}}
						/>
						<PicksImage src={PicksImage2} alt="picks" />
					</section>
				</Wrapper>
			</FSModalBody>
		</FSModal>
	);
};
