import React from "react";
import styled from "@emotion/styled";

const SVG = styled("svg")`
	&.left {
		transform: rotate(-90deg);
	}

	&.right {
		transform: rotate(90deg);
	}

	&.bottom {
		transform: rotate(180deg);
	}
`;

interface IProps {
	direction?: "top" | "right" | "bottom" | "left";
	color?: string;
}

export const IconTriangle: React.FC<IProps> = ({direction = "top", color = "#0CDD63"}) => {
	return (
		<SVG
			className={direction}
			xmlns="http://www.w3.org/2000/svg"
			width="8"
			height="9"
			viewBox="0 0 8 9"
			fill="none">
			<path d="M4 0.5L8 8.5H0L4 0.5Z" fill={color} />
		</SVG>
	);
};
