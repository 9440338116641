import React from "react";
import huge from "assets/img/icons/chips/hugeStack.png";
import zero from "assets/img/icons/chips/zeroStack.png";
import small from "assets/img/icons/chips/smallStack.png";
import medium from "assets/img/icons/chips/mediumStack.png";
import xl from "assets/img/icons/chips/xlStack.png";
import large from "assets/img/icons/chips/largeStack.png";
import styled from "@emotion/styled";
import {useSelector} from "react-redux";
import {getUserChipsAccordingContest} from "modules/selectors";
import {get} from "lodash";

const chipsMapper = {
	200: huge,
	100: xl,
	60: large,
	30: medium,
	10: small,
	0: zero,
};

const getImageAccordingChips = (points: number): string => {
	// Reverse for sort from 200 -> 0 for correct check
	const key =
		Object.keys(chipsMapper)
			.reverse()
			.find((key) => points >= Number(key)) ?? "0";
	return get(chipsMapper, key, zero) as string;
};

const Chips = styled("img")`
	width: 97px;
	height: 45px;
`;
export const ChipsImage: React.FC = () => {
	const userChips = useSelector(getUserChipsAccordingContest);
	const image = getImageAccordingChips(userChips);

	return <Chips src={image} alt="chips" />;
};
