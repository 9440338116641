import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsHalfTimeModalOpen, getSelectedMatch, getUserChipsObject} from "modules/selectors";
import {BottomSheet, ModalBackdrop, PrimaryButton} from "components/Common";
import {toggleHalfTimeModal} from "modules/actions";
import styled from "@emotion/styled";
import AssetsTopImage from "assets/img/icons/ModalMakePredictionCoins.png";
import {useTranslation} from "react-i18next";
import {MatchStatus} from "modules/enums";
import {LocalStorageService} from "modules/utils/LocalStorageService";
import {HALF_TIME_KEY} from "modules/constants";

const ChipsBlock = styled("div")`
	position: relative;
	background: url(${AssetsTopImage});
	background-size: 100% 100%;
	width: 205px;
	height: 112px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span,
	h4 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
	}

	h4 {
		font-size: 22px;
		margin-bottom: 6px;
	}
`;

const Title = styled("h2")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 20px;
`;

const Text = styled("p")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	margin-top: 20px;
`;

const SBottomSheet = styled(BottomSheet)`
	padding-top: 95px;
	justify-content: space-between;
	align-items: center;
`;

const Section = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const HalfTimeModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const activeMatch = useSelector(getSelectedMatch);
	const isOpen = useSelector(getIsHalfTimeModalOpen);
	const lastHalfTimeId = Number(LocalStorageService.GET(HALF_TIME_KEY));

	useEffect(() => {
		if (lastHalfTimeId === activeMatch?.id) {
			return;
		}

		if (activeMatch?.status === MatchStatus.HALF_TIME) {
			dispatch(toggleHalfTimeModal(true));
			LocalStorageService.SET(HALF_TIME_KEY, activeMatch.id);
		}
	}, [lastHalfTimeId, dispatch, activeMatch]);

	const closeModal = useCallback(() => {
		dispatch(toggleHalfTimeModal(false));
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<Section>
					<Title>{t("game.result_modal.title")}</Title>
					<ChipsCounter />
				</Section>
				<PrimaryButton className="active" onClick={closeModal}>
					<span>{t("game.returning_user.play")}</span>
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};

const ChipsCounter = () => {
	const {t} = useTranslation();
	const {wonFirstHalf} = useSelector(getUserChipsObject);

	return (
		<React.Fragment>
			<ChipsBlock>
				<h4>{wonFirstHalf ?? 0}</h4>
				<span>{t("common.chips")}</span>
			</ChipsBlock>
			<Text>{t("game.result_modal.more_100_chips")}</Text>
		</React.Fragment>
	);
};
