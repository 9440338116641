import {IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {RequestStateType} from "modules/enums";

const getState = ({rankingsReducer}: IStore) => rankingsReducer;

export const getLeagueRanks = createSelector(getState, (state) => state.singleLeague);
export const getOverallRanks = createSelector(getState, (state) => state.overall);
export const getCelebrityRanks = createSelector(getState, (state) => state.celebrity);
export const getInVenueRanks = createSelector(getState, (state) => state.inVenue);
export const getRequestStateObject = createSelector(getState, (state) => ({
	isIdle: state.requestState === RequestStateType.Idle,
	isLoading: state.requestState === RequestStateType.Loading,
}));
