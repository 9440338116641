import React from "react";
import {useTour} from "@reactour/tour";
import {PredictionSuggest} from "modules/enums";
import {Exist} from "components/Exist";
import {IconChip} from "components/Icons";
import {getButtonClass, getChipColor} from "modules/utils/helpers";
import {Chip, PointsLabel, PredictButtonBlock} from "components/Common";

interface IProps {
	readonly text: string;
	readonly points: number;
	readonly customClass?: string;
}

const getTutorialButtonClass = (currentStep: number) => {
	if (currentStep === 2) {
		return {
			className: "picked",
			isPicked: true,
			suggestion: undefined,
			picksPoints: 10,
		};
	}

	if (currentStep === 4) {
		return {
			className: "correct",
			isPicked: false,
			suggestion: PredictionSuggest.Correct,
			picksPoints: 10,
		};
	}

	return {
		className: "",
		isPicked: false,
		suggestion: undefined,
		picksPoints: 0,
	};
};

export const PredictTutorialButton: React.FC<IProps> = ({text, points, customClass = ""}) => {
	const {currentStep} = useTour();
	const {className, suggestion, isPicked, picksPoints} = getTutorialButtonClass(currentStep);
	const pickedClass = getButtonClass(suggestion, isPicked);

	return (
		<PredictButtonBlock disableRipple className={`${pickedClass} ${className} ${customClass}`}>
			<Exist when={Boolean(picksPoints)}>
				<Chip>
					<IconChip color={getChipColor(picksPoints)} width={40} height={40} />
					<span>{picksPoints}</span>
				</Chip>
			</Exist>

			<PointsLabel>{points / 10}X</PointsLabel>
			<span>{text}</span>
		</PredictButtonBlock>
	);
};
