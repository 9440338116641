import {IUser, NullOrEmpty} from "modules/types";

export enum ConnextraType {
	HOME = "f2p_raiderspredictor_homepage",
	REGISTRATION_START = "f2p_raiderspredictor_regstart",
	REGISTRATION_CONFIRM = "f2p_raiderspredictor_regconfirm",
	LOGIN = "f2p_raiderspredictor_loggedin",
	PICKS_CONFIRM = "f2p_raiderspredictor_pickconfirm",
	SHARE = "f2p_raiderspredictor_shareclick",
}

const CONNEXTRA_URL = `https://us.connextra.com/dcs/tagController/tag/b81243639001/`;

export const createConnextraScriptTag = (type: ConnextraType, user: NullOrEmpty<IUser>) => {
	const accountId = user?.id;
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (accountId) {
		url.searchParams.append("AccountID", String(accountId));
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
