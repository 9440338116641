import styled from "@emotion/styled";
import {TextField} from "@mui/material";

export const Input = styled(TextField)({
	width: "100%",
	marginBottom: "20px",
	"& label": {
		color: "#FFFFFF",
	},
	"& input": {
		color: "#FFFFFF",
	},
	"& label.Mui-focused": {
		color: "#FFFFFF",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#FFFFFF",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			transition: "0.25s",
			borderColor: "#757575",
		},
		"&:hover fieldset": {
			borderColor: "#FFFFFF",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#FFFFFF",
		},
	},

	"& .Mui-disabled": {
		pointerEvents: "none",
		opacity: "0.75",
	},

	"& .Mui-disabled.MuiInputLabel-root": {
		color: "#757575",
	},

	"& .Mui-disabled fieldset": {
		borderColor: "#757575 !important",
		opacity: 1,
	},

	"& .Mui-disabled input": {
		color: "#757575",
		WebkitTextFillColor: "#757575",
	},

	// Select styles if you use it as <Input select>
	"& .MuiSvgIcon-root": {
		color: "#FFFFFF",
	},

	"& .MuiSelect-select": {
		color: "#FFFFFF",
	},

	//TextArea
	"& textarea": {
		color: "#FFFFFF",
	},
});
