import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconHelp: React.FC<IGeneralIconProps> = ({
                                                          width = 26,
                                                          height = 26,
                                                          color = '#000000'
                                                      }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width={width}
             height={height}
             viewBox="0 0 26 26">
            <path
                d="M13.0004 2.44445C7.17371 2.44445 2.44482 7.17334 2.44482 13C2.44482 18.8267 7.17371 23.5556 13.0004 23.5556C18.827 23.5556 23.5559 18.8267 23.5559 13C23.5559 7.17334 18.827 2.44445 13.0004 2.44445ZM14.0559 20.3889H11.9448V18.2778H14.0559V20.3889ZM16.2409 12.2083L15.2909 13.1795C14.5309 13.95 14.0559 14.5833 14.0559 16.1667H11.9448V15.6389C11.9448 14.4778 12.4198 13.4222 13.1798 12.6517L14.4887 11.3217C14.8793 10.9417 15.1115 10.4139 15.1115 9.83334C15.1115 8.67223 14.1615 7.72223 13.0004 7.72223C11.8393 7.72223 10.8893 8.67223 10.8893 9.83334H8.77816C8.77816 7.50056 10.6676 5.61112 13.0004 5.61112C15.3332 5.61112 17.2226 7.50056 17.2226 9.83334C17.2226 10.7622 16.8426 11.6067 16.2409 12.2083Z"
                fill={color}/>
        </svg>
    );
}
