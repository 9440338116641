import {createReducer} from "redux-act";
import {
	IErrorModalPayload,
	ILeagueRemoveUserModalAction,
	ILeagueRemoveUserModalState,
	IReducerAction,
	Nullable,
} from "modules/types";
import {
	closeErrorModal,
	closeRemoveLeagueUserModal,
	openErrorModal,
	openRemoveLeagueUserModal,
	showApiErrorModal,
	toggleHalfTimeModal,
	toggleInvitesModal,
	togglePredictSavedModal,
	toggleResultModal,
	toggleReturningUserModal,
	toggleScoringModal,
	toggleTutorialModal,
	toggleWelcomeModal,
} from "modules/actions";

interface IModalReducer {
	isTutorialModalOpen: boolean;
	isInvitesModalOpen: boolean;
	isPicksSavedModalOpen: boolean;
	isScoringModalOpen: boolean;
	isReturningUserModalOpen: boolean;
	isHalfTimeModalOpen: boolean;
	isResultModalOpen: boolean;
	isWelcomeModalOpen: boolean;
	errorModalData: Nullable<IErrorModalPayload>;
	removeLeagueUserData: ILeagueRemoveUserModalState;
}

type IModalReducerPayload<TP = null> = IReducerAction<IModalReducer, TP>;

const initialState: IModalReducer = {
	isTutorialModalOpen: false,
	isInvitesModalOpen: false,
	isPicksSavedModalOpen: false,
	isScoringModalOpen: false,
	isReturningUserModalOpen: false,
	isHalfTimeModalOpen: false,
	isResultModalOpen: false,
	isWelcomeModalOpen: false,
	errorModalData: null,
	removeLeagueUserData: {
		user: null,
		isOpen: false,
		leagueId: null,
	},
};

const onToggleTutorialModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isTutorialModalOpen: payload,
});

const onOpenErrorModal: IModalReducerPayload<IErrorModalPayload> = (state, payload) => ({
	...state,
	errorModalData: payload,
});

const onShowApiErrorModal: IModalReducerPayload<string> = (state, payload) => ({
	...state,
	errorModalData: {
		title: "Error",
		text: payload,
	},
});

const onCloseErrorModal: IModalReducerPayload = (state) => ({
	...state,
	errorModalData: null,
});

const onToggleInvitesModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isInvitesModalOpen: payload,
});

const onOpenRemoveLeagueUserModal: IModalReducerPayload<ILeagueRemoveUserModalAction> = (
	state,
	payload
) => ({
	...state,
	removeLeagueUserData: {
		isOpen: true,
		user: payload.user,
		leagueId: payload.leagueId,
	},
});

const onCloseRemoveLeagueUserModal: IModalReducerPayload = (state) => ({
	...state,
	removeLeagueUserData: {
		isOpen: false,
		user: null,
		leagueId: null,
	},
});

const onTogglePredictSavedModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isPicksSavedModalOpen: payload,
});

const onToggleScoringModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isScoringModalOpen: payload,
});

const onToggleReturningUserModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isReturningUserModalOpen: payload,
});

const onToggleHalfTimeModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isHalfTimeModalOpen: payload,
});

const onToggleResultModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isResultModalOpen: payload,
});

const onToggleWelcomeModal: IModalReducerPayload<boolean> = (state, payload) => ({
	...state,
	isWelcomeModalOpen: payload,
});

export const modalReducer = createReducer<IModalReducer>({}, initialState)
	.on(toggleTutorialModal, onToggleTutorialModal)
	.on(openErrorModal, onOpenErrorModal)
	.on(showApiErrorModal, onShowApiErrorModal)
	.on(closeErrorModal, onCloseErrorModal)
	.on(toggleInvitesModal, onToggleInvitesModal)
	.on(openRemoveLeagueUserModal, onOpenRemoveLeagueUserModal)
	.on(closeRemoveLeagueUserModal, onCloseRemoveLeagueUserModal)
	.on(togglePredictSavedModal, onTogglePredictSavedModal)
	.on(toggleScoringModal, onToggleScoringModal)
	.on(toggleReturningUserModal, onToggleReturningUserModal)
	.on(toggleHalfTimeModal, onToggleHalfTimeModal)
	.on(toggleResultModal, onToggleResultModal)
	.on(toggleWelcomeModal, onToggleWelcomeModal);
