import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons/PureButton";

export const ModalBackdrop = styled("div")`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	padding: 24px 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	z-index: 10;

	&.hidden {
		opacity: 0;
		z-index: -1;
		display: none;
	}
`;

export const Modal = styled("div")`
	position: relative;
	width: 100%;
	padding: 20px 12px;
	background: #ffffff;
	border-radius: 10px;
`;

export const CommonModalCloseButton = styled(PureButton)`
	position: absolute;
	right: 12px;
	top: 16px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ModalBody = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	h2.title {
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		text-transform: capitalize;
	}

	p.body-text {
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		padding: 0 12px;
	}
`;
