import {createAction} from "redux-act";
import {IBackdoorAuthRequest, ILoginPayload, IRegisterPayload, IUser} from "modules/types";

export const backdoorLoginUserRequest = createAction<IBackdoorAuthRequest>();
export const backdoorLoginUserSuccess = createAction<IUser>();

export const userCheckDone = createAction();

export const loginInApp = createAction<ILoginPayload>();
export const loginInAppSuccess = createAction<IUser>();
export const setUserRequested = createAction();

export const getUserRequest = createAction();
export const logoutUserRequest = createAction();
export const logoutUserSuccess = createAction();

export const registerRequest = createAction<IRegisterPayload>();
export const registerRequestSuccess = createAction<IUser>();
