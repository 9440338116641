import {SagaIterator} from "redux-saga";
import {call, delay, put, select} from "typed-redux-saga";
import Api from "modules/utils/Api";
import {
	fetchAnswersByContestIdRequest,
	fetchChecksumsSuccess,
	fetchMatchesRequest,
	fetchRoundsRequest,
	fetchRoundsSuccess,
	fetchSquadsSuccess,
	fetchUserChipsByMatch,
} from "modules/actions";
import * as Sentry from "@sentry/browser";
import {
	getActiveContest,
	getChecksums,
	getIsLiveScoringSubscribed,
	getSelectedMatch,
} from "modules/selectors";
import {ONE_MINUTE, THREE_MINUTES} from "modules/constants";
import {get} from "lodash";
import {Nullable} from "modules/types";
import {ContestStatus} from "modules/enums";
import {EmptyActionCreator} from "redux-act";

export const fetchSquadsSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.squads);
		yield* put(fetchSquadsSuccess(response));
	} catch (e) {
		console.log(e);
	}
};

export const subscribeAnswersSaga = function* (): SagaIterator {
	try {
		const isSubscribed = yield* select(getIsLiveScoringSubscribed);
		if (!isSubscribed) {
			yield* delay(ONE_MINUTE);
			yield* call(subscribeAnswersSaga);
			return;
		}

		const contest = yield* select(getActiveContest);
		if (
			contest &&
			![ContestStatus.Complete, ContestStatus.Canceled].includes(
				contest?.status || ContestStatus.Complete
			)
		) {
			yield* put(fetchAnswersByContestIdRequest(contest.id));
		}

		const match = yield* select(getSelectedMatch);
		if (match) {
			yield* put(fetchUserChipsByMatch(match.id));
		}

		yield* delay(THREE_MINUTES);
		yield* call(subscribeAnswersSaga);
	} catch (e) {
		console.log(e);
	}
};

const fetchesMap = {
	matches: fetchMatchesRequest,
	rounds: fetchRoundsRequest,
};

export const subscribeLiveScoringSaga = function* (): SagaIterator {
	try {
		const isSubscribed = yield* select(getIsLiveScoringSubscribed);
		if (!isSubscribed) {
			yield* delay(ONE_MINUTE);
			yield* call(subscribeLiveScoringSaga);
			return;
		}

		const checksums = yield* select(getChecksums);
		const newChecksums = yield* call(Api.JSON.checksums);

		const keys = Object.keys(newChecksums);
		for (const key of keys) {
			// !checksums[key] to not fetch it for first time as it fetches in main HOC
			if (checksums[key] === newChecksums[key] || !checksums[key]) {
				continue;
			}

			const action = get(fetchesMap, key, null) as Nullable<EmptyActionCreator>;
			if (action) {
				yield* put(action());
			}
		}

		yield* put(fetchChecksumsSuccess(newChecksums));
		yield* delay(ONE_MINUTE);
		yield* call(subscribeLiveScoringSaga);
	} catch (e) {
		const error = e as Error;
		console.log(error);
		Sentry.captureException(new Error(`Error while subscribe live scoring: ${error.message}`));
	}
};

export const fetchRoundsSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.rounds);
		yield* put(fetchRoundsSuccess(response.items));
	} catch (e) {
		console.log(e);
	}
};
