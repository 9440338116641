import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconMenu: React.FC<IGeneralIconProps> = ({
    width = 32,
    height = 24,
    color = "#FFFFFF"
                                                      }) => {
    return(
        <svg width={width} height={height} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="6.3335"
                  y1="6"
                  x2="25.6668"
                  y2="6"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"/>
            <line x1="6.3335"
                  y1="11.6667"
                  x2="25.6668"
                  y2="11.6667"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"/>
            <line x1="6.3335"
                  y1="17.3333"
                  x2="25.6668"
                  y2="17.3333"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"/>
        </svg>

    );
}
