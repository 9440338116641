import styled from "@emotion/styled";

export const LoadMoreWrapper = styled("div")`
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
`;

export const TableWrapper = styled("div")`
	padding-bottom: 80px;
`;
