import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsLogged} from "modules/selectors/User";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const isLoggedIn = useSelector(getIsLogged);
	const path = location.pathname + location.search;
	const mainPath = "/" + location.search;

	if (isLoggedIn) {
		return <Outlet />;
	}
	return <Navigate state={{from: path}} to={mainPath} />;
};
