import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import {IContactUsPayload} from "modules/types";
import {Input, PrimaryButton} from "components/Common";
import {MenuItem} from "@mui/material";
import {ContactUsQuestionType} from "modules/enums";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {serializeForm} from "modules/utils/helpers";
import {getContactUsRequestState} from "modules/selectors/Static";
import {Exist} from "components/Exist";
import {ButtonPreloader} from "components/Preloader";
import {sendContactUsRequest} from "modules/actions";

const Form = styled("form")`
	margin-top: 4px;
`;

export const ContactUs: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const user = useSelector(getUser);
	const {isSuccess, isLoadingOrSuccess, isIdle} = useSelector(getContactUsRequestState);

	const [formData, setFormData] = useState<IContactUsPayload>({
		name: user?.username ?? "",
		email: user?.email ?? "",
		type: "",
		question: "",
	});

	useEffect(() => {
		if (isSuccess) {
			setFormData({
				name: user?.username ?? "",
				email: user?.email ?? "",
				type: "",
				question: "",
			});
		}
	}, [isSuccess, user]);

	const onFormSubmit = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();

			dispatch(sendContactUsRequest(formData));
		},
		[formData, dispatch]
	);

	const onFormChange = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			const data = serializeForm<IContactUsPayload>(e.currentTarget);
			setFormData({
				...formData,
				...data,
			});
		},
		[formData]
	);

	const onSelectChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		},
		[formData]
	);

	return (
		<Form onSubmit={onFormSubmit} onChange={onFormChange}>
			<Input
				name="name"
				label={t("contact.form.name")}
				value={formData.name}
				type="text"
				disabled={Boolean(user)}
				required
			/>
			<Input
				name="email"
				label={t("contact.form.email")}
				value={formData.email}
				type="email"
				disabled={Boolean(user)}
				required
			/>
			<Input
				select
				name="type"
				value={formData.type}
				onChange={onSelectChange}
				label={t("contact.form.type")}
				required>
				<MenuItem value={ContactUsQuestionType.Account}>
					{t("contact.type.account")}
				</MenuItem>
				<MenuItem value={ContactUsQuestionType.Auth}>{t("contact.type.auth")}</MenuItem>
				<MenuItem value={ContactUsQuestionType.Manager}>
					{t("contact.type.manager")}
				</MenuItem>
				<MenuItem value={ContactUsQuestionType.Other}>{t("contact.type.other")}</MenuItem>
			</Input>
			<Input
				name="question"
				label={t("contact.form.question_title")}
				variant="outlined"
				placeholder={t("contact.form.question_placeholder")}
				multiline
				value={formData.question}
				rows={5}
				required={true}
			/>
			<PrimaryButton disabled={isLoadingOrSuccess} type="submit">
				<Exist when={isIdle}>{t("contact.form.submit")}</Exist>
				<Exist when={isLoadingOrSuccess}>
					<ButtonPreloader />
				</Exist>
			</PrimaryButton>
		</Form>
	);
};
