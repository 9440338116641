import {createAction} from "redux-act";
import {
	ICreateLeaguePayload,
	IJoinLeaguePayload,
	ILeague,
	ILeagueEmailPayload,
	ILeagueForJoinResponse,
	ILeagueRemoveUserModalAction,
	ILeaguesFetchPayload,
	ILeagueUser,
	ISingleLeague,
	ILeagueStateChangePayload,
	IUserLeaguesResponse,
	IUpdateLeaguePayload,
} from "modules/types";

export const toggleLeaguesRequestState = createAction<ILeagueStateChangePayload>();

export const fetchUserLeaguesRequest = createAction<ILeaguesFetchPayload>();
export const fetchUserLeaguesSuccess = createAction<IUserLeaguesResponse>();
export const fetchUserLeaguesConcat = createAction<IUserLeaguesResponse>();

export const fetchSingleLeagueRequest = createAction<number>();
export const fetchSingleLeagueSuccess = createAction<ISingleLeague>();

export const createLeagueRequest = createAction<ICreateLeaguePayload>();
export const createLeagueSuccess = createAction<ILeague>();

export const updateLeagueRequest = createAction<IUpdateLeaguePayload>();
export const updateLeagueSuccess = createAction();

export const skipLeagueCreateStepTwo = createAction();

export const sendLeagueEmailInviteRequest = createAction<ILeagueEmailPayload>();

export const showForJoinRequest = createAction<IJoinLeaguePayload>();
export const showForJoinSuccess = createAction<ILeagueForJoinResponse>();
export const showForJoinConcat = createAction<ILeagueForJoinResponse>();

export const joinToLeagueRequest = createAction<string>();

export const fetchLeagueUsersRequest = createAction<number>();
export const fetchLeagueUsersSuccess = createAction<ILeagueUser[]>();
export const fetchLeagueUsersClear = createAction();

export const removeLeagueUserRequest = createAction<ILeagueRemoveUserModalAction>();
export const removeLeagueUserSuccess = createAction();

export const leaveLeagueRequest = createAction<number>();
export const leaveLeagueSuccess = createAction();
