import React from "react";
import {IGeneralIconProps} from "modules/types";

export const IconFacebook: React.FC<IGeneralIconProps> = ({
	width = 10,
	height = 18,
	color = "#000000",
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 10 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.397 17.9972V9.8012H9.162L9.573 6.59217H6.397V4.54817C6.397 3.62217 6.655 2.98817 7.984 2.98817H9.668V0.12717C8.8487 0.0393595 8.0251 -0.00304055 7.201 0.000169447C4.757 0.000169447 3.079 1.49217 3.079 4.23117V6.58617H0.332031V9.7952H3.085V17.9972H6.397Z"
				fill={color}
			/>
		</svg>
	);
};
