import styled from "@emotion/styled";
import {TableRow} from "@mui/material";

export const STableRow = styled(TableRow)`
	//border-bottom: none !important;

	&.rank-1 {
		background: ${({theme}) => theme.tableColors.gold};
	}

	&.rank-2 {
		background: ${({theme}) => theme.tableColors.silver};
	}

	&.rank-3 {
		background: ${({theme}) => theme.tableColors.bronze};
	}

	&.rank-1,
	&.rank-2,
	&.rank-3 {
		border-bottom: none !important;
		.prize-icon {
			opacity: 1;
		}
	}

	.active {
		background: #f7f7f7;
	}

	.rank-1.active {
		background: #cfb572;
	}

	.rank-2.active {
		background: #bebaba;
	}

	.rank-3.active {
		background: #c39e78;
	}

	.prize-section {
		display: flex;
		padding-right: 0 !important;
	}

	.points {
		width: 100%;
		min-width: 72px;
		max-width: 72px;
		text-align: right;
	}

	.username {
		width: 100%;
		word-break: break-all;
	}

	.prize-icon {
		opacity: 0;
		margin-left: 8px;
		display: inline;
		vertical-align: sub;
	}

	td {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.4px;
		text-transform: capitalize;
		padding-top: 12px !important;
		padding-bottom: 12px !important;
	}

	&.user-row {
		position: fixed;
		bottom: 0;
		background: ${({theme}) => theme.background.black};
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		td {
			color: ${({theme}) => theme.text.color.white};
			border-bottom: none;
			min-width: 70px;
			width: 100%;

			&:first-of-type {
				max-width: 70px;
				padding: 10px 8px 10px 18px;
				border-bottom: none !important;
			}

			&:last-of-type {
				padding: 10px 18px 10px 8px;
			}
		}

		svg {
			display: none;
		}
	}
`;
