import {get} from "lodash";
import {FB_SHARE_URL} from "modules/constants";
import {ShareTarget, ShareType} from "modules/enums";
import {ISharing} from "modules/types/Sharing";

const getEncodedJson = <T>(object: T) => btoa(JSON.stringify(object));

export const getLinkForShare = <T>(object: T, type: ShareType) => {
	const encodedJson = getEncodedJson(object);

	switch (type) {
		case ShareType.League:
			return `${FB_SHARE_URL}league/${encodedJson}`;
		case ShareType.General:
			return `${FB_SHARE_URL}general/${encodedJson}`;
		default:
			return `${FB_SHARE_URL}general/${encodedJson}`;
	}
};

const prepareShareObject = (data: ISharing) => {
	let object = {
		t: getTimestamp(),
	};

	if (data.type === ShareType.League) {
		object = Object.assign(object, {
			leagueId: data.league?.id,
		});
	}
	return object;
};

const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const shareFacebook = (data: ISharing) => {
	const FB_API = get(window, "FB");

	if (!FB_API) {
		throw Error("No FB api found");
	}

	const object = prepareShareObject(data);

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object, data.type),
		},
		(response: unknown) => {
			console.log(response);
		}
	);
};

const shareTwitter = (data: ISharing) => {
	const object = prepareShareObject(data);
	const href = getLinkForShare(object, data.type);
	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(data.message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

const shareNative = async (data: ISharing) => {
	try {
		const object = prepareShareObject(data);
		const href = getLinkForShare(object, data.type);

		const shareData = {
			url: href,
			text: data.message,
		};

		await navigator.share(shareData);
	} catch (err) {
		const error = err as Error;
		console.warn(error.message);
	}
};

const shareMap = {
	[ShareTarget.Twitter]: shareTwitter,
	[ShareTarget.Facebook]: shareFacebook,
	[ShareTarget.Native]: shareNative,
} as const;

export const share = async (data: ISharing) => {
	const shareFunction = shareMap[data.target];

	if (shareFunction && typeof shareFunction === "function") {
		await shareFunction(data);
	}
};
