import styled from "@emotion/styled";

export const BottomSheet = styled("div")`
	min-height: 420px;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 48px 20px 20px 20px;
	background: #ffffff;
	border-radius: 28px 28px 0 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	transform: translateY(100%);
	transition: 0.5s;
	z-index: 12;

	&.open {
		transform: translateY(0);
	}
`;

export const BottomSheetHeading = styled("h3")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	text-align: center;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
`;
