import {styled} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export const CustomCheckbox = styled(Checkbox)({
	"&.MuiCheckbox-root": {
		color: "#FFFFFF",
	},

	"& .MuiSvgIcon-root": {
		fontSize: 28,
	},
});
