import {toggleReceiveChipsAnimation} from "modules/actions";
import {delay, put} from "typed-redux-saga";
import {SagaIterator} from "redux-saga";

export const playReceiveChipsAnimationSaga = function* (): SagaIterator {
	try {
		yield* put(toggleReceiveChipsAnimation(true));
		yield* delay(2500);
		yield* put(toggleReceiveChipsAnimation(false));
	} catch (e) {
		console.log(e);
	}
};
