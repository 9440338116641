import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled("a")`
	width: 100%;
	max-width: 300px;
	height: 250px;
	background: #222222;

	img {
		width: 100%;
		height: 100%;
	}
`;

interface IProps {
	image: string;
	alt: string;
}

export const ADContainer: React.FC<IProps> = ({image, alt}) => {
	const sponsorLink = "https://cox.com/fanzone";
	return (
		<Wrapper href={sponsorLink}>
			<img src={image} alt={alt} />
		</Wrapper>
	);
};
