import {createReducer} from "redux-act";
import {
	IContestAnswersResponse,
	IEditPickPayload,
	IPickRequestPayload,
	IPredictionReducer,
	IPredictionStateChangePayload,
	IReducerAction,
	IUserChipsObject,
} from "modules/types";
import {
	clearChips,
	closePredictionCompleteModal,
	closePredictionModal,
	editPredictionRequest,
	fetchAnswersByContestIdRequest,
	fetchAnswersByContestIdSuccess,
	fetchUserChipsByMatchSuccess,
	makePredictionRequest,
	makePredictionSuccess,
	openPredictionCompleteModal,
	openPredictionModal,
	togglePredictionRequestState,
} from "modules/actions";
import {RequestStateType} from "modules/enums";

type IPredictionReducerPayload<TP = null> = IReducerAction<IPredictionReducer, TP>;

const initialState: IPredictionReducer = {
	chips: {
		isCameBack: false,
		wonFirstHalf: null,
		wonSecondHalf: null,
		remainFirstHalf: null,
		remainSecondHalf: null,
	},
	answers: [],
	predictModalData: {
		isOpen: false,
		predictionId: null,
	},
	predictModalCompleteData: {
		isOpen: false,
		predictionId: null,
	},

	requestState: {
		answersFetch: RequestStateType.Idle,
		makePick: RequestStateType.Idle,
		chips: RequestStateType.Idle,
	},
};

const onTogglePredictionRequestState: IPredictionReducerPayload<IPredictionStateChangePayload> = (
	state,
	payload
) => ({
	...state,
	requestState: {
		...state.requestState,
		[payload.key]: payload.state,
	},
});

const onOpenPredictionModal: IPredictionReducerPayload<number> = (state, payload) => ({
	...state,
	predictModalData: {
		isOpen: true,
		predictionId: payload,
	},
});

const onClosePredictionModal: IPredictionReducerPayload = (state) => ({
	...state,
	predictModalData: {
		isOpen: false,
		predictionId: null,
	},
});

const onOpenPredictionCompleteModal: IPredictionReducerPayload<number> = (state, payload) => ({
	...state,
	predictModalCompleteData: {
		isOpen: true,
		predictionId: payload,
	},
});

const onClosePredictionCompleteModal: IPredictionReducerPayload = (state) => ({
	...state,
	predictModalCompleteData: {
		isOpen: false,
		predictionId: null,
	},
});

const onFetchAnswersByContestIdRequest: IPredictionReducerPayload<number> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		answersFetch: RequestStateType.Loading,
	},
});

const onFetchAnswersByContestIdSuccess: IPredictionReducerPayload<IContestAnswersResponse> = (
	state,
	payload
) => ({
	...state,
	answers: payload.items,
	requestState: {
		...state.requestState,
		answersFetch: RequestStateType.Idle,
	},
});

const onMakePredictionRequest: IPredictionReducerPayload<IPickRequestPayload | IEditPickPayload> = (
	state
) => ({
	...state,
	requestState: {
		...state.requestState,
		makePick: RequestStateType.Loading,
	},
});

const onMakePredictionSuccess: IPredictionReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		makePick: RequestStateType.Success,
	},
});

const onFetchUserChipsByMatchSuccess: IPredictionReducerPayload<IUserChipsObject> = (
	state,
	payload
) => ({
	...state,
	chips: payload,
});

const onClearChips: IPredictionReducerPayload = (state) => ({
	...state,
	chips: {
		isCameBack: false,
		wonFirstHalf: null,
		wonSecondHalf: null,
		remainFirstHalf: null,
		remainSecondHalf: null,
	},
});

export const predictionReducer = createReducer<IPredictionReducer>({}, initialState)
	.on(togglePredictionRequestState, onTogglePredictionRequestState)
	.on(openPredictionModal, onOpenPredictionModal)
	.on(closePredictionModal, onClosePredictionModal)
	.on(openPredictionCompleteModal, onOpenPredictionCompleteModal)
	.on(closePredictionCompleteModal, onClosePredictionCompleteModal)
	.on(fetchAnswersByContestIdRequest, onFetchAnswersByContestIdRequest)
	.on(fetchAnswersByContestIdSuccess, onFetchAnswersByContestIdSuccess)
	.on(makePredictionRequest, onMakePredictionRequest)
	.on(editPredictionRequest, onMakePredictionRequest)
	.on(makePredictionSuccess, onMakePredictionSuccess)

	.on(fetchUserChipsByMatchSuccess, onFetchUserChipsByMatchSuccess)
	.on(clearChips, onClearChips);
