import React, {useCallback} from "react";
import {
	Input,
	PrimaryButton,
	RadioElement,
	RadioLabel,
	RadioWrapper,
	SubTitle,
	TextBlock,
} from "components/Common";
import styled from "@emotion/styled";
import {LeaguePrivacy} from "modules/enums";
import {useTranslation} from "react-i18next";
import {MenuItem} from "@mui/material";
import {serializeForm} from "modules/utils/helpers";
import {ICreateLeaguePayload} from "modules/types";
import {useDispatch, useSelector} from "react-redux";
import {createLeagueRequest} from "modules/actions";
import {Exist} from "components/Exist";
import {ButtonPreloader} from "components/Preloader";
import {getLeaguesRequestStateByKey} from "modules/selectors/Leagues";
import {getScheduleRounds} from "modules/selectors";

const Wrapper = styled("div")``;

const SubmitButton = styled(PrimaryButton)`
	margin-top: 8px;
`;

export const LeagueCreateStepOne: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {isIdle, isLoading} = useSelector(getLeaguesRequestStateByKey)("createLeague");
	const rounds = useSelector(getScheduleRounds);

	const onFormSubmit = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			const data = serializeForm<ICreateLeaguePayload>(e.currentTarget);
			dispatch(createLeagueRequest(data));
		},
		[dispatch]
	);

	return (
		<Wrapper>
			<SubTitle>{t("leagues.create.step_1")}</SubTitle>
			<TextBlock>{t("leagues.create.step_1_title")}</TextBlock>
			<form onSubmit={onFormSubmit}>
				<Input
					name="name"
					label={t("leagues.form.create_league_name")}
					variant="outlined"
					required={true}
				/>
				<Input
					select
					name="start_round"
					label={t("leagues.form.start_round")}
					variant="outlined"
					defaultValue=""
					required={true}>
					{rounds.map((round) => (
						<MenuItem key={round.id} value={round.id}>
							{t("leagues.form.round", {X: round.id})}
						</MenuItem>
					))}
				</Input>

				<TextBlock>{t("leagues.privacy.privacy")}</TextBlock>
				<RadioWrapper defaultValue={LeaguePrivacy.Public} name="privacy">
					<RadioLabel
						value={LeaguePrivacy.Public}
						control={<RadioElement />}
						label={t("leagues.privacy.public")}
					/>
					<RadioLabel
						value={LeaguePrivacy.Private}
						control={<RadioElement />}
						label={t("leagues.privacy.private")}
					/>
				</RadioWrapper>

				<SubmitButton type="submit">
					<Exist when={isIdle}>{t("leagues.form.create")}</Exist>
					<Exist when={isLoading}>
						<ButtonPreloader />
					</Exist>
				</SubmitButton>
			</form>
		</Wrapper>
	);
};

export default LeagueCreateStepOne;
