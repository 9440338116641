import {createReducer} from "redux-act";
import {IMatch, IMatchReducer, IMatchResponse, IMatchResult, IReducerAction} from "modules/types";
import {ContestHalf, RequestStateType} from "modules/enums";
import {
	fetchMatchesRequest,
	fetchMatchesSuccess,
	fetchMatchResultClear,
	fetchMatchResultRequest,
	fetchMatchResultSuccess,
	setSelectedHalf,
	setSelectedMatch,
} from "modules/actions";

type IMatchReducerPayload<TP = null> = IReducerAction<IMatchReducer, TP>;

const initialState: IMatchReducer = {
	matches: [],
	totalMatches: 0,
	selectedMatch: null,
	selectedHalf: ContestHalf.First,
	matchResult: null,

	requestState: {
		fetch: RequestStateType.Idle,
		fetchResults: RequestStateType.Idle,
	},
};

const onFetchMatchesRequest: IMatchReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		fetch: RequestStateType.Loading,
	},
});

const onFetchMatchesSuccess: IMatchReducerPayload<IMatchResponse> = (state, payload) => ({
	...state,
	matches: payload.items,
	totalMatches: payload.totalCount,
	requestState: {
		...state.requestState,
		fetch: RequestStateType.Idle,
	},
});

const onSetSelectedMatch: IMatchReducerPayload<IMatch> = (state, payload) => ({
	...state,
	selectedMatch: payload,
});

const onSetSelectedHalf: IMatchReducerPayload<ContestHalf> = (state, payload) => ({
	...state,
	selectedHalf: payload,
});

const onFetchMatchResultRequest: IMatchReducerPayload<number> = (state, payload) => ({
	...state,
	requestState: {...state.requestState, fetchResults: RequestStateType.Loading},
});

const onFetchMatchResultSuccess: IMatchReducerPayload<IMatchResult> = (state, payload) => ({
	...state,
	matchResult: payload,
	requestState: {...state.requestState, fetchResults: RequestStateType.Idle},
});

const onFetchMatchResultClear: IMatchReducerPayload = (state) => ({
	...state,
	requestState: {...state.requestState, fetchResults: RequestStateType.Idle},
});

export const matchReducer = createReducer<IMatchReducer>({}, initialState)
	.on(fetchMatchesRequest, onFetchMatchesRequest)
	.on(fetchMatchesSuccess, onFetchMatchesSuccess)
	.on(setSelectedMatch, onSetSelectedMatch)
	.on(setSelectedHalf, onSetSelectedHalf)
	.on(fetchMatchResultRequest, onFetchMatchResultRequest)
	.on(fetchMatchResultSuccess, onFetchMatchResultSuccess)
	.on(fetchMatchResultClear, onFetchMatchResultClear);
