import {LanguagesShort} from "modules/enums";

export class SharedLanguageService {
	private static _language: LanguagesShort = LanguagesShort.English;

	public static getLanguage(): LanguagesShort {
		return this._language;
	}

	public static setLanguage(value: LanguagesShort) {
		this._language = value;
	}
}
