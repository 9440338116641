import React, {lazy} from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {Provider} from "react-redux";
import store from "modules/store";
import {ThemeProvider} from "@mui/material";
import {theme} from "assets/theme";

import "assets/fonts/fonts.css";
import "assets/css/reset.css";
import "assets/css/core.css";
import {retryFailLoad} from "modules/utils/LazyLoad";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {
	Header,
	HOCi18n,
	HOCLiveScoring,
	HOCModals,
	HOCTutorial,
	Menu,
	NotAuthOnlyRoute,
	Preloader,
	PrivateRoute,
	ScoringModal,
} from "components";
import {PREPROD_URL, PROD_URL, SENTRY_DOMAIN_ALLOWED, UAT_URL} from "modules/constants";
import {HOCMain} from "components/HOC/HOCMain";
import LeaguesSectionLayout from "components/LeagueSectionsLayout";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://7f758f7fe4894c54b7c2f1785cae0a5f@o151969.ingest.sentry.io/6415849",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: [UAT_URL, PREPROD_URL, PROD_URL, SENTRY_DOMAIN_ALLOWED],
	integrations: [
		new BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// const LoadingPage = lazy(retryFailLoad(() => import("pages/LoadingPage")));
const Register = lazy(retryFailLoad(() => import("pages/Register")));
const LogOutPage = lazy(retryFailLoad(() => import("pages/LogOutPage")));
const PredictPage = lazy(retryFailLoad(() => import("pages/PredictPage")));

const LeaguesPage = lazy(retryFailLoad(() => import("pages/Leagues")));
const LeagueCreatePage = lazy(retryFailLoad(() => import("pages/Leagues/CreateLeaguePage")));
const JoinLeaguePage = lazy(retryFailLoad(() => import("pages/Leagues/JoinLeaguePage")));

const LeagueStandingsPage = lazy(retryFailLoad(() => import("pages/Leagues/LeagueStandingsPage")));
const LeagueManagePage = lazy(retryFailLoad(() => import("pages/Leagues/LeagueManagePage")));
const LeagueInvitePage = lazy(retryFailLoad(() => import("pages/Leagues/LeagueInvitePage")));
const LeagueSettingsPage = lazy(retryFailLoad(() => import("pages/Leagues/LeagueSettingsPage")));
const LeagueAboutPage = lazy(retryFailLoad(() => import("pages/Leagues/LeagueAboutPage")));

const HelpPageLayout = lazy(retryFailLoad(() => import("pages/HelpPage")));

const DebugPage = lazy(retryFailLoad(() => import("pages/DebugPage")));
const BackdoorPage = lazy(retryFailLoad(() => import("pages/BackdoorPage")));
const PageNotFound = lazy(retryFailLoad(() => import("pages/PageNotFound")));

const LeaderboardLayout = lazy(retryFailLoad(() => import("pages/Leaderboards")));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<HOCi18n>
					<HOCMain>
						<HOCLiveScoring>
							<HOCModals>
								<HOCTutorial>
									<BrowserRouter>
										<React.Suspense fallback={<Preloader />}>
											<Header />
											<Menu />
											<Routes>
												<Route element={<NotAuthOnlyRoute />}>
													<Route path="/" element={<Register />} />
													<Route
														path="/register"
														element={<Register />}
													/>
													<Route
														path="/error"
														element={<PageNotFound />}
													/>
													<Route
														path="backdoor"
														element={<BackdoorPage />}
													/>
												</Route>

												<Route element={<PrivateRoute />}>
													<Route
														path="predict"
														element={<PredictPage />}
													/>
													<Route
														path="leaderboard"
														element={<LeaderboardLayout />}
													/>
													<Route
														path="leaderboard/:section"
														element={<LeaderboardLayout />}
													/>
													<Route
														path="leagues/create"
														element={<LeagueCreatePage />}
													/>
													<Route
														path="leagues/:id"
														element={<LeaguesSectionLayout />}>
														<Route
															path="standings"
															element={<LeagueStandingsPage />}
														/>
														<Route
															path="manage"
															element={<LeagueManagePage />}
														/>
														<Route
															path="invite"
															element={<LeagueInvitePage />}
														/>
														<Route
															path="settings"
															element={<LeagueSettingsPage />}
														/>
														<Route
															path="about"
															element={<LeagueAboutPage />}
														/>
													</Route>
													<Route
														path="leagues/join/:code"
														element={<JoinLeaguePage />}
													/>
													<Route
														path="leagues/join/"
														element={<JoinLeaguePage />}
													/>
													<Route
														path="leagues"
														element={<LeaguesPage />}
													/>
												</Route>

												<Route
													path="help/:section"
													element={<HelpPageLayout />}
												/>
												<Route path="help/" element={<HelpPageLayout />} />

												<Route path="debug" element={<DebugPage />} />
												<Route path="logout" element={<LogOutPage />} />
												<Route path="*" element={<PageNotFound />} />
											</Routes>
											<ScoringModal />
										</React.Suspense>
									</BrowserRouter>
								</HOCTutorial>
							</HOCModals>
						</HOCLiveScoring>
					</HOCMain>
				</HOCi18n>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
