import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getActiveContest, getSelectedHalf, getUserChipsObject} from "modules/selectors";
import {ContestHalf, ContestStatus} from "modules/enums";
import {NullOrEmpty} from "modules/types";
import React from "react";
import {Exist} from "components/Exist";
import styled from "@emotion/styled";

const ChipsCountWrapper = styled("div")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	text-transform: capitalize;

	h3 {
		font-weight: 700;
		font-size: 26px;
		line-height: 24px;
	}

	p {
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
	}
`;

export const ChipsCount = () => {
	const {t} = useTranslation();
	const {remainFirstHalf, remainSecondHalf, wonFirstHalf, wonSecondHalf} =
		useSelector(getUserChipsObject);
	const contest = useSelector(getActiveContest);
	const half = useSelector(getSelectedHalf);
	const isContestWon = [ContestStatus.Live, ContestStatus.Complete].includes(
		contest?.status || ContestStatus.Draft
	);

	const chipsOrZero = (item: NullOrEmpty<number>) => {
		return item ?? 0;
	};

	const chipsText = () => {
		return (
			<React.Fragment>
				<Exist when={isContestWon}>
					<span>{t("game.chips_won")}</span>
				</Exist>
				<Exist when={!isContestWon}>
					<span>{t("game.chips_available")}</span>
				</Exist>
			</React.Fragment>
		);
	};

	if (half === ContestHalf.First) {
		return (
			<ChipsCountWrapper>
				<Exist when={isContestWon}>
					<h3>{chipsOrZero(wonFirstHalf)}</h3>
				</Exist>
				<Exist when={!isContestWon}>
					<h3>{chipsOrZero(remainFirstHalf)}</h3>
				</Exist>
				{chipsText()}
			</ChipsCountWrapper>
		);
	}

	return (
		<ChipsCountWrapper>
			<Exist when={isContestWon}>
				<h3>{wonSecondHalf ?? 0}</h3>
			</Exist>
			<Exist when={!isContestWon}>
				<h3>{remainSecondHalf ?? 0}</h3>
			</Exist>
			{chipsText()}
		</ChipsCountWrapper>
	);
};
