import React, {useCallback, useEffect} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton, SheetCloseButton} from "components/Common";
import AssetsTopImage from "assets/img/icons/RemoveUserModalChipsImage.svg";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {closeRemoveLeagueUserModal, removeLeagueUserRequest} from "modules/actions";
import {getLeaguesRequestStateByKey, getRemoveLeagueUserModalData} from "modules/selectors";
import {IconClose} from "components/Icons";
import {Exist} from "components/Exist";
import {ButtonPreloader} from "components/Preloader";

const SBottomSheet = styled(BottomSheet)`
	gap: 12px;
	padding-top: 110px;

	img {
		max-width: 190px;
		margin-bottom: 14px;
	}

	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		align-items: center;
		text-align: center;
	}

	p {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		padding: 0 48px;
		line-height: 19px;
		text-align: center;
		margin-bottom: 12px;
	}

	button {
		width: 100%;
		margin-top: auto;
	}
`;

export const RemoveLeaguePlayerModal: React.FC = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const {user, leagueId, isOpen} = useSelector(getRemoveLeagueUserModalData);
	const {isLoading, isSuccess} = useSelector(getLeaguesRequestStateByKey)("removeLeagueUser");

	const closeModal = useCallback(() => {
		dispatch(closeRemoveLeagueUserModal());
	}, [dispatch]);

	const removeUserAccept = useCallback(() => {
		dispatch(removeLeagueUserRequest({user, leagueId}));
	}, [dispatch, user, leagueId]);

	useEffect(() => {
		if (isSuccess) {
			closeModal();
		}
	}, [isSuccess, closeModal]);

	if (!isOpen || !user) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<SheetCloseButton onClick={closeModal}>
					<IconClose color="#000000" />
				</SheetCloseButton>

				<img src={AssetsTopImage} alt="Info chips" />
				<h2>{t("leagues.remove_user.title")}</h2>
				<p>{t("leagues.remove_user.text_1", {X: user.username})}</p>
				<p>{t("leagues.remove_user.text_2")}</p>
				<p>{t("leagues.remove_user.text_3")}</p>

				<PrimaryButton className="active" onClick={removeUserAccept}>
					<Exist when={!isLoading}>
						<span>{t("leagues.remove_user.confirm")}</span>
					</Exist>
					<Exist when={isLoading}>
						<ButtonPreloader />
					</Exist>
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
