import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {subscribeAnswers, subscribeLiveScoring} from "modules/actions";
import {getIsLogged} from "modules/selectors";

interface IProps {
	children: React.ReactNode;
}

export const HOCLiveScoring: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const isLogged = useSelector(getIsLogged);

	useEffect(() => {
		if (!isLogged) {
			return;
		}

		dispatch(subscribeLiveScoring());
		dispatch(subscribeAnswers());
	}, [dispatch, isLogged]);

	return <React.Fragment>{children}</React.Fragment>;
};
