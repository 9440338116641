import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {LocalStorageService} from "modules/utils/LocalStorageService";
import {TUTORIAL_KEY} from "modules/constants";
import "assets/css/tour.css";
import {useTour} from "@reactour/tour";
import {toggleTutorialModal} from "modules/actions";

export const Tutorial: React.FC = () => {
	const dispatch = useDispatch();
	const {isOpen, setCurrentStep} = useTour();
	const isTutorialPlayed = LocalStorageService.GET(TUTORIAL_KEY) || false;

	useEffect(() => {
		if (!isTutorialPlayed) {
			window.scrollTo({top: 100, left: 0, behavior: "smooth"});
			dispatch(toggleTutorialModal(true));
		}
	}, [dispatch, isTutorialPlayed]);

	useEffect(() => {
		if (!isOpen) {
			setCurrentStep(0);
		}
	}, [setCurrentStep, isOpen]);

	return null;
};
