import {IBackdoorAuthRequest, ILoginPayload, IRegisterPayload, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {
	backdoorLoginUserSuccess,
	getUserRequest,
	loginInAppSuccess,
	logoutUserSuccess,
	registerRequestSuccess,
	setUserRequested,
	showApiErrorModal,
	userCheckDone,
} from "modules/actions";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/Analytics";

export const getUserSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.Auth.getUser);
		ApiError.CHECK(response);
		yield* put(backdoorLoginUserSuccess(response.success.user));
		createConnextraScriptTag(ConnextraType.LOGIN, response.success.user);
	} catch (e) {
		const error = e as ApiError;
		if (error.code !== 401) {
			yield* put(showApiErrorModal(error.message));
		}
	} finally {
		yield* put(userCheckDone());
	}
};

export const backdoorLoginUserSaga = function* ({
	payload,
}: ISagaAction<IBackdoorAuthRequest>): SagaIterator {
	try {
		const response = yield* call(Api.Auth.backdoorLogin, payload);
		ApiError.CHECK(response);
		yield* put(backdoorLoginUserSuccess(response.success.user));
	} catch (e: unknown) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(userCheckDone());
	}
};

export const logoutSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.Auth.logout);
		ApiError.CHECK(response);
		yield* put(logoutUserSuccess());
	} catch (e) {
		console.log(e);
	}
};

export const registerSaga = function* ({payload}: ISagaAction<IRegisterPayload>): SagaIterator {
	try {
		const response = yield* call(Api.Auth.registerSSO, payload);
		ApiError.CHECK(response);
		yield* put(registerRequestSuccess(response.success.user));
		createConnextraScriptTag(ConnextraType.REGISTRATION_CONFIRM, response.success.user);
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(userCheckDone());
	}
};

export const loginInAppSaga = function* ({payload}: ISagaAction<ILoginPayload>): SagaIterator {
	try {
		const response = yield* call(Api.Auth.loginInApp, payload);
		ApiError.CHECK(response);
		yield* put(loginInAppSuccess(response.success.user));
	} catch (e) {
		console.log(e);
	} finally {
		yield* put(setUserRequested());
		yield* put(getUserRequest());
	}
};
