import {IMatch, IMatchReducer, IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {memoize} from "lodash";
import {getDashedValue, parseStateType} from "modules/utils/helpers";
import {ContestStatus, MatchStatus} from "modules/enums";
import {
	DEFAULT_CHIPS_AMOUNT,
	DEFAULT_CHIPS_AMOUNT_WITH_RETURN,
	LIVE_MATCH_STATES,
	RAIDERS_ID,
} from "modules/constants";
import {getSquads} from "modules/selectors/JSON";

const getState = ({matchReducer}: IStore) => matchReducer;
const getPredictState = ({predictionReducer}: IStore) => predictionReducer;

export const getMatches = createSelector(getState, (state) => state.matches);
export const getMatchesCount = createSelector(getState, (state) => state.totalMatches);
export const getSelectedMatch = createSelector(getState, (state) => state.selectedMatch);
export const getSelectedHalf = createSelector(getState, (state) => state.selectedHalf);

export const getAvailableMatches = createSelector(getMatches, (matches) =>
	matches.filter((item) => {
		if (item.status === MatchStatus.CANCELED) {
			return true;
		}

		const firstContest = item.contests.firstHalf;
		const secondContest = item.contests.secondHalf;
		const firstDraft = firstContest?.status === ContestStatus.Draft;
		const secondDraft = secondContest?.status === ContestStatus.Draft;

		if (!firstContest && !secondContest) {
			return false;
		}

		if (!secondContest && firstDraft) {
			return false;
		}
		// Both contests draft
		return !(firstDraft && secondDraft);
	})
);

export const getActiveContest = createSelector(getSelectedMatch, getSelectedHalf, (match, half) => {
	if (!match || !half) {
		return null;
	}

	const halfPath = (half + "Half") as keyof IMatch["contests"];
	return match.contests[halfPath];
});

export const getMatchContests = createSelector(getSelectedMatch, (match) => ({
	first: match?.contests.firstHalf || null,
	second: match?.contests.secondHalf || null,
	firstDraft:
		match?.contests.firstHalf?.status === ContestStatus.Draft ||
		match?.contests.firstHalf == null,
	secondDraft:
		match?.contests.secondHalf?.status === ContestStatus.Draft ||
		match?.contests.secondHalf === null,
}));

export const getPropsForMatchAndHalf = createSelector(
	getSelectedMatch,
	getSelectedHalf,
	(match, half) => {
		const empty = {items: [], totalCount: 0};

		if (!match || !half) {
			return empty;
		}

		const halfPath = (half + "Half") as keyof IMatch["contests"];
		const contest = match.contests[halfPath];
		contest?.props.items.sort((a, b) => a.position - b.position);
		return contest?.props || empty;
	}
);
export const getMatchesNavigationData = createSelector(
	getAvailableMatches,
	getSelectedMatch,
	(matches, selectedMatch) => {
		const invalidData = {
			previousAvailable: false,
			nextAvailable: false,
		};

		if (matches.length === 0 || !selectedMatch) {
			return invalidData;
		}

		const activeMatchIndex = matches.findIndex((item) => item.id === selectedMatch.id);

		if (activeMatchIndex === -1) {
			return invalidData;
		}

		return {
			previousAvailable: activeMatchIndex !== 0,
			nextAvailable: activeMatchIndex !== matches.length - 1,
		};
	}
);

export const getActiveMatchStates = createSelector(getSelectedMatch, (match) => ({
	isLive: [MatchStatus.FIRST_HALF, MatchStatus.SECOND_HALF, MatchStatus.OVERTIME].includes(
		match?.status ?? MatchStatus.CANCELED
	),
	isSchedule: match?.status === MatchStatus.SCHEDULED,
	isHalfTime: match?.status === MatchStatus.HALF_TIME,
	isComplete: match?.status === MatchStatus.COMPLETE,
}));

export const getActiveMatchNextContest = createSelector(getSelectedMatch, (match) => {
	if (!match) {
		return null;
	}

	if (match.status === MatchStatus.SCHEDULED) {
		return match.contests.firstHalf;
	}
	if (match.status === MatchStatus.HALF_TIME) {
		return match.contests.secondHalf;
	}

	return null;
});

export const getContestStateTypeByKey = createSelector(getState, (states) =>
	memoize((key: keyof IMatchReducer["requestState"]) => parseStateType(states.requestState[key]))
);

export const getIsMatchLive = createSelector(getSelectedMatch, (match) => {
	if (!match) {
		return false;
	}

	return LIVE_MATCH_STATES.includes(match.status);
});

export const getIsActiveContestLocked = createSelector(getActiveContest, (contest) => {
	if (!contest) {
		return {
			isComplete: true,
			isActive: false,
			isOpen: false,
		};
	}

	return {
		isComplete: contest.status === ContestStatus.Complete,
		isActive: contest.status === ContestStatus.Live,
		isOpen: contest.status === ContestStatus.Open,
	};
});

export const getMatchResult = createSelector(getState, (state) => state.matchResult);
export const getOpponentByRoundId = createSelector(getMatches, getSquads, (matches, squads) =>
	memoize((roundId: number) => {
		const match = matches.find((item) => item.roundId === roundId);
		if (!match) {
			return {
				opponentName: "",
				symbol: "",
			};
		}

		const isAway = Number(RAIDERS_ID) === match.awaySquad;
		const opponentId = isAway ? match.homeSquad : match.awaySquad;
		const opponentSquad = squads.find((item) => item.id === opponentId);

		return {
			opponentName: getDashedValue(opponentSquad?.shortName),
			symbol: isAway ? "@" : "vs",
		};
	})
);

export const getIsStatsVisible = createSelector(
	getSelectedMatch,
	getPredictState,
	(activeMatch, predictState) => {
		const chipsObject = predictState.chips;
		const completed = activeMatch?.status === MatchStatus.COMPLETE;
		const notSpentFirstHalf = [DEFAULT_CHIPS_AMOUNT, DEFAULT_CHIPS_AMOUNT_WITH_RETURN].includes(
			chipsObject.remainFirstHalf ?? 100
		);
		const notSpentSecondHalf = chipsObject.remainSecondHalf === DEFAULT_CHIPS_AMOUNT;

		return completed && !(notSpentFirstHalf && notSpentSecondHalf);
	}
);
