import React, {FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import styled from "@emotion/styled";
import {FormControlLabel} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CustomCheckbox, Input, PrimaryButton} from "components/Common";
import {parseParams, serializeForm} from "modules/utils/helpers";
import {IRegisterForm} from "modules/types";
import {useDispatch} from "react-redux";
import {loginInApp, registerRequest} from "modules/actions";
import {Link, useLocation} from "react-router-dom";
import {RegisterQueryMapper} from "modules/enums";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/Analytics";

const Wrapper = styled.form`
	width: 100%;
	padding: 0 20px;
	color: ${({theme}) => theme.text.color.white};

	a {
		text-decoration: underline;
	}

	h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 16px;
	}

	.MuiFormControl-root {
		margin-bottom: 4px;
	}

	button {
		position: relative;
		margin-top: 4px;
		margin-bottom: 20px;
		z-index: 4;
	}
`;

export const FormRegister: React.FC = () => {
	const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const {t} = useTranslation();
	const [form, setForm] = useState<IRegisterForm>({
		username: "",
		terms: false,
	});

	const withSearch = useCallback(
		(path: string) => {
			return path + location.search;
		},
		[location]
	);

	const params = useMemo(() => {
		return parseParams(location.search);
	}, [location]);

	useEffect(() => {
		if (params.action === "login") {
			dispatch(
				loginInApp({
					params: {
						action: "login",
					},
					headers: {
						token: params[RegisterQueryMapper.Token] ?? "",
					},
				})
			);
		}
	}, [dispatch, params]);

	const onFormChange = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			const data = serializeForm<IRegisterForm>(e.currentTarget);
			setForm({...form, ...data});

			if (!isAnalyticsInitialized) {
				createConnextraScriptTag(ConnextraType.REGISTRATION_START, null);
				setIsAnalyticsInitialized(true);
			}
		},
		[form, isAnalyticsInitialized]
	);

	const onFormSubmit = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();
			dispatch(
				registerRequest({
					params: {
						username: form.username,
						isStadium: Boolean(params[RegisterQueryMapper.IsStadium ?? false]),
					},
					headers: {
						token: params[RegisterQueryMapper.Token] ?? "",
					},
				})
			);
		},
		[form, dispatch, params]
	);

	return (
		<Wrapper onChange={onFormChange} onSubmit={onFormSubmit}>
			<h2>{t("register.form.title")}</h2>

			<Input
				name="username"
				label={t("register.form.username")}
				value={form.username}
				// disabled={Boolean(user)}
				required
			/>
			<FormControlLabel
				label={<Link to={withSearch("/help/terms")}>{t("register.form.terms")}</Link>}
				control={<CustomCheckbox name="terms" size="medium" value={form.terms} required />}
			/>

			<PrimaryButton type="submit">{t("register.form.submit")}</PrimaryButton>
		</Wrapper>
	);
};
