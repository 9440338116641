import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getLeagueById, getUser} from "modules/selectors";
import {Exist} from "components/Exist";
import styled from "@emotion/styled";
import {PrimaryLink} from "components/Common";
import {LanguagesShort, LeaguePrivacy} from "modules/enums";

const ButtonsWrapper = styled("div")`
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 6px;

	&.es {
		a {
			font-size: 0.6rem;
		}

		&.league-layout {
			a {
				font-size: 11px;
			}
		}
	}
`;

interface IProps {
	leagueId: number;
	customClass?: string;
}

export const LeagueNavigation: React.FC<IProps> = ({leagueId, customClass = ""}) => {
	const {t, i18n} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const user = useSelector(getUser);
	const league = useSelector(getLeagueById)(leagueId);

	const isCommissioner = user?.id === league?.user_id;
	const isPrivateLeague = league?.privacy === LeaguePrivacy.Private;

	const getLink = (path: string) => {
		return `/leagues/${leagueId}/${path}`;
	};

	if (!league) {
		return null;
	}

	return (
		<ButtonsWrapper className={`${language} ${customClass}`}>
			<Exist when={isCommissioner}>
				<PrimaryLink className="league-navigation" to={getLink("standings")}>
					{t("leagues.navigate.standings")}
				</PrimaryLink>
				<PrimaryLink className="league-navigation" to={getLink("manage")}>
					{t("leagues.navigate.manage")}
				</PrimaryLink>
				<PrimaryLink className="league-navigation" to={getLink("invite")}>
					{t("leagues.navigate.invite")}
				</PrimaryLink>
				<PrimaryLink className="league-navigation" to={getLink("settings")}>
					{t("leagues.navigate.settings")}
				</PrimaryLink>
			</Exist>

			<Exist when={!isCommissioner}>
				<PrimaryLink className="league-navigation" to={getLink("standings")}>
					{t("leagues.navigate.standings")}
				</PrimaryLink>
				<Exist when={!isPrivateLeague}>
					<PrimaryLink className="league-navigation" to={getLink("invite")}>
						{t("leagues.navigate.invite")}
					</PrimaryLink>
				</Exist>
				<PrimaryLink className="league-navigation" to={getLink("about")}>
					{t("leagues.navigate.about")}
				</PrimaryLink>
			</Exist>
		</ButtonsWrapper>
	);
};
