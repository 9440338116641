import React, {useCallback, useEffect, useState} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton, SheetCloseButton} from "components/Common";
import AssetsTopImage from "assets/img/icons/ModalMakePredictionCoins.png";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useDispatch, useSelector} from "react-redux";
import {
	getActiveContest,
	getContestAnswerById,
	getPredictionById,
	getPredictionRequestStateByKey,
	getPredictionSliderData,
	getPredictModalData,
	getSelectedMatch,
	getUser,
} from "modules/selectors";
import {RemainingChips} from "components/Modals/PredictModal/RemainingChips";
import {WinChips} from "components/Modals/PredictModal/WinChips";
import {IconClose} from "components/Icons";
import {
	closePredictionModal,
	editPredictionRequest,
	fetchAnswersByContestIdRequest,
	fetchUserChipsByMatch,
	makePredictionRequest,
	togglePredictSavedModal,
} from "modules/actions";
import {Exist} from "components/Exist";
import {ButtonPreloader} from "components/Preloader";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/Analytics";
import {PredictSavedModalService} from "modules/utils/PredictSavedModalService";
import {LanguagesShort} from "modules/enums";
import {RangeSlider} from "components/RangeSlider";

const ChipsBlock = styled("div")`
	position: relative;
	background: url(${AssetsTopImage});
	background-size: 100% 100%;
	width: 205px;
	height: 112px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	span,
	h4 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
	}

	h4 {
		font-size: 22px;
		margin-bottom: 6px;
	}
`;

const SBottomSheet = styled(BottomSheet)`
	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 12px;
	}
`;

const CalculationWrapper = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;
	margin: 16px 0;

	> div {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 9px 12px 8px 12px;
		gap: 2px;
		border-radius: 4px;

		h4 {
			text-align: center;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
		}

		p {
			text-align: center;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
		}

		&.remaining {
			background: ${({theme}) => theme.background.black};
			color: ${({theme}) => theme.text.color.white};
		}

		&.win {
			background: ${({theme}) => theme.actionColors.correct};
			color: ${({theme}) => theme.text.color.dark};
		}
	}
`;

const SliderText = styled("div")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
`;

const PlusChipsBlock = styled("div")`
	padding: 3px 8px;
	position: absolute;
	background: #000000;
	border: 1px solid #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 21px;
	top: 4px;
	right: 18%;

	color: ${({theme}) => theme.text.color.white};
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
`;

const SliderWrapper = styled.div`
	padding: 0 12px;
	margin: 12px auto;
	width: 96%;

	.slider {
		margin: 6px auto;
	}
`;

const SliderRange = styled("div")`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const PredictModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t, i18n} = useTranslation();
	const {isOpen, predictionId} = useSelector(getPredictModalData);
	const pick = useSelector(getPredictionById)(Number(predictionId));
	const {min, max, disabled} = useSelector(getPredictionSliderData)(predictionId);
	const [chipsSelected, setChipsSelected] = useState(min);
	const language = i18n.language as LanguagesShort;
	const activeContest = useSelector(getActiveContest);
	const activeMatch = useSelector(getSelectedMatch);
	const user = useSelector(getUser);

	// fetch answer if user edit pick
	const answer = useSelector(getContestAnswerById)(predictionId);

	const {isLoading, isSuccess} = useSelector(getPredictionRequestStateByKey)("makePick");
	const isButtonDisabled = isLoading || disabled || (!answer && chipsSelected === 0);

	useEffect(() => {
		if (answer) {
			setChipsSelected(answer.bet);
		}
	}, [answer]);

	const closeModal = useCallback(() => {
		setChipsSelected(0);
		dispatch(closePredictionModal());
	}, [dispatch]);

	const placePick = useCallback(() => {
		if (!pick || !activeContest) {
			return;
		}

		if (answer) {
			const userPick = {bet: chipsSelected, isStadium: true};
			dispatch(
				editPredictionRequest({
					propId: pick.id,
					pick: userPick,
				})
			);
			createConnextraScriptTag(ConnextraType.PICKS_CONFIRM, user);
			return;
		}

		dispatch(
			makePredictionRequest({
				contestId: activeContest.id,
				pick: {
					questionId: pick.id,
					bet: chipsSelected,
					isStadium: true,
				},
			})
		);
	}, [chipsSelected, dispatch, pick, activeContest, answer, user]);

	useEffect(() => {
		if (!isSuccess || !activeContest) {
			return;
		}

		setChipsSelected(0);
		closeModal();
		const isVoted = PredictSavedModalService.CheckMatchId(activeMatch?.id);
		if (!isVoted) {
			dispatch(togglePredictSavedModal(true));
		}
		PredictSavedModalService.StoreMatchId(activeMatch?.id);
		dispatch(fetchAnswersByContestIdRequest(activeContest.id));
		if (activeMatch) {
			dispatch(fetchUserChipsByMatch(activeMatch.id));
		}
	}, [isSuccess, dispatch, closeModal, chipsSelected, activeContest, activeMatch]);

	if (!pick || !isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<SheetCloseButton onClick={closeModal}>
					<IconClose color="#000000" />
				</SheetCloseButton>

				<h2>{pick?.name[language]}</h2>
				<ChipsBlock>
					<h4>{chipsSelected}</h4>
					<span>{t("common.chips")}</span>

					<PlusChipsBlock>
						<Exist when={Boolean(pick?.multiplier)}>{pick?.multiplier}X</Exist>
						<Exist when={!pick}>-</Exist>
					</PlusChipsBlock>
				</ChipsBlock>

				<SliderWrapper>
					<SliderRange>
						<span>{min}</span>
						<span>{max}</span>
					</SliderRange>
					<div className="slider">
						<RangeSlider value={chipsSelected} onChange={setChipsSelected} />
					</div>
				</SliderWrapper>

				<SliderText>{t("game.prediction.slider")}</SliderText>

				<CalculationWrapper>
					<RemainingChips placed={chipsSelected} predictionId={predictionId} />
					<WinChips placed={chipsSelected} predictionId={predictionId} />
				</CalculationWrapper>

				<PrimaryButton className="active" onClick={placePick} disabled={isButtonDisabled}>
					<Exist when={isLoading}>
						<ButtonPreloader />
					</Exist>
					<Exist when={!isLoading}>
						<span>{t("game.prediction.save_picks")}</span>
					</Exist>
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
