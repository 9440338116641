import {createAction} from "redux-act";
import {IContactUsPayload, IHelpSectionActionPayload} from "modules/types";
import {LanguagesShort} from "modules/enums";

export const fetchHelpSection = createAction<LanguagesShort>();
export const fetchHelpSectionSuccess = createAction<IHelpSectionActionPayload>();

export const sendContactUsRequest = createAction<IContactUsPayload>();
export const sendContactUsSuccess = createAction();
export const sendContactUsClear = createAction();
