import React, {useCallback, useEffect} from "react";
import styled from "@emotion/styled";

import {useDispatch, useSelector} from "react-redux";
import {getIsMenuOpen} from "modules/selectors";
import {IconClose} from "components/Icons";
import {PureButton} from "components/Common";
import {toggleMenu} from "modules/actions";

import AssetIconTopShape from "assets/img/icons/TopShape.svg";
import AssetMenuBackground from "assets/img/MenuBG.png";
import {Exist} from "components/Exist";
import {MenuLogged} from "components/Menu/MenuLogged";
import {MenuNonLogged} from "components/Menu/MenuNonLogged";
import {useLocation} from "react-router-dom";
import {getIsLogged} from "modules/selectors/User";

const Wrapper = styled("div")`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: url(${AssetMenuBackground});
	background-size: cover;
	z-index: 99;
	transform: translateX(-100%);
	transition: 300ms ease-in-out;
	max-width: 100vw;

	&.open {
		transform: translateX(0);
	}
`;

const Content = styled.div`
	background: linear-gradient(180deg, #000000 0%, rgba(29, 29, 29, 0) 25%);
	height: calc(100vh - 52px);
`;

const Heading = styled("div")`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 52px;
	padding: 0 12px;

	background: url(${AssetIconTopShape}), #000000;
	background-repeat: no-repeat;
	background-position: top center;
`;

const CloseButton = styled(PureButton)`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Menu: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isOpen = useSelector(getIsMenuOpen);
	const isLogged = useSelector(getIsLogged);

	const closeMenu = useCallback(() => {
		dispatch(toggleMenu(false));
	}, [dispatch]);

	useEffect(() => {
		closeMenu();
	}, [closeMenu, location]);

	return (
		<Wrapper className={isOpen ? "open" : ""}>
			<Heading>
				<CloseButton onClick={closeMenu}>
					<IconClose />
				</CloseButton>
			</Heading>
			<Content>
				<Exist when={isLogged}>
					<MenuLogged />
				</Exist>
				<Exist when={!isLogged}>
					<MenuNonLogged />
				</Exist>
			</Content>
		</Wrapper>
	);
};
