import {createReducer} from "redux-act";
import {IReducerAction, IRegisterPayload, IUser, IUserReducer} from "modules/types";
import {
	backdoorLoginUserSuccess,
	loginInAppSuccess,
	logoutUserSuccess,
	registerRequest,
	registerRequestSuccess,
	setUserRequested,
	userCheckDone,
} from "modules/actions";
import {RequestStateType} from "modules/enums";

type IUserReducerPayload<TP = null> = IReducerAction<IUserReducer, TP>;

const initialState: IUserReducer = {
	user: null,
	isLogged: false,
	isChecked: false,
	isRequested: false,
	requestState: {
		register: RequestStateType.Idle,
	},
};

const onBackdoorLoginUserSuccess: IUserReducerPayload<IUser> = (state, payload) => ({
	...state,
	user: payload,
	isLogged: true,
});

const onLogoutUser: IUserReducerPayload = (state) => ({
	...state,
	user: null,
	isLogged: false,
});

const onRegisterRequest: IUserReducerPayload<IRegisterPayload> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		register: RequestStateType.Loading,
	},
});

const onRegisterRequestSuccess: IUserReducerPayload<IUser> = (state, payload) => ({
	...state,
	requestState: {
		...state.requestState,
		register: RequestStateType.Idle,
	},
	user: payload,
	isLogged: true,
	isChecked: true,
	isRequested: true,
});

const onSetUserRequested: IUserReducerPayload = (state) => ({
	...state,
	isRequested: true,
});

const onUserCheckDone: IUserReducerPayload = (state) => ({
	...state,
	isChecked: true,
});

export const userReducer = createReducer<IUserReducer>({}, initialState)
	.on(backdoorLoginUserSuccess, onBackdoorLoginUserSuccess)
	.on(logoutUserSuccess, onLogoutUser)
	.on(registerRequest, onRegisterRequest)
	.on(registerRequestSuccess, onRegisterRequestSuccess)
	.on(loginInAppSuccess, onRegisterRequestSuccess)
	.on(setUserRequested, onSetUserRequested)
	.on(userCheckDone, onUserCheckDone);
