import {LanguagesShort, LeaguePrivacy, MatchStatus} from "modules/enums";

function envOrEmpty(key: string) {
	return process.env[key] || "";
}

function envToBoolean(key: string): boolean {
	return JSON.parse(process.env[key] || "false") as boolean;
}

/**
 * Environment variables
 */
export const API_URL = envOrEmpty("REACT_APP_API_URL");
export const JSON_URL = envOrEmpty("REACT_APP_JSON_URL");
export const JOIN_URL = envOrEmpty("REACT_APP_SHARE_URL");
export const IMAGES_URL = envOrEmpty("REACT_APP_IMAGES_URL");
export const RAIDERS_ID = envOrEmpty("REACT_APP_RAIDERS_ID");
export const FB_SHARE_URL = envOrEmpty("REACT_APP_FB_SHARE_URL");
export const ENV = envOrEmpty("REACT_APP_ENVIRONMENT");
export const IS_USING_MOCK_DATA = JSON.parse(process.env.REACT_APP_MOCK_DATA || "false") as boolean;
export const IS_IN_STADIUM_LEADERBOARD_DISABLED = envToBoolean(
	"REACT_APP_IS_IN_STADIUM_LEADERBOARD_DISABLED"
);
export const IS_CELEBRITY_LEADERBOARD_DISABLED = envToBoolean(
	"REACT_APP_IS_CELEBRITY_LEADERBOARD_DISABLED"
);
export const IS_SHARING_ENABLED = JSON.parse(
	process.env.REACT_APP_IS_SHARE_ENABLED || "false"
) as boolean;
export const NO_HEADER_PATHS = ["/", "/backdoor", "/register", "/error"];

export const UAT_URL = "https://lvr-predictor.uat.f2p.media.geniussports.com/";
export const PREPROD_URL = "https://lvr-predictor.preprod.f2p.media.geniussports.com";
export const PROD_URL = "https://runthetable.raiders.com/";
export const SENTRY_DOMAIN_ALLOWED = ".f2p.media.geniussports.com";

export const TUTORIAL_KEY = "tutorial_played";
export const HALF_TIME_KEY = "last_half";
export const PLAYED_MATCHES = "played_matches";

export const EMAIL_PATTERN = new RegExp("[A-za-z0-9._%+-]+@[a-z\\d.-]+.[a-z]{2,4}$");

export const TIMER_FORMAT_MAPPER = {
	mm: "m",
	ss: "s",
	hh: "h",
};

export const LIVE_MATCH_STATES = [
	MatchStatus.FIRST_HALF,
	MatchStatus.SECOND_HALF,
	MatchStatus.HALF_TIME,
	MatchStatus.OVERTIME,
];

export const ACTIVE_MATCH_STATES = [...LIVE_MATCH_STATES, MatchStatus.SCHEDULED];

export const RETURN_AMOUNT = 20;
export const DEFAULT_CHIPS_AMOUNT = 100;
export const DEFAULT_CHIPS_AMOUNT_WITH_RETURN = DEFAULT_CHIPS_AMOUNT + RETURN_AMOUNT;
export const DEFAULT_CHIPS_STEP = 10;

export const ONE_MINUTE = 1000 * 60;
export const FIVE_MINUTES = ONE_MINUTE * 5;
export const THREE_MINUTES = ONE_MINUTE * 3;
export const DEFAULT_LIMIT = 20;

export const SSO_COOKIE_KEY = "X-YinzCam-Ticket";

export const PRIVACY_TRANSLATION = {
	[LeaguePrivacy.Public]: "leagues.privacy.public",
	[LeaguePrivacy.Private]: "leagues.privacy.private",
} as const;
export const BACKDOOR_PASSWORD = envOrEmpty("REACT_APP_BACKDOOR_PASSWORD");
export const TOKEN_KEY = "X-YinzCam-Ticket";

export const LocaleByLanguages = {
	[LanguagesShort.English]: "en-US",
	[LanguagesShort.Spanish]: "es-ES",
};

export const SECRET_KEY = "non_secret_key_100%";
export const SHARE_TRANSLATIONS_MAP = {
	general: "share.text.global", // Do you have what it takes to predict the Raiders props that will hit? Come check out Raiders Run the Table, available in the Raiders app now
	league: "share.text.league", // Come join the {{X}} league on Raiders Run The Table, now available on the Raiders app
};
