import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {SubTitle, TertiaryButton} from "components/Common";
import {useDispatch, useSelector} from "react-redux";
import {getNewLeague} from "modules/selectors/Leagues";
import {CopyCode, EmailInvite, QrInvite} from "components/LeagueInviteComponents";
import {SocialInvite} from "components/SocialInvite";
import {skipLeagueCreateStepTwo} from "modules/actions";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Wrapper = styled("div")``;

const Section = styled("section")`
	button {
		color: ${({theme}) => theme.text.color.white};
		text-transform: capitalize;
		text-decoration: underline;
	}
`;

const SkipSection = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const skip = useCallback(() => {
		dispatch(skipLeagueCreateStepTwo());
		navigate("/leagues");
	}, [dispatch, navigate]);

	return (
		<Section>
			<TertiaryButton onClick={skip}>
				{t("leagues.form.skip")} {`>`}
			</TertiaryButton>
		</Section>
	);
};

export const LeagueCreateStepTwo: React.FC = () => {
	const {t} = useTranslation();
	const newLeague = useSelector(getNewLeague);

	if (!newLeague) {
		return <SkipSection />;
	}

	return (
		<Wrapper>
			<SubTitle>{t("leagues.create.step_2")}</SubTitle>
			<CopyCode code={newLeague?.code || ""} />
			<QrInvite code={newLeague?.code || ""} />
			<EmailInvite league={newLeague} />
			<SocialInvite league={newLeague} />
			<SkipSection />
		</Wrapper>
	);
};

export default LeagueCreateStepTwo;
