import React from "react";
import {MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getOpponentByRoundId} from "modules/selectors";
import {IRound} from "modules/types";

/**
 * Not really React.FC component
 * As can't wrap MenuItem with any React.FC structure
 * The only possible way is pure function
 * {@link https://stackoverflow.com/questions/68378474/mui-select-custom-menuitem-not-working-properly MUI Select custom MenuItem not working properly}
 **/

export const RoundMenuItem = (round: IRound) => {
	const {t} = useTranslation();
	const {opponentName, symbol} = useSelector(getOpponentByRoundId)(round.id);
	return (
		<MenuItem key={round.id} value={round.id}>
			<React.Fragment>
				<span>{t("leagues.form.round", {X: round.id})}</span>
				<span>&nbsp;{symbol}&nbsp;</span>
				<span>{opponentName}</span>
			</React.Fragment>
		</MenuItem>
	);
};
