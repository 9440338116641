import styled from "@emotion/styled";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Switch} from "components/Switch";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedHalf} from "modules/actions";
import {ContestHalf} from "modules/enums";
import {getActiveContest, getMatchContests} from "modules/selectors";

const Wrapper = styled("div")`
	margin: 22px 0 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.15px;
		text-transform: uppercase;
	}
`;

export const PredictHalfToggle: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {second, firstDraft, secondDraft} = useSelector(getMatchContests);
	const activeContest = useSelector(getActiveContest);
	const checked = activeContest === second;
	const isDisabled = firstDraft || secondDraft;

	const setChecked = useCallback(
		(newChecked: boolean) => {
			const half = newChecked ? ContestHalf.Second : ContestHalf.First;
			dispatch(setSelectedHalf(half));
		},
		[dispatch]
	);

	return (
		<Wrapper className="half-toggle">
			<span>{t("game.half.first")}</span>
			<Switch onChange={setChecked} checked={checked} disabled={isDisabled} />
			<span>{t("game.half.second")}</span>
		</Wrapper>
	);
};
