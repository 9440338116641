import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconStats: React.FC<IGeneralIconProps> = ({
                                                           width = 32,
                                                           height = 32,
                                                           color = '#FFFFFF'
                                                       }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width={width}
             height={height}
             viewBox="0 0 32 32">
            <path
                d="M6.6665 12.2667H10.6665V25.3334H6.6665V12.2667ZM14.1332 6.66669H17.8665V25.3334H14.1332V6.66669ZM21.5998 17.3334H25.3332V25.3334H21.5998V17.3334Z"
                fill={color}/>
        </svg>
    );
}
