import {PageLayout, PageTitle} from "components/Common";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchSingleLeagueRequest} from "modules/actions";
import {getLeagueById, getLeaguesRequestStateByKey} from "modules/selectors";
import {LocalPreloader} from "components/Preloader";
import styled from "@emotion/styled";
import {LeagueNavigation} from "components/LeagueNavigation";

const SPageTitle = styled(PageTitle)`
	padding-left: 20px;
	padding-right: 20px;
`;

const NavigationWrapper = styled("div")`
	padding: 0 20px;

	a {
		border: 1px solid ${({theme}) => theme.text.color.white};
		background: transparent;
		font-weight: 400;
		padding: 10px 8px !important;

		&.active {
			background: ${({theme}) => theme.text.color.white};
			color: ${({theme}) => theme.text.color.dark};
		}
	}
`;

const allowedPathParts = ["standings", "invite", "about", "settings", "manage"];
export const LeaguesSectionLayout: React.FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const league = useSelector(getLeagueById)(Number(params.id));

	const {isLoading, isError} = useSelector(getLeaguesRequestStateByKey)("singleLeague");
	useEffect(() => {
		const isCorrectPath = allowedPathParts.some((path) => location.pathname.includes(path));

		if (isError || !isCorrectPath) {
			navigate("/leagues");
		}
	}, [location, isError, navigate]);

	useEffect(() => {
		const leagueId = parseInt(String(params.id));

		if (!leagueId || league) {
			return;
		}

		dispatch(fetchSingleLeagueRequest(leagueId));
	}, [params, dispatch, league]);

	if (isLoading || !league) {
		return <LocalPreloader />;
	}

	return (
		<PageLayout className="no-padding">
			<SPageTitle>{league.name}</SPageTitle>
			<NavigationWrapper>
				<LeagueNavigation customClass="league-layout" leagueId={league.id} />
			</NavigationWrapper>
			<Outlet />
		</PageLayout>
	);
};

export default LeaguesSectionLayout;
