// FS - FullScreen
import styled from "@emotion/styled";
import AssetMenuBackground from "assets/img/MenuBG.png";
import AssetIconTopShape from "assets/img/icons/TopShape.svg";

export const FSModal = styled("div")`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	transition: 300ms ease-in-out;
	background: url(${AssetMenuBackground});
	background-size: cover;
	z-index: 11;
	overflow: auto;
	max-width: 100%;
	overflow-x: hidden;
`;

export const FSModalHeading = styled("div")`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;
	padding: 0 12px;

	background: url(${AssetIconTopShape});
	background-repeat: no-repeat;
	background-position: top center;
`;

export const FSModalBody = styled("div")`
	background: linear-gradient(180deg, #000000 0%, rgba(29, 29, 29, 0) 25%);
	min-height: calc(100vh - 50px);
`;
