import styled from "@emotion/styled";
import AssetMenuBackground from "assets/img/MenuBG.png";

export const PageLayout = styled("div")`
	padding: 16px 20px;
	background: url(${AssetMenuBackground});
	background-size: 100% 100%;
	min-height: 100vh;

	&.no-bg {
		background: unset;
	}

	&.no-padding {
		padding: 16px 0;
	}
`;

export const PageLayoutNoPadding = styled("div")`
	padding: 16px 0;
`;

export const PageTitle = styled("h1")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 16px;
`;
