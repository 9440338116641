import {call, put, select} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {
	checkHalf,
	clearChips,
	fetchMatchesSuccess,
	fetchMatchResultClear,
	fetchMatchResultSuccess,
	setSelectedHalf,
	setSelectedMatch,
	showApiErrorModal,
} from "modules/actions";
import {ContestHalf, ContestStatus, MatchStatus} from "modules/enums";
import {last} from "lodash";
import {sortMatchesByDate} from "modules/utils/helpers";
import {getAvailableMatches, getSelectedMatch} from "modules/selectors";
import {ACTIVE_MATCH_STATES} from "modules/constants";
import {ISagaAction} from "modules/types";

export const fetchMatchesSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.matches);
		const sorted = response.items.sort(sortMatchesByDate);
		yield* put(fetchMatchesSuccess({...response, items: sorted}));

		const selectedMatch = yield* select(getSelectedMatch);
		if (selectedMatch) {
			const mathFromJSON = sorted.find((item) => item.id === selectedMatch.id);
			yield* put(setSelectedMatch(mathFromJSON ?? selectedMatch));
			yield* put(checkHalf());
			return;
		}

		const activeMatch = sorted.find((match) => {
			const isScheduled = ACTIVE_MATCH_STATES.includes(match.status);

			const firstContestCorrect = ![ContestStatus.Canceled, ContestStatus.Draft].includes(
				match.contests.firstHalf?.status || ContestStatus.Draft
			);

			const secondContestCorrect = ![ContestStatus.Canceled, ContestStatus.Draft].includes(
				match.contests.secondHalf?.status || ContestStatus.Draft
			);

			// At least one contest non-draft/non-cancel
			const contestsCorrect = firstContestCorrect || secondContestCorrect;

			return isScheduled && contestsCorrect;
		});
		if (activeMatch) {
			yield* put(setSelectedMatch(activeMatch));
			yield* put(checkHalf());
			return;
		}

		const lastComplete = last(sorted.filter((match) => match.status === MatchStatus.COMPLETE));
		if (lastComplete) {
			yield* put(setSelectedMatch(lastComplete));
		}
		yield* put(checkHalf());
	} catch (e) {
		console.log(e);
	}
};

export const goNextMatchSaga = function* (): SagaIterator {
	try {
		const matches = yield* select(getAvailableMatches);
		const activeMatch = yield* select(getSelectedMatch);

		if (!matches || !activeMatch) {
			return;
		}

		const index = matches.findIndex((item) => item.id === activeMatch.id);
		if (index === -1) {
			return;
		}

		const nextMatch = matches[index + 1];
		if (!nextMatch) {
			return;
		}

		yield* put(setSelectedMatch(nextMatch));
		yield* put(checkHalf());
		yield* put(clearChips());
	} catch (e) {
		console.log(e);
	}
};

export const goPrevMatchSaga = function* (): SagaIterator {
	try {
		const matches = yield* select(getAvailableMatches);
		const activeMatch = yield* select(getSelectedMatch);

		if (!matches || !activeMatch) {
			return;
		}

		const index = matches.findIndex((item) => item.id === activeMatch.id);
		if (index === -1) {
			return;
		}

		const nextMatch = matches[index - 1];
		if (!nextMatch) {
			return;
		}

		yield* put(setSelectedMatch(nextMatch));
		yield* put(checkHalf());
		yield* put(clearChips());
	} catch (e) {
		console.log(e);
	}
};

export const checkHalfSaga = function* (): SagaIterator {
	try {
		const match = yield* select(getSelectedMatch);
		if (!match) {
			return;
		}
		const firstHalfLive = match.contests.firstHalf?.status === ContestStatus.Live;
		if (firstHalfLive) {
			yield* put(setSelectedHalf(ContestHalf.First));
			return;
		}
		const secondHalfLive = match.contests.secondHalf?.status === ContestStatus.Live;
		const halfTime = match.status === MatchStatus.HALF_TIME;
		if (secondHalfLive || halfTime) {
			yield* put(setSelectedHalf(ContestHalf.Second));
			return;
		}

		yield* put(setSelectedHalf(ContestHalf.First));
	} catch (e) {
		console.log(e);
	}
};

export const fetchMatchResultSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const response = yield* call(Api.Match.result, payload);
		ApiError.CHECK(response);

		yield* put(fetchMatchResultSuccess(response.success));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(fetchMatchResultClear());
	}
};
