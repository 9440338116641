import React from "react";
import {IContestProp} from "modules/types";
import {Chip, PointsLabel, PredictButtonBlock} from "components/Common";
import {LanguagesShort, PredictionSuggest} from "modules/enums";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {IconChip} from "components/Icons";
import SponsorLogo from "assets/img/logos/COXSponsorLogo.svg";
import SponsorLogoDark from "assets/img/logos/COXSponsorLogoDark.svg";
import SponsorLogoWhite from "assets/img/logos/COXSponsorLogoWhite.svg";
import {
	getBetClass,
	getButtonClass,
	getChipColor,
	getDashedValue,
	getSuggestion,
} from "modules/utils/helpers";
import {useSelector} from "react-redux";
import {getContestAnswerById, getIsActiveContestLocked} from "modules/selectors";
import {Exist} from "components/Exist";

const SPredictButtonBlock = styled(PredictButtonBlock)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	height: unset;

	.cox-dark {
		display: none;
	}

	&:focus:not(.complete),
	&:active:not(.complete) {
		span:not(.chips) {
			color: ${({theme}) => theme.text.color.dark} !important;
		}
	}

	&.picked {
		span:not(.chips),
		p:not(.chips) {
			color: ${({theme}) => theme.text.color.dark} !important;
		}

		.cox-dark {
			display: block;
		}

		.cox-default {
			display: none;
		}
	}

	&.incorrect {
		span:not(.chips),
		p:not(.chips) {
			color: ${({theme}) => theme.text.color.white} !important;
		}
	}
`;

const InnerBlock = styled("div")`
	position: relative;
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	width: 100%;
	margin-top: 12px;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: capitalize;

	&:after {
		content: "";
		position: absolute;
		left: -3px;
		top: -3px;
		right: -3px;
		bottom: -3px;
		border: 1px solid #ffffff;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		z-index: 2;
	}

	span {
		text-align: left;
		padding: 0;
	}
`;

const Sponsor = styled.img`
	height: 16px;
	width: auto;
	margin: 12px 0 4px 0;
`;

const Text = styled.div`
	width: 100%;
	padding: 0 !important;
`;

interface IBaseProp {
	readonly prop: IContestProp;
}

interface IProps extends IBaseProp {
	onClick: () => void;
}

export const SponsorProp: React.FC<IProps> = ({prop, onClick}) => {
	const {i18n, t} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const {isActive, isComplete} = useSelector(getIsActiveContestLocked);
	const answer = useSelector(getContestAnswerById)(prop?.id);
	const isPicked = Boolean(answer);

	const suggestion = getSuggestion(answer?.outcome);
	const pickedClass = getButtonClass(suggestion, isPicked);
	const completeClass = isComplete || isActive ? "complete" : "";

	return (
		<SPredictButtonBlock
			disableRipple
			onClick={onClick}
			className={`${pickedClass} ${completeClass}`}>
			<PointsLabel>{prop.multiplier}Х</PointsLabel>
			<Exist when={Boolean(answer?.bet)}>
				<Chip>
					<IconChip color={getChipColor(answer?.bet)} width={40} height={40} />
					<span className={`${getBetClass(answer?.bet)} chips`}>
						{getDashedValue(answer?.bet)}
					</span>
				</Chip>
			</Exist>

			<SponsorLogoMapper prop={prop} />
			<Text>{t("props.sponsor.title")}</Text>
			<InnerBlock>
				<p>{prop.name[language ?? LanguagesShort.English]}</p>
			</InnerBlock>
		</SPredictButtonBlock>
	);
};

const SponsorLogoMapper: React.FC<IBaseProp> = ({prop}) => {
	const answer = useSelector(getContestAnswerById)(prop?.id);
	const isPicked = Boolean(answer);

	const suggestion = getSuggestion(answer?.outcome);
	const isCorrect = suggestion === PredictionSuggest.Correct;
	const isIncorrect = suggestion === PredictionSuggest.Incorrect;

	if (isPicked && isIncorrect) {
		return <Sponsor className="cox-white" src={SponsorLogoWhite} alt="COX" />;
	}

	if (isPicked || isCorrect) {
		return <Sponsor className="cox-dark" src={SponsorLogoDark} alt="COX" />;
	}

	return <Sponsor className="cox-default" src={SponsorLogo} alt="COX" />;
};
