import {all, debounce, takeLatest} from "typed-redux-saga";
import {
	backdoorLoginUserRequest,
	checkHalf,
	createLeagueRequest,
	editPredictionRequest,
	fetchAnswersByContestIdRequest,
	fetchHelpSection,
	fetchLeagueUsersRequest,
	fetchMatchesRequest,
	fetchMatchResultRequest,
	fetchRoundsRequest,
	fetchSingleLeagueRequest,
	fetchSquadsRequest,
	fetchUserChipsByMatch,
	getUserRequest,
	goToNextMatch,
	goToPrevMatch,
	joinToLeagueRequest,
	leaveLeagueRequest,
	loginInApp,
	logoutUserRequest,
	makePredictionRequest,
	playReceiveChipsAnimation,
	registerRequest,
	removeLeagueUserRequest,
	sendContactUsRequest,
	sendLeagueEmailInviteRequest,
	showForJoinRequest,
	subscribeAnswers,
	subscribeLiveScoring,
	updateLeagueRequest,
} from "modules/actions";
import {
	backdoorLoginUserSaga,
	getUserSaga,
	logoutSaga,
	registerSaga,
	loginInAppSaga,
} from "modules/sagas/User";
import {fetchUserLeaguesRequest} from "modules/actions/Leagues";
import {
	createLeagueSaga,
	fetchLeagueUsersSaga,
	fetchSingleLeagueSaga,
	fetchUserLeaguesSaga,
	joinLeagueSaga,
	leaveLeagueSaga,
	removeLeagueUserSaga,
	sendLeagueEmailInviteSaga,
	showForJoinSaga,
	updateLeagueSaga,
} from "modules/sagas/Leagues";
import {
	fetchMatchesSaga,
	goNextMatchSaga,
	goPrevMatchSaga,
	fetchMatchResultSaga,
	checkHalfSaga,
} from "modules/sagas/Matches";
import {
	fetchRoundsSaga,
	fetchSquadsSaga,
	subscribeAnswersSaga,
	subscribeLiveScoringSaga,
} from "modules/sagas/JSON";
import {
	editPredictionSaga,
	fetchAnswersByContestIdSaga,
	fetchUserChipsByMatchSaga,
	makePredictionSaga,
} from "modules/sagas/Prediction";
import {fetchHelpSectionSaga, sendContactUsSaga} from "modules/sagas/Static";
import {playReceiveChipsAnimationSaga} from "modules/sagas/UI";
import {
	fetchCelebrityRankingsRequest,
	fetchInVenueRankingsRequest,
	fetchLeagueRankingsRequest,
	fetchOverallRankingsRequest,
} from "modules/actions/Rankings";
import {
	fetchCelebrityRankingsSaga,
	fetchInVenueRankingsSaga,
	fetchLeagueRankingsSaga,
	fetchOverallRankingsSaga,
} from "modules/sagas/Rankings";

export const rootSaga = function* () {
	yield* all([
		takeLatest(backdoorLoginUserRequest, backdoorLoginUserSaga),
		takeLatest(getUserRequest, getUserSaga),
		takeLatest(logoutUserRequest, logoutSaga),
		takeLatest(registerRequest, registerSaga),
		takeLatest(loginInApp, loginInAppSaga),

		takeLatest(fetchUserLeaguesRequest, fetchUserLeaguesSaga),
		takeLatest(fetchSingleLeagueRequest, fetchSingleLeagueSaga),
		takeLatest(createLeagueRequest, createLeagueSaga),
		takeLatest(sendLeagueEmailInviteRequest, sendLeagueEmailInviteSaga),
		takeLatest(joinToLeagueRequest, joinLeagueSaga),
		debounce(500, showForJoinRequest, showForJoinSaga),
		takeLatest(fetchLeagueUsersRequest, fetchLeagueUsersSaga),
		takeLatest(removeLeagueUserRequest, removeLeagueUserSaga),
		takeLatest(leaveLeagueRequest, leaveLeagueSaga),
		takeLatest(updateLeagueRequest, updateLeagueSaga),

		takeLatest(fetchMatchesRequest, fetchMatchesSaga),
		takeLatest(fetchMatchResultRequest, fetchMatchResultSaga),
		takeLatest(fetchSquadsRequest, fetchSquadsSaga),
		takeLatest(goToNextMatch, goNextMatchSaga),
		takeLatest(goToPrevMatch, goPrevMatchSaga),
		takeLatest(subscribeLiveScoring, subscribeLiveScoringSaga),
		takeLatest(subscribeAnswers, subscribeAnswersSaga),
		takeLatest(checkHalf, checkHalfSaga),

		takeLatest(fetchUserChipsByMatch, fetchUserChipsByMatchSaga),
		takeLatest(fetchAnswersByContestIdRequest, fetchAnswersByContestIdSaga),
		takeLatest(makePredictionRequest, makePredictionSaga),
		takeLatest(editPredictionRequest, editPredictionSaga),

		takeLatest(fetchHelpSection, fetchHelpSectionSaga),
		takeLatest(fetchRoundsRequest, fetchRoundsSaga),
		takeLatest(sendContactUsRequest, sendContactUsSaga),

		takeLatest(playReceiveChipsAnimation, playReceiveChipsAnimationSaga),

		// RANKINGS
		takeLatest(fetchOverallRankingsRequest, fetchOverallRankingsSaga),
		takeLatest(fetchLeagueRankingsRequest, fetchLeagueRankingsSaga),
		takeLatest(fetchCelebrityRankingsRequest, fetchCelebrityRankingsSaga),
		takeLatest(fetchInVenueRankingsRequest, fetchInVenueRankingsSaga),
	]);
};
