import {
	IFetchLeagueRankingsPayload,
	IFetchOverallRankingsPayload,
	ISagaAction,
} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {
	clearRequestProcess,
	fetchCelebrityRankingsConcat,
	fetchCelebrityRankingsError,
	fetchCelebrityRankingsSuccess,
	fetchInVenueRankingsConcat,
	fetchInVenueRankingsError,
	fetchInVenueRankingsSuccess,
	fetchLeagueRankingsConcat,
	fetchLeagueRankingsError,
	fetchLeagueRankingsSuccess,
	fetchOverallRankingsConcat,
	fetchOverallRankingsError,
	fetchOverallRankingsSuccess,
	setRequestProcess,
} from "modules/actions/Rankings";
import {showApiErrorModal} from "modules/actions";

export const fetchOverallRankingsSaga = function* ({
	payload,
}: ISagaAction<IFetchOverallRankingsPayload>): SagaIterator {
	try {
		yield* put(setRequestProcess());
		// make round undefined if 0 round
		const data = {...payload, round: payload.round === 0 ? undefined : payload.round};
		const response = yield* call(Api.Rankings.overallOrLeague, data);
		ApiError.CHECK(response);
		if (payload.offset !== 0) {
			yield* put(fetchOverallRankingsConcat(response.success));
		} else {
			yield* put(fetchOverallRankingsSuccess(response.success));
		}
	} catch (e) {
		const error = e as ApiError;
		yield* put(fetchOverallRankingsError());
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(clearRequestProcess());
	}
};

export const fetchLeagueRankingsSaga = function* ({
	payload,
}: ISagaAction<IFetchLeagueRankingsPayload>): SagaIterator {
	try {
		yield* put(setRequestProcess());

		// make round undefined if 0 round
		const data = {...payload, round: payload.round === 0 ? undefined : payload.round};
		const response = yield* call(Api.Rankings.overallOrLeague, data);
		ApiError.CHECK(response);
		if (data.offset !== 0) {
			yield* put(fetchLeagueRankingsConcat(response.success));
		} else {
			yield* put(fetchLeagueRankingsSuccess(response.success));
		}
	} catch (e) {
		const error = e as ApiError;
		yield* put(fetchLeagueRankingsError());
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(clearRequestProcess());
	}
};

export const fetchCelebrityRankingsSaga = function* ({
	payload,
}: ISagaAction<IFetchOverallRankingsPayload>): SagaIterator {
	try {
		yield* put(setRequestProcess());
		// make round undefined if 0 round
		const data = {...payload, round: payload.round === 0 ? undefined : payload.round};
		const response = yield* call(Api.Rankings.celebrity, data);
		ApiError.CHECK(response);

		if (payload.offset !== 0) {
			yield* put(fetchCelebrityRankingsConcat(response.success));
		} else {
			yield* put(fetchCelebrityRankingsSuccess(response.success));
		}
	} catch (e) {
		const error = e as ApiError;
		yield* put(fetchCelebrityRankingsError());
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(clearRequestProcess());
	}
};

export const fetchInVenueRankingsSaga = function* ({
	payload,
}: ISagaAction<IFetchOverallRankingsPayload>): SagaIterator {
	try {
		yield* put(setRequestProcess());
		const data = {...payload, round: payload.round === 0 ? undefined : payload.round};
		const response = yield* call(Api.Rankings.inVenue, data);
		ApiError.CHECK(response);

		if (payload.offset !== 0) {
			yield* put(fetchInVenueRankingsConcat(response.success));
		} else {
			yield* put(fetchInVenueRankingsSuccess(response.success));
		}
	} catch (e) {
		const error = e as ApiError;
		yield* put(fetchInVenueRankingsError());
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* put(clearRequestProcess());
	}
};
