import React from "react";
import {TBody, THead} from "components/Common";
import {Table, TableCell, TableRow} from "@mui/material";
import {useSelector} from "react-redux";
import {getMatchResult} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {ContestHalf, LanguagesShort} from "modules/enums";
import styled from "@emotion/styled";
import {Nullable} from "modules/types";
import AssetBarCode from "assets/img/BarCode.png";

const STable = styled(Table)`
	&.center {
		.MuiTableCell-head {
			text-align: center;
		}

		.MuiTableCell-body {
			text-align: center;
			font-weight: bold;
			border-bottom: none;
		}
	}

	.MuiTableRow-head {
		background: #000000;

		.MuiTableCell-head {
			color: #ffffff;
			text-transform: uppercase;
			font-weight: 400;

			&.bet,
			&.won {
				text-align: right !important;
			}
		}
	}

	th,
	td {
		padding-left: 6px !important;
		padding-right: 6px !important;

		&:first-of-type {
			padding-left: 12px !important;
		}

		&:last-of-type {
			padding-right: 12px !important;
		}
	}
`;

const STableCell = styled(TableCell)`
	&.half {
		font-weight: 600;
		max-width: 34px;
	}

	&.question {
		font-weight: 400;
		font-size: 12px;
		line-height: 17px;
	}

	&.bet > span {
		background: ${({theme}) => theme.background.grey};
		font-weight: 600;
		padding: 3px 5px 3px 15px;
		min-width: 38px;
		display: block;
		text-align: right;

		&.correct {
			background: ${({theme}) => theme.actionColors.correct};
		}

		&.incorrect {
			background: ${({theme}) => theme.actionColors.incorrect};
		}
	}
`;

const TableBottom = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 12px;
	background: ${({theme}) => theme.actionColors.correct};
	width: 100%;

	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;

	p {
		margin-bottom: 0;
	}
`;

const SubTitle = styled("h4")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;

	b {
		font-weight: bold;
	}
`;

const BarCode = styled("div")`
	padding: 20px 50px;

	img {
		max-width: 200px;
		height: auto;
	}
`;

const halfMapper = {
	[ContestHalf.First]: "1ST",
	[ContestHalf.Second]: "2ND",
};

export const BetTable: React.FC = () => {
	const {t, i18n} = useTranslation();
	const results = useSelector(getMatchResult);
	const language = i18n.language as LanguagesShort;

	const getBackgroundByAnswer = (outcome: Nullable<number>) => {
		if (outcome === null) {
			return "";
		}
		return outcome === 0 ? "incorrect" : "correct";
	};

	return (
		<React.Fragment>
			<SubTitle>
				<b>{t("game.results_modal.complete")}</b> <br />
				<span>{t("game.results_modal.results")}</span>
			</SubTitle>
			<STable>
				<THead>
					<TableRow>
						<TableCell>{t("game.results_modal.half")}</TableCell>
						<TableCell>{t("game.results_modal.prediction")}</TableCell>
						<TableCell className="bet">{t("game.results_modal.bet")}</TableCell>
						<TableCell className="won">{t("game.results_modal.won")}</TableCell>
					</TableRow>
				</THead>
				<TBody>
					{results?.answers.map((answer) => (
						<TableRow key={answer.question[language]}>
							<STableCell className="half">{halfMapper[answer.half]}</STableCell>
							<STableCell className="question">
								{answer.question[language]}
							</STableCell>
							<STableCell className="bet">
								<span>{answer.bet || "-"}</span>
							</STableCell>
							<STableCell className="bet">
								<span className={getBackgroundByAnswer(answer.outcome)}>
									{answer.outcome ?? "-"}
								</span>
							</STableCell>
						</TableRow>
					))}
				</TBody>
			</STable>
			<TableBottom>
				<p>{t("game.results_modal.total_won")}</p>
				<p>{results?.roundPoints}</p>
			</TableBottom>
			<BarCode>
				<img src={AssetBarCode} alt="barcode" />
			</BarCode>
		</React.Fragment>
	);
};
