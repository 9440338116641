import React, {useCallback} from "react";
import {PrimaryButton, TBody, THead} from "components/Common";
import {Table, TableCell, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useDispatch, useSelector} from "react-redux";
import {toggleResultModal} from "modules/actions";
import {useNavigate} from "react-router-dom";
import {getMatchResult} from "modules/selectors";
import {Exist} from "components/Exist";
import {LocalPreloader} from "components/Preloader";
import {NullOrEmpty} from "modules/types";

const SubTitle = styled("h4")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;

	b {
		font-weight: bold;
	}
`;

const STable = styled(Table)`
	&.center {
		.MuiTableCell-head {
			text-align: center;
		}

		.MuiTableCell-body {
			text-align: center;
			font-weight: bold;
			border-bottom: none;
		}
	}

	.MuiTableRow-head {
		background: #000000;

		.MuiTableCell-head {
			color: #ffffff;
			text-transform: uppercase;
			font-weight: 400;

			&.bet,
			&.won {
				text-align: right !important;
			}
		}
	}
`;

const sumOf = (first: NullOrEmpty<number>, second: NullOrEmpty<number>) => {
	return (first ?? 0) + (second ?? 0);
};

const translationWon = "game.results_modal.won";

export const LeaderboardResultTable: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {t} = useTranslation();
	const results = useSelector(getMatchResult);

	const viewLeaderboard = useCallback(() => {
		dispatch(toggleResultModal(false));
		navigate("/leaderboard");
	}, [dispatch, navigate]);
	return (
		<React.Fragment>
			<SubTitle>
				<b>{t("game.results_modal.rankings")}</b>
			</SubTitle>
			<Exist when={Boolean(results)}>
				<STable className="center">
					<THead>
						<TableRow>
							<TableCell>{t("game.results_modal.bet_firts")}</TableCell>
							<TableCell>{t(translationWon)}</TableCell>
						</TableRow>
					</THead>
					<TBody>
						<TableRow>
							<TableCell>{results?.firstHalfBet}</TableCell>
							<TableCell>{results?.firstHalfOutcome}</TableCell>
						</TableRow>
					</TBody>
				</STable>
				<STable className="center">
					<THead>
						<TableRow>
							<TableCell>{t("game.results_modal.bet_second")}</TableCell>
							<TableCell>{t(translationWon)}</TableCell>
						</TableRow>
					</THead>
					<TBody>
						<TableRow>
							<TableCell>{results?.secondHalfBet}</TableCell>
							<TableCell>{results?.secondHalfOutcome}</TableCell>
						</TableRow>
					</TBody>
				</STable>
				<STable className="center">
					<THead>
						<TableRow>
							<TableCell>{t("game.results_modal.overall")}</TableCell>
							<TableCell>{t("game.results_modal.bet")}</TableCell>
							<TableCell>{t(translationWon)}</TableCell>
						</TableRow>
					</THead>
					<TBody>
						<TableRow>
							<TableCell>{results?.roundRank}</TableCell>
							<TableCell>
								{sumOf(results?.firstHalfBet, results?.secondHalfBet)}
							</TableCell>
							<TableCell>
								{sumOf(results?.firstHalfOutcome, results?.secondHalfOutcome)}
							</TableCell>
						</TableRow>
					</TBody>
				</STable>
			</Exist>
			<Exist when={!results}>
				<LocalPreloader color="#000000" />
			</Exist>
			<PrimaryButton className="active" onClick={viewLeaderboard}>
				{t("game.result_modal.leaderboard")}
			</PrimaryButton>
		</React.Fragment>
	);
};
