import React from "react";
import {MenuLink, MenuList} from "components/Common";
import {useTranslation} from "react-i18next";

export const MenuNonLogged: React.FC = () => {
	const {t} = useTranslation();
	return (
		<MenuList>
			<MenuLink to="/">{t("menu.home")}</MenuLink>
			<MenuLink to="/help">{t("menu.help")}</MenuLink>
		</MenuList>
	);
};
