import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {PureButton} from "components/Common";
import {Exist, IconMenu, IconStats} from "components";

import RaidersLogoAsset from "assets/img/logos/RaidersLogoNew.png";
import AssetIconTopShape from "assets/img/icons/TopShape.svg";
import SponsorLogo from "assets/img/logos/COXSponsorLogo.svg";
import {NO_HEADER_PATHS} from "modules/constants";
import {useLocation} from "react-router-dom";
import {toggleMenu, toggleResultModal} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getIsStatsVisible} from "modules/selectors";

const HeaderElement = styled("header")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 18px;
	//height: 66px;
	background: url(${AssetIconTopShape}), #000000;
	background-repeat: no-repeat;
	background-position: top center;
`;

const ActionButton = styled(PureButton)`
	width: 32px;
	height: 32px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TextBlock = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding-top: 2px;

	img.raiders {
		height: 80px;
		width: auto;
		padding-bottom: 8px;
	}

	p.title {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: ${({theme}) => theme.text.color.white};
		text-transform: uppercase;
	}
`;

const PoweredBy = styled.p`
	font-style: normal;
	font-weight: 600;
	font-size: ${({theme}) => theme.text.fontSize.regular.small_11};
	color: ${({theme}) => theme.text.color.white};
	line-height: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	gap: 4px;

	img {
		height: 12px;
	}
`;

const EmptyBlock = styled.div`
	width: 32px;
	height: 32px;
`;

export const Header: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const {t} = useTranslation();

	const isNoHeader = NO_HEADER_PATHS.includes(location.pathname);
	const isStatsVisible = useSelector(getIsStatsVisible) && location.pathname === "/predict";

	const openResultsModal = useCallback(() => {
		dispatch(toggleResultModal(true));
	}, [dispatch]);

	const openMenu = useCallback(() => {
		dispatch(toggleMenu(true));
	}, [dispatch]);

	if (isNoHeader) {
		return null;
	}

	return (
		<HeaderElement>
			<ActionButton onClick={openMenu}>
				<IconMenu />
			</ActionButton>
			<TextBlock>
				<img className="raiders" src={RaidersLogoAsset} alt="Raiders Logo" />
				<PoweredBy>
					{t("common.powered_by")}
					<img src={SponsorLogo} alt="COX sponsor" />
				</PoweredBy>
			</TextBlock>
			<Exist when={isStatsVisible}>
				<ActionButton onClick={openResultsModal}>
					<IconStats />
				</ActionButton>
			</Exist>
			<Exist when={!isStatsVisible}>
				<EmptyBlock />
			</Exist>
		</HeaderElement>
	);
};
