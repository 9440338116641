import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const PureButton = styled(Button)`
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	border: none;
	background: transparent;
	min-width: unset;

	text-transform: uppercase;
	width: 100%;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 0;

	transition: 0.25s;

	&:disabled {
		opacity: 0.5;
	}

	.loader {
		color: #ffffff;
	}
`;
