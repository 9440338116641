export enum PredictionSuggest {
	Correct,
	Incorrect,
}

export enum LeaguePrivacy {
	Private = "private",
	Public = "public",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum RequestStateType {
	Loading,
	Success,
	Error,
	Idle,
}

export enum MatchStatus {
	COMPLETE = "complete",
	FIRST_HALF = "first_half",
	HALF_TIME = "half_time",
	SECOND_HALF = "second_half",
	OVERTIME = "overtime",
	SCHEDULED = "scheduled",
	CANCELED = "canceled",
}

export enum PropStatus {
	UNUSED = "unused",
	OPEN = "open",
	LIVE = "live",
	RESULTED = "resulted",
}

export enum ContestStatus {
	Draft = "draft",
	Open = "open",
	Live = "live",
	Complete = "complete",
	Canceled = "canceled",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Live = "playing",
	Complete = "complete",
}

export enum ContestHalf {
	First = "first",
	Second = "second",
}

export enum ContactUsQuestionType {
	Account = "My Account",
	Auth = "Logging in/Registration",
	Manager = "Manager",
	Other = "Other",
}

export enum RegisterQueryMapper {
	Token = "token",
	ReportSuite = "reportSuite",
	IsStadium = "inStadium",
}

export enum ShareTarget {
	Facebook = "fb",
	Twitter = "tw",
	Native = "native",
}

export enum ShareType {
	League = "league",
	General = "general",
}

export enum LanguagesShort {
	English = "en",
	Spanish = "es",
}

export enum LeaderboardOrder {
	Weekly = "weekly",
	Season = "season",
}

export enum ENVS {
	UAT = "uat",
	PREPROD = "preprod",
	PROD = "production",
}
