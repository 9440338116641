import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconShare: React.FC<IGeneralIconProps>  = ({
                                        width = 26,
                                        height = 26,
                                        color = '#000000'
                                    }) => {
    return(
            <svg xmlns="http://www.w3.org/2000/svg"
                 width={width}
                 height={height}
                 viewBox="0 0 26 26">
                <path d="M19.3333 17.3067C18.5311 17.3067 17.8133 17.6233 17.2644 18.1195L9.73833 13.7389C9.79111 13.4961 9.83333 13.2533 9.83333 13C9.83333 12.7467 9.79111 12.5039 9.73833 12.2611L17.18 7.92278C17.75 8.45056 18.4994 8.77778 19.3333 8.77778C21.0856 8.77778 22.5 7.36334 22.5 5.61112C22.5 3.8589 21.0856 2.44445 19.3333 2.44445C17.5811 2.44445 16.1667 3.8589 16.1667 5.61112C16.1667 5.86445 16.2089 6.10723 16.2617 6.35001L8.82 10.6883C8.25 10.1606 7.50056 9.83334 6.66667 9.83334C4.91444 9.83334 3.5 11.2478 3.5 13C3.5 14.7522 4.91444 16.1667 6.66667 16.1667C7.50056 16.1667 8.25 15.8395 8.82 15.3117L16.3356 19.7028C16.2828 19.9245 16.2511 20.1567 16.2511 20.3889C16.2511 22.0883 17.6339 23.4711 19.3333 23.4711C21.0328 23.4711 22.4156 22.0883 22.4156 20.3889C22.4156 18.6895 21.0328 17.3067 19.3333 17.3067Z"
                      fill={color}/>
            </svg>
    );
}
