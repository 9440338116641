import React, {useCallback} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton} from "components/Common";

import AssetsTopImage from "assets/img/icons/ErrorModalChipsImage.svg";
import styled from "@emotion/styled";
import {closeErrorModal} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getErrorModalData} from "modules/selectors/Modal";
import {Exist} from "components/Exist";
import {useTranslation} from "react-i18next";

const SBottomSheet = styled(BottomSheet)`
	gap: 12px;
	padding-top: 70px;

	img {
		max-width: 190px;
		margin-bottom: 14px;
	}

	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		align-items: center;
		text-align: center;
	}

	h3 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
	}

	p {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
	}

	button {
		width: 100%;
		margin-top: auto;
	}
`;

export const GlobalErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const modalData = useSelector(getErrorModalData);

	const closeModal = useCallback(() => {
		dispatch(closeErrorModal());
	}, [dispatch]);

	if (modalData === null) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<img src={AssetsTopImage} alt="Error chips" />

				<Exist when={Boolean(modalData?.title)}>
					<h2 className="error-title-h2">{modalData?.title}</h2>
				</Exist>
				<Exist when={Boolean(modalData?.subTitle)}>
					<h3 className="error-title-h3">{modalData?.subTitle}</h3>
				</Exist>
				<p className="error-text">{modalData?.text}</p>

				<PrimaryButton className="error-button-submit" onClick={closeModal}>
					{t("common.close")}
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
