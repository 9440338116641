import {IEditPickPayload, IPickRequestPayload, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, delay, put} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {
	fetchAnswersByContestIdSuccess,
	fetchUserChipsByMatchSuccess,
	makePredictionSuccess,
	showApiErrorModal,
	togglePredictionRequestState,
} from "modules/actions";
import {RequestStateType} from "modules/enums";

export const fetchAnswersByContestIdSaga = function* ({
	payload,
}: ISagaAction<number>): SagaIterator {
	try {
		const response = yield* call(Api.Picks.getAnswersForContest, payload);
		ApiError.CHECK(response);

		yield* put(fetchAnswersByContestIdSuccess(response.success));
	} catch (e) {
		const error = e as Error;
		yield* put(showApiErrorModal(error.message));
	}
};

export const makePredictionSaga = function* ({
	payload,
}: ISagaAction<IPickRequestPayload>): SagaIterator {
	try {
		const response = yield* call(Api.Picks.saveAnswer, payload);
		ApiError.CHECK(response);
		yield* put(makePredictionSuccess());
	} catch (e) {
		const error = e as Error;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(1000);
		yield* put(
			togglePredictionRequestState({
				key: "makePick",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const editPredictionSaga = function* ({
	payload,
}: ISagaAction<IEditPickPayload>): SagaIterator {
	try {
		const response = yield* call(Api.Picks.editAnswer, payload);
		ApiError.CHECK(response);
		yield* put(makePredictionSuccess());
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(1000);
		yield* put(
			togglePredictionRequestState({
				key: "makePick",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchUserChipsByMatchSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const response = yield* call(Api.Picks.getChips, payload);
		ApiError.CHECK(response);
		yield* put(fetchUserChipsByMatchSuccess(response.success));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	}
};
