import {IStaticReducer, IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {memoize} from "lodash";
import {parseStateType} from "modules/utils/helpers";

const getState = ({staticReducer}: IStore) => staticReducer;

export const getHelpSections = createSelector(getState, (state) => state.sections);

export const getHelpSectionByKey = createSelector(getHelpSections, (sections) =>
	memoize((key: keyof IStaticReducer["sections"]) => {
		return sections[key];
	})
);

export const getContactUsRequestState = createSelector(getState, (state) =>
	parseStateType(state.requestState.contactUs)
);
