import styled from "@emotion/styled";
import React, {useCallback} from "react";

const Label = styled("label")`
	position: relative;
	width: 35px;
	height: 14px;
	background: #ffffff;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	margin: 0 12px;

	&.disabled {
		background: rgba(255, 255, 255, 0.5);
	}

	input {
		display: none;
	}
`;

const Toggle = styled("div")`
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-25%, -50%);
	width: 20px;
	height: 20px;
	background-color: ${({theme}) => theme.actionColors.picks};
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	pointer-events: none;
	transition: 0.25s;

	&.checked {
		left: 100%;
		transform: translate(-75%, -50%);
	}
`;

interface IProps {
	onChange?: (checked: boolean) => void;
	checked: boolean;
	disabled?: boolean;
}

export const Switch: React.FC<IProps> = ({onChange, checked, disabled}) => {
	const checkedClass = checked ? "checked" : "";
	const disabledClass = disabled ? "disabled" : "";

	const changeHandler = useCallback(
		(e: React.SyntheticEvent) => {
			if (onChange) {
				onChange(!checked);
			}
		},
		[checked, onChange]
	);

	return (
		<Label className={`switch ${disabledClass}`} aria-disabled={disabled}>
			<input type="checkbox" checked={checked} onChange={changeHandler} disabled={disabled} />
			<Toggle className={`${checkedClass} ${disabledClass}`} />
		</Label>
	);
};
