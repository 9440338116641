import React, {useCallback} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton} from "components/Common";
import AssetsTopImage from "assets/img/icons/InfoModalChipsImage.svg";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import styled from "@emotion/styled";
import {getIsInvitesModalOpen} from "modules/selectors/Modal";
import {toggleInvitesModal} from "modules/actions";

const SBottomSheet = styled(BottomSheet)`
	gap: 12px;
	padding-top: 110px;

	img {
		max-width: 190px;
		margin-bottom: 14px;
	}

	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		align-items: center;
		text-align: center;
	}

	p {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		padding: 0 48px;
		line-height: 19px;
		text-align: center;
	}

	button {
		width: 100%;
		margin-top: auto;
	}
`;

export const InvitesSentModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getIsInvitesModalOpen);

	const closeModal = useCallback(() => {
		dispatch(toggleInvitesModal(false));
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<img src={AssetsTopImage} alt="Info chips" />
				<h2 className="info-title-h2">{t("leagues.invite.title")}</h2>
				<p className="info-text">{t("leagues.invite.text")}</p>

				<PrimaryButton className="info-button-submit active" onClick={closeModal}>
					{t("common.close")}
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
