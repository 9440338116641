import React from "react";
import {useTranslation} from "react-i18next";
import {Nullable} from "modules/types";
import {useSelector} from "react-redux";
import {getPredictionById} from "modules/selectors";
import {calculatePredictionOutcome} from "modules/utils/helpers";

interface IProps {
	placed: number;
	predictionId: Nullable<number>;
}
export const WinChips: React.FC<IProps> = ({placed, predictionId}) => {
	const {t} = useTranslation();
	const pick = useSelector(getPredictionById)(Number(predictionId));
	const calculated = calculatePredictionOutcome(placed, pick);

	return (
		<div className="win">
			<h4>{t("game.prediction.to_win")}</h4>
			<p>{calculated || "-"}</p>
		</div>
	);
};
