import React from "react";
import {IGeneralIconProps} from "modules/types";

export const IconLock: React.FC<IGeneralIconProps> = ({
	width = 16,
	height = 16,
	color = "#FFFFFF",
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.99989 8.44444C7.511 8.44444 7.111 8.84444 7.111 9.33333C7.111 9.82222 7.511 10.2222 7.99989 10.2222C8.48878 10.2222 8.88878 9.82222 8.88878 9.33333C8.88878 8.84444 8.48878 8.44444 7.99989 8.44444ZM10.6666 6.22222H10.2221V5.33333C10.2221 4.10666 9.22656 3.11111 7.99989 3.11111C6.98656 3.11111 6.10211 3.79555 5.84878 4.77777C5.81814 4.89596 5.77767 5.33333 5.77767 5.33333C5.77767 5.55555 5.77767 6.22222 5.77767 6.22222H6.66656C6.66656 6.22222 6.66656 5.77777 6.66656 5.33333C6.67292 5.16747 6.70197 5.03483 6.711 5C6.86211 4.41333 7.391 4 7.99989 4C8.73322 4 9.33322 4.6 9.33322 5.33333V6.22222H6.66656H5.77767H5.33322C4.84434 6.22222 4.44434 6.62222 4.44434 7.11111V11.5556C4.44434 12.0444 4.84434 12.4444 5.33322 12.4444H10.6666C11.1554 12.4444 11.5554 12.0444 11.5554 11.5556V7.11111C11.5554 6.62222 11.1554 6.22222 10.6666 6.22222ZM10.6666 11.1111C10.6666 11.3556 10.4666 11.5556 10.2221 11.5556H5.77767C5.53322 11.5556 5.33322 11.3556 5.33322 11.1111V7.55555C5.33322 7.31111 5.53322 7.11111 5.77767 7.11111H10.2221C10.4666 7.11111 10.6666 7.31111 10.6666 7.55555V11.1111Z"
				fill={color}
			/>
		</svg>
	);
};
