import { default as createSagaMiddleware } from 'redux-saga';
import * as reducers from './reducers';
import { rootSaga } from './sagas';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

export const rootReducer = combineReducers(reducers);

const configureAppStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware],
    });

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureAppStore();
