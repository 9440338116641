import {IPredictionReducer, IStore, Nullable} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {memoize} from "lodash";
import {getActiveContest, getSelectedHalf, getSelectedMatch} from "modules/selectors/Matches";
import {parseStateType} from "modules/utils/helpers";
import {ContestHalf, ContestStatus} from "modules/enums";

const getState = ({predictionReducer}: IStore) => predictionReducer;

export const getPredictionRequestState = createSelector(getState, (state) => state.requestState);
export const getPredictionRequestStateByKey = createSelector(getPredictionRequestState, (states) =>
	memoize((key: keyof IPredictionReducer["requestState"]) => parseStateType(states[key]))
);
export const getUserChipsObject = createSelector(getState, (state) => state.chips);
export const getIsCameBack = createSelector(getUserChipsObject, (state) => state.isCameBack);
export const getUserChips = createSelector(
	getUserChipsObject,
	getSelectedHalf,
	(chipsObject, half) => {
		if (half === ContestHalf.First) {
			return chipsObject?.remainFirstHalf ?? 0;
		}
		return (chipsObject?.remainSecondHalf ?? 0) + (chipsObject?.wonSecondHalf ?? 0);
	}
);

export const getUserChipsAccordingContest = createSelector(
	getUserChipsObject,
	getActiveContest,
	(chipsObject, contest) => {
		if (!contest) {
			return 0;
		}
		const isContestWon = [ContestStatus.Live, ContestStatus.Complete].includes(
			contest?.status || ContestStatus.Draft
		);
		if (isContestWon) {
			return Number(
				contest.half === ContestHalf.First
					? chipsObject.wonFirstHalf
					: chipsObject.wonSecondHalf
			);
		}

		return Number(
			contest.half === ContestHalf.First
				? chipsObject.remainFirstHalf
				: chipsObject.remainSecondHalf
		);
	}
);

export const getPredictModalData = createSelector(getState, (state) => state.predictModalData);
export const getPredictModalCompleteData = createSelector(
	getState,
	(state) => state.predictModalCompleteData
);

export const getPredictionById = createSelector(getSelectedMatch, (match) =>
	memoize((predictionId: number) => {
		if (!match) {
			return null;
		}

		const firstContestProps = match.contests.firstHalf?.props.items;
		const secondContestProps = match.contests.secondHalf?.props.items;

		const predictions = [...(firstContestProps ?? []), ...(secondContestProps ?? [])];
		return predictions.find((item) => item.id === predictionId);
	})
);

export const getContestAnswers = createSelector(getState, (state) => state.answers);
export const getContestAnswerById = createSelector(getContestAnswers, (answers) =>
	memoize((questionId: Nullable<number> = 0) => {
		return answers.find((item) => item.questionId === questionId);
	})
);

export const getPredictionSliderData = createSelector(
	getUserChips,
	getContestAnswers,
	(userChips, answers) =>
		memoize((questionId: Nullable<number> = 0) => {
			const answer = answers.find((item) => item.questionId === questionId);
			const max = userChips + (answer?.bet || 0);
			return {
				min: 0,
				max,
				disabled: max === 0,
			};
		})
);
