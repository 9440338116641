import {isFunction, map, padStart, split} from "lodash";
import React, {MutableRefObject} from "react";
import styled, {css} from "styled-components";
import {DateTime} from "luxon";
import {connect} from "react-redux";

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

const scaleToZero = css`
	@keyframes scale {
		25% {
			opacity: 1;
		}

		100% {
			transform: scale(0.25);
			opacity: 0;
		}
	}

	-webkit-animation: scale 1s forwards !important;
	animation: scale 1s forwards !important;
`;

const StyledElem = styled.div`
	&.animated {
		${scaleToZero}
	}
`;

interface ITimerPart {
	index: number;
}

const TimerPart = styled.div<ITimerPart>`
	${(props) =>
		props.index < 1
			? css`
					text-align: right;
			  `
			: css`
					text-align: left;
			  `}
`;

const defaultTimerValue = {
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	diff: 0,
};

interface IProps {
	format?: string;
	noRender?: boolean;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
	date: number | string | Date;
	is_animated?: boolean;
	as?: string;
	separator?: React.ReactNode;
	separator_key?: string;
	withPadStart?: boolean;
	autoFormat?: boolean;
	autoFormatChangeCallback?: (format: string) => void;
	onCompleteDelay?: number;
	calculateToFormat?: boolean;
	innerRef?: MutableRefObject<number>;
	forceComplete?: boolean;
}

export interface IState {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	diff: number;
	completed: boolean;
	lastFormat: string;
}

class TimerComponent extends React.Component<IProps, IState> {
	public static defaultProps = {
		format: "hh:mm:ss",
		withPadStart: true,
	};
	public state = {
		...defaultTimerValue,
		completed: false,
		lastFormat: "",
	};
	private is_mounted = false;
	private timer?: ReturnType<typeof setInterval>;

	constructor(props: IProps) {
		super(props);

		this.state = {
			...this.state,
			...this.timerValues(this.duration),
			lastFormat: props.format ?? "",
		};
	}

	private get duration() {
		const startDate = DateTime.fromJSDate(new Date());
		const endDate = DateTime.fromJSDate(new Date(this.props.date));

		return endDate.diff(startDate).get("milliseconds");
	}

	private get base_timer() {
		const {diff, minutes, seconds, hours, days} = this.state;
		const {
			format = "",
			separator,
			separator_key = ":",
			withPadStart = true,
			autoFormat = false,
		} = this.props;

		if (!diff) {
			return withPadStart ? "00" : "0";
		}

		const padStartValue = withPadStart ? 2 : 0;
		const secs = padStart(String(seconds), padStartValue, "0");
		const mins = padStart(String(minutes), padStartValue, "0");
		const hrs = padStart(String(hours), padStartValue, "0");
		const ds = padStart(String(days), padStartValue, "0");

		const {HH, MM, SS} = this.getCalculatedValue();

		const replaceFn = (str: string) => {
			return str
				.replace("SS", SS)
				.replace("MM", MM)
				.replace("HH", HH)
				.replace("ss", secs)
				.replace("mm", mins)
				.replace("hh", hrs)
				.replace("dd", ds);
		};

		if (autoFormat) {
			return replaceFn(this.autoFormatString);
		}

		if (separator) {
			const format_array = split(format, separator_key);

			const value = format_array.map(replaceFn);

			return (
				<React.Fragment>
					{map(value, (time: number | string, index: number) => (
						<React.Fragment key={`${time} ${index}`}>
							<TimerPart index={index}>{time}</TimerPart>
							{/* eslint-disable-next-line sonarjs/no-gratuitous-expressions */}
							{index + 1 < format_array.length && separator}
						</React.Fragment>
					))}
				</React.Fragment>
			);
		}

		return replaceFn(format);
	}

	private get autoFormatString() {
		const {minutes, seconds, hours, days} = this.state;
		const {format} = this.props;

		if (days !== 0) {
			return "dd";
		}

		if (hours !== 0) {
			return "hh";
		}

		if (minutes !== 0) {
			return "mm";
		}

		if (seconds !== 0) {
			return "ss";
		}
		return format ?? "";
	}

	private get animated_timer() {
		const {diff, seconds, minutes = 0} = this.state;

		if (!diff) {
			return "";
		}

		return seconds + minutes * 60;
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.log(error);
	}

	public componentDidUpdate() {
		const {autoFormat, autoFormatChangeCallback} = this.props;
		const {lastFormat} = this.state;
		if (autoFormat) {
			const format = this.autoFormatString;
			if (lastFormat !== format && autoFormatChangeCallback) {
				this.setState({
					...this.state,
					lastFormat: format,
				});

				autoFormatChangeCallback(format);
			}
		}
	}

	/**
	 * @ignore
	 */
	public render() {
		const {is_animated, as, noRender} = this.props;

		if (noRender) {
			return null;
		}
		const value = this.base_timer;

		if (is_animated) {
			const animated_value = this.animated_timer;
			const animatedClass = is_animated ? "animated" : "";

			return as ? (
				<StyledElem
					className={`${animatedClass} timer-value`}
					key={animated_value}
					as={as as never}>
					{animated_value}
				</StyledElem>
			) : (
				animated_value
			);
		}

		return as ? <StyledElem className="timer-value">{value}</StyledElem> : value;
	}

	/**
	 * @ignore
	 */
	public componentDidMount() {
		const {forceComplete} = this.props;
		if (this.duration <= 0 && forceComplete) {
			this.handleCompleteState();
			return;
		}

		if (this.duration <= 0) {
			return;
		}

		this.timer = setInterval(() => this.diff(), 333);

		this.is_mounted = true;
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		this.is_mounted = false;
		this.clearTimer();
	}

	/**
	 * Explanation:
	 * 86400 - seconds in day
	 * 3600 - seconds in hour
	 * 1440 - minutes in day
	 * 60 - minutes in hour, seconds in minute
	 * 24 - hours in day
	 */

	private getCalculatedValue() {
		const {minutes, seconds, hours, days} = this.state;
		const {withPadStart = true} = this.props;

		const padStartValue = withPadStart ? 2 : 0;

		const calculatedHours = days * 24 + hours;
		const calculatedMins = days * 1440 + hours * 60 + minutes;
		const calculatedSecs = days * 86400 + hours * 3600 + minutes * 60 + seconds;

		const padCalculatedHours = padStart(String(calculatedHours), padStartValue, "0");
		const padCalculatedMins = padStart(String(calculatedMins), padStartValue, "0");
		const padCalculatedSecs = padStart(String(calculatedSecs), padStartValue, "0");

		return {
			DD: days,
			HH: padCalculatedHours,
			MM: padCalculatedMins,
			SS: padCalculatedSecs,
		};
	}

	private clearTimer(): void {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	private timerValues(diff: number) {
		if (diff < MS_IN_SECOND) {
			return defaultTimerValue;
		}

		const seconds = Math.floor(diff / MS_IN_SECOND);
		const minutes = Math.floor(seconds / SEC_IN_MINUTE);
		const hours = Math.floor(minutes / MIN_IN_HOUR);

		return {
			diff,
			days: Math.floor(hours / HRS_IN_DAY),
			hours: hours % HRS_IN_DAY,
			minutes: minutes % MIN_IN_HOUR,
			seconds: seconds % SEC_IN_MINUTE,
		};
	}

	private diff() {
		const diff = this.duration;
		const {onTick, onCompleteDelay, innerRef} = this.props;

		if (innerRef) {
			innerRef.current = diff;
		}

		if (isFunction(onTick)) {
			onTick(diff);
		}

		const delay = onCompleteDelay ?? 400;
		if (this.is_mounted) {
			if (diff <= delay) {
				return this.handleCompleteState();
			}

			this.setState(this.timerValues(diff));
		} else {
			this.clearTimer();
		}
	}

	private handleCompleteState() {
		this.clearTimer();

		const {onComplete} = this.props;

		this.setState({
			completed: true,
			...defaultTimerValue,
		});

		if (isFunction(onComplete)) {
			onComplete();
		}
	}
}

export const Timer = connect()(TimerComponent);
