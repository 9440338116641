import styled from "@emotion/styled";
import {TableHead} from "@mui/material";

export const THead = styled(TableHead)(({theme}) => ({
	background: theme.actionColors.picks,
	color: theme.text.color.white,

	"& .MuiTableCell-root": {
		borderBottom: "none",
		paddingBottom: "10px",
		paddingTop: "10px",
		padding: "10px 8px",
	},

	"& .MuiTableCell-root:first-of-type": {
		padding: "10px 8px 10px 18px",
		borderBottom: "none",
	},
	"& .MuiTableCell-root:last-of-type": {
		padding: "10px 18px 10px 8px",
	},
	".rank": {
		paddingRight: "0 !important",
	},

	"& .MuiTableCell-root.active": {
		background: "#0091d0",
	},

	".username": {
		width: "100%",
	},

	".points": {
		textAlign: "right",
		maxWidth: "72px",
		minWidth: "72px",
		width: "100%",
	},
}));
