import styled from "@emotion/styled";
import React, {useCallback, useState} from "react";
import {Exist} from "components/Exist";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getActiveMatchNextContest, getActiveMatchStates} from "modules/selectors";
import {DateTime} from "luxon";
import {IconLock, Timer} from "components";
import {fetchMatchesRequest} from "modules/actions";
import {LanguagesShort} from "modules/enums";

const Wrapper = styled("div")`
	min-width: 180px;
	min-height: 32px;
	padding: 6px 12px;
	border-radius: 6px;
	background: ${({theme}) => theme.actionColors.inPlay};
	display: flex;
	align-items: center;
	justify-content: center;

	&.live {
		background: ${({theme}) => theme.actionColors.live};
	}

	&.schedule {
		background: ${({theme}) => theme.actionColors.picks};
		justify-content: space-between;
		gap: 10px;
	}

	&.half-time {
		background: ${({theme}) => theme.actionColors.picks};
	}

	&.complete {
		background: ${({theme}) => theme.actionColors.inPlay};
	}

	span {
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		white-space: nowrap;

		&.bold {
			font-weight: 700;
		}
	}
`;

interface IGetActiveMatchStatesResult {
	isLive: boolean;
	isSchedule: boolean;
	isComplete: boolean;
	isHalfTime: boolean;
}

function getClasses(state: IGetActiveMatchStatesResult) {
	if (state.isLive) {
		return "live";
	}

	if (state.isComplete) {
		return "complete";
	}

	if (state.isHalfTime) {
		return "half-time";
	}

	return "schedule";
}

export const PredictWeekTimer: React.FC = () => {
	const state = useSelector(getActiveMatchStates);
	const {t} = useTranslation();
	const {isLive, isSchedule, isComplete, isHalfTime} = state;
	const stateClass = getClasses(state);

	if (isSchedule) {
		return <ScheduleElement />;
	}

	return (
		<Wrapper className={stateClass}>
			<Exist when={isHalfTime}>
				<span className="bold">{t("game.state.half_time")}</span>
			</Exist>

			<Exist when={isLive}>
				<span className="bold">{t("game.state.live")}</span>
			</Exist>
			<Exist when={isComplete}>
				<span className="bold">{t("game.state.complete")}</span>
			</Exist>
		</Wrapper>
	);
};

const ScheduleElement = () => {
	const dispatch = useDispatch();
	const [isLocked, setIsLocked] = useState(false);
	const {t, i18n} = useTranslation();
	const nextContest = useSelector(getActiveMatchNextContest);
	const language = i18n.language as LanguagesShort;

	const onComplete = useCallback(() => {
		dispatch(fetchMatchesRequest());
		setIsLocked(true);
	}, [dispatch]);

	if (!nextContest) {
		return null;
	}

	const contestStartDateTime = DateTime.fromISO(nextContest.date);
	const daysDiff = contestStartDateTime.diffNow("days").days;
	const format = daysDiff > 1 ? "dd:hh:mm:ss" : "hh:mm:ss";
	const startDate = contestStartDateTime.toJSDate();

	return (
		<Wrapper className={`schedule ${language}`}>
			<span>{t("game.state.locks_in")}</span>
			<Exist when={isLocked}>
				<IconLock color="#000000" width={20} height={20} />
			</Exist>
			<Exist when={!isLocked}>
				<span className="bold">
					<Timer
						format={format}
						date={startDate}
						forceComplete={true}
						withPadStart={true}
						onComplete={onComplete}
					/>
				</span>
			</Exist>
		</Wrapper>
	);
};
