import styled from "@emotion/styled";

export const SubTitle = styled("h3")`
	color: ${({theme}) => theme.text.color.white};
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.4px;
	text-transform: capitalize;
	margin-bottom: 16px;
`;

export const TextBlock = styled("p")`
	color: ${({theme}) => theme.text.color.white};
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.4px;
	margin-bottom: 16px;

	b {
		font-weight: bold;
	}
`;
