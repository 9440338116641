import {
	IContactUsPayload,
	IHelpSectionActionPayload,
	IReducerAction,
	IStaticReducer,
} from "modules/types";
import {RequestStateType} from "modules/enums";
import {createReducer} from "redux-act";
import {
	fetchHelpSectionSuccess,
	sendContactUsClear,
	sendContactUsRequest,
	sendContactUsSuccess,
} from "modules/actions";

type IStaticReducerPayload<TP = null> = IReducerAction<IStaticReducer, TP>;

const initialState: IStaticReducer = {
	sections: {
		faq: null,
		terms: null,
		prizes: null,
	},

	requestState: {
		contactUs: RequestStateType.Idle,
	},
};

const onFetchHelpSectionSuccess: IStaticReducerPayload<IHelpSectionActionPayload> = (
	state,
	payload
) => ({
	...state,
	sections: {
		...state.sections,
		[payload.section]: payload.data,
	},
});

const onSendContactUsRequest: IStaticReducerPayload<IContactUsPayload> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		contactUs: RequestStateType.Loading,
	},
});

const onSendContactUsSuccess: IStaticReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		contactUs: RequestStateType.Success,
	},
});

const onSendContactUsClear: IStaticReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		contactUs: RequestStateType.Idle,
	},
});

export const staticReducer = createReducer<IStaticReducer>({}, initialState)
	.on(fetchHelpSectionSuccess, onFetchHelpSectionSuccess)
	.on(sendContactUsRequest, onSendContactUsRequest)
	.on(sendContactUsSuccess, onSendContactUsSuccess)
	.on(sendContactUsClear, onSendContactUsClear);
