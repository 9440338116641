import React from "react";
import {IGeneralIconProps} from "modules/types";

export const IconTwitter: React.FC<IGeneralIconProps> = ({
	width = 24,
	height = 24,
	color = "#000000",
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.2812 7.1075L11.3316 7.93795L10.4922 7.83627C7.43686 7.44646 4.76765 6.12451 2.50134 3.90432L1.39336 2.8027L1.10798 3.6162C0.503625 5.42964 0.889738 7.34477 2.1488 8.63282C2.8203 9.34464 2.66921 9.44633 1.51088 9.02263C1.10798 8.88705 0.755438 8.78536 0.721863 8.8362C0.60435 8.95484 1.00725 10.4971 1.32621 11.1072C1.76269 11.9546 2.65243 12.7851 3.6261 13.2766L4.44869 13.6664L3.47501 13.6833C2.53491 13.6833 2.50134 13.7003 2.60206 14.0562C2.93781 15.1578 4.26403 16.3272 5.74133 16.8357L6.78215 17.1916L5.87563 17.7339C4.53263 18.5135 2.9546 18.9542 1.37658 18.9881C0.621138 19.005 0 19.0728 0 19.1237C0 19.2931 2.04808 20.2422 3.23999 20.6151C6.81573 21.7167 11.063 21.2422 14.2526 19.3609C16.5189 18.022 18.7852 15.3612 19.8428 12.7851C20.4136 11.4123 20.9844 8.90399 20.9844 7.70068C20.9844 6.92107 21.0347 6.81938 21.9748 5.88724C22.5288 5.3449 23.0492 4.75172 23.15 4.58224C23.3178 4.26023 23.3011 4.26023 22.4449 4.54835C21.018 5.05679 20.8165 4.989 21.5216 4.22633C22.042 3.684 22.6631 2.70101 22.6631 2.41289C22.6631 2.36205 22.4113 2.44679 22.1259 2.59932C21.8238 2.7688 21.1523 3.02302 20.6486 3.17555L19.7421 3.46367L18.9195 2.90439C18.4663 2.59932 17.8283 2.26036 17.4926 2.15867C16.6364 1.9214 15.327 1.9553 14.5548 2.22646C12.4563 2.98913 11.1301 4.9551 11.2812 7.1075Z"
				fill={color}
			/>
		</svg>
	);
};
