import React from "react";
import {GlobalErrorModal, ReturningUserModal} from "components/Modals";

interface IProps {
	children: React.ReactNode;
}

export const HOCModals: React.FC<IProps> = ({children}) => {
	return (
		<React.Fragment>
			{children}
			<GlobalErrorModal />
			<ReturningUserModal />
		</React.Fragment>
	);
};
