import {IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";

const getState = ({UIReducer}: IStore) => UIReducer;

export const getIsMenuOpen = createSelector(getState, (state) => state.isMenuOpen);
export const getIsReceiveAnimationPlaying = createSelector(
	getState,
	(state) => state.isChipsAnimationPlaying
);
