import React from 'react';
import { useTour } from '@reactour/tour';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0 4px 0;
`

const StepItem = styled('div')`
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: ${({theme}) => theme.background.grey};
  
  &.active {
    background: ${({theme}) => theme.background.black};
  }
`

export const TutorialSteps: React.FC = () => {
    const {steps, currentStep} = useTour();

    const getActiveClass = (current: number, index: number) => {
        return current === index ? 'active' : ''
    }

    return (
        <Wrapper>
            {steps.map((item, index) => (
                <StepItem key={index} className={getActiveClass(currentStep, index)} />
            ))}
        </Wrapper>
    );
}
