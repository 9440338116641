import {createReducer} from "redux-act";
import {RequestStateType} from "modules/enums";
import {
	ICreateLeaguePayload,
	IJoinLeaguePayload,
	ILeague,
	ILeagueForJoinResponse,
	ILeagueRemoveUserModalAction,
	ILeaguesFetchPayload,
	ILeaguesReducer,
	ILeaguesReducerPayload,
	ILeagueStateChangePayload,
	ILeagueUser,
	ISingleLeague,
	IUpdateLeaguePayload,
	IUserLeaguesResponse,
} from "modules/types";
import {
	createLeagueRequest,
	createLeagueSuccess,
	fetchLeagueUsersClear,
	fetchLeagueUsersRequest,
	fetchLeagueUsersSuccess,
	fetchSingleLeagueRequest,
	fetchSingleLeagueSuccess,
	fetchUserLeaguesConcat,
	fetchUserLeaguesRequest,
	fetchUserLeaguesSuccess,
	joinToLeagueRequest,
	leaveLeagueRequest,
	leaveLeagueSuccess,
	removeLeagueUserRequest,
	removeLeagueUserSuccess,
	showForJoinConcat,
	showForJoinRequest,
	showForJoinSuccess,
	skipLeagueCreateStepTwo,
	toggleLeaguesRequestState,
	updateLeagueRequest,
	updateLeagueSuccess,
} from "modules/actions/Leagues";

const initialState: ILeaguesReducer = {
	userLeagues: {
		leagues: [],
		hasNext: false,
	},
	forJoinLeagues: {
		leagues: [],
		nextPage: false,
	},
	newLeague: null,
	singleLeague: {
		isJoined: false,
		league: null,
	},
	leagueUsers: [],
	requestState: {
		userLeagues: RequestStateType.Idle,
		singleLeague: RequestStateType.Idle,
		createLeague: RequestStateType.Idle,
		fetchJoinLeagues: RequestStateType.Idle,
		joinLeague: RequestStateType.Idle,
		fetchLeagueUsers: RequestStateType.Idle,
		removeLeagueUser: RequestStateType.Idle,
		leaveLeague: RequestStateType.Idle,
		updateLeague: RequestStateType.Idle,
	},
};

const onToggleLeaguesRequestState: ILeaguesReducerPayload<ILeagueStateChangePayload> = (
	state,
	payload
) => ({
	...state,
	requestState: {
		...state.requestState,
		[payload.key]: payload.state,
	},
});

const onFetchUserLeaguesRequest: ILeaguesReducerPayload<ILeaguesFetchPayload> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		userLeagues: RequestStateType.Loading,
	},
});

const onFetchUserLeaguesSuccess: ILeaguesReducerPayload<IUserLeaguesResponse> = (
	state,
	payload
) => ({
	...state,
	userLeagues: payload,
	requestState: {
		...state.requestState,
		userLeagues: RequestStateType.Idle,
	},
});

const onFetchUserLeaguesConcat: ILeaguesReducerPayload<IUserLeaguesResponse> = (
	state,
	payload
) => ({
	...state,
	userLeagues: {
		leagues: state.userLeagues.leagues.concat(payload.leagues),
		hasNext: payload.hasNext,
	},
	requestState: {
		...state.requestState,
		userLeagues: RequestStateType.Idle,
	},
});

const onCreateLeagueRequest: ILeaguesReducerPayload<ICreateLeaguePayload> = (state, payload) => ({
	...state,
	requestState: {
		...state.requestState,
		createLeague: RequestStateType.Loading,
	},
});

const onCreateLeagueSuccess: ILeaguesReducerPayload<ILeague> = (state, payload) => ({
	...state,
	newLeague: payload,
	requestState: {
		...state.requestState,
		createLeague: RequestStateType.Idle,
	},
});

const onSkipLeagueCreateStepTwo: ILeaguesReducerPayload = (state) => ({
	...state,
	newLeague: null,
	requestState: {
		...state.requestState,
		createLeague: RequestStateType.Idle,
	},
});

const onShowForJoinRequest: ILeaguesReducerPayload<IJoinLeaguePayload> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		fetchJoinLeagues: RequestStateType.Loading,
	},
});

const onShowForJoinSuccess: ILeaguesReducerPayload<ILeagueForJoinResponse> = (state, payload) => ({
	...state,
	forJoinLeagues: payload,
	requestState: {
		...state.requestState,
		fetchJoinLeagues: RequestStateType.Idle,
	},
});

const onShowForJoinConcat: ILeaguesReducerPayload<ILeagueForJoinResponse> = (state, payload) => ({
	...state,
	forJoinLeagues: {
		leagues: state.forJoinLeagues.leagues.concat(payload.leagues),
		nextPage: payload.nextPage,
	},
	requestState: {
		...state.requestState,
		fetchJoinLeagues: RequestStateType.Idle,
	},
});

const onJoinToLeagueRequest: ILeaguesReducerPayload<string> = (state, payload) => ({
	...state,
	requestState: {
		...state.requestState,
		joinLeague: RequestStateType.Loading,
	},
});

const onFetchSingleLeagueRequest: ILeaguesReducerPayload<number> = (state, payload) => ({
	...state,
	requestState: {
		...state.requestState,
		singleLeague: RequestStateType.Loading,
	},
});

const onFetchSingleLeagueSuccess: ILeaguesReducerPayload<ISingleLeague> = (state, payload) => ({
	...state,
	userLeagues: {
		leagues: [...state.userLeagues.leagues, payload.league],
		hasNext: state.userLeagues.hasNext,
	},
	singleLeague: payload,
	requestState: {
		...state.requestState,
		singleLeague: RequestStateType.Idle,
	},
});

const onFetchLeagueUsersRequest: ILeaguesReducerPayload<number> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		fetchLeagueUsers: RequestStateType.Loading,
	},
});

const onFetchLeagueUsersSuccess: ILeaguesReducerPayload<ILeagueUser[]> = (state, payload) => ({
	...state,
	leagueUsers: payload,
	requestState: {
		...state.requestState,
		fetchLeagueUsers: RequestStateType.Idle,
	},
});

const onFetchLeagueUsersClear: ILeaguesReducerPayload = (state) => ({
	...state,
	leagueUsers: [],
	requestState: {
		...state.requestState,
		fetchLeagueUsers: RequestStateType.Idle,
	},
});

const onRemoveLeagueUserRequest: ILeaguesReducerPayload<ILeagueRemoveUserModalAction> = (
	state
) => ({
	...state,
	requestState: {
		...state.requestState,
		removeLeagueUser: RequestStateType.Loading,
	},
});

const onRemoveLeagueUserSuccess: ILeaguesReducerPayload<ILeagueRemoveUserModalAction> = (
	state
) => ({
	...state,
	requestState: {
		...state.requestState,
		removeLeagueUser: RequestStateType.Success,
	},
});

const onLeaveLeagueRequest: ILeaguesReducerPayload<number> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		leaveLeague: RequestStateType.Loading,
	},
});

const onLeaveLeagueSuccess: ILeaguesReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		leaveLeague: RequestStateType.Success,
	},
});

const onUpdateLeagueRequest: ILeaguesReducerPayload<IUpdateLeaguePayload> = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		updateLeague: RequestStateType.Loading,
	},
});

const onUpdateLeagueSuccess: ILeaguesReducerPayload = (state) => ({
	...state,
	requestState: {
		...state.requestState,
		updateLeague: RequestStateType.Success,
	},
});

export const leaguesReducer = createReducer<ILeaguesReducer>({}, initialState)
	.on(toggleLeaguesRequestState, onToggleLeaguesRequestState)

	.on(fetchUserLeaguesRequest, onFetchUserLeaguesRequest)
	.on(fetchUserLeaguesSuccess, onFetchUserLeaguesSuccess)
	.on(fetchUserLeaguesConcat, onFetchUserLeaguesConcat)

	.on(createLeagueRequest, onCreateLeagueRequest)
	.on(createLeagueSuccess, onCreateLeagueSuccess)
	.on(skipLeagueCreateStepTwo, onSkipLeagueCreateStepTwo)

	.on(updateLeagueRequest, onUpdateLeagueRequest)
	.on(updateLeagueSuccess, onUpdateLeagueSuccess)

	.on(showForJoinRequest, onShowForJoinRequest)
	.on(showForJoinSuccess, onShowForJoinSuccess)
	.on(showForJoinConcat, onShowForJoinConcat)

	.on(joinToLeagueRequest, onJoinToLeagueRequest)

	.on(fetchSingleLeagueRequest, onFetchSingleLeagueRequest)
	.on(fetchSingleLeagueSuccess, onFetchSingleLeagueSuccess)

	.on(fetchLeagueUsersRequest, onFetchLeagueUsersRequest)
	.on(fetchLeagueUsersSuccess, onFetchLeagueUsersSuccess)
	.on(fetchLeagueUsersClear, onFetchLeagueUsersClear)

	.on(removeLeagueUserRequest, onRemoveLeagueUserRequest)
	.on(removeLeagueUserSuccess, onRemoveLeagueUserSuccess)

	.on(leaveLeagueRequest, onLeaveLeagueRequest)
	.on(leaveLeagueSuccess, onLeaveLeagueSuccess);
