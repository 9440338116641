import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconChip: React.FC<IGeneralIconProps> = ({
                                                        width = 44,
                                                        height = 44,
                                                        color = '#0CDD63'
                                                    }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 44" fill="none">
            <g filter="url(#filter0_d_419_32345)">
                <mask id="mask0_419_32345"
                      style={{maskType: "alpha"}}
                      maskUnits="userSpaceOnUse"
                      x="2" y="0"
                      width="40"
                      height="40">
                    <circle cx="22" cy="20" r="20" fill="#C4C4C4"/>
                </mask>
                <g mask="url(#mask0_419_32345)">
                    <circle cx="22" cy="20" r="20" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M21.9571 20.1644L17.0204 -0.974854H26.8938L21.9571 20.1644ZM21.9571 20.1644L37.7959 5.31948L42.7326 13.8701L21.9571 20.1644ZM21.9571 20.1644L37.7959 35.0093L42.7326 26.4587L21.9571 20.1644ZM21.9571 20.1644L26.8938 41.3036H17.0204L21.9571 20.1644ZM21.9571 20.1644L6.11835 35.0093L1.18164 26.4587L21.9571 20.1644ZM1.18164 13.8701L21.9571 20.1644L6.11835 5.31947L1.18164 13.8701Z"
                          fill={color}/>
                    <g filter="url(#filter1_i_419_32345)">
                        <circle cx="21.9996" cy="20" r="13.6364" fill="white"/>
                    </g>
                    <g filter="url(#filter2_i_419_32345)">
                        <ellipse cx="22.018" cy="20.0178" rx="11.7709" ry="11.7709" fill="black"/>
                    </g>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_419_32345" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_419_32345"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_419_32345" result="shape"/>
                </filter>
                <filter id="filter1_i_419_32345" x="8.36328" y="6.36365" width="27.2725" height="27.2727"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="0.75"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_419_32345"/>
                </filter>
                <filter id="filter2_i_419_32345" x="10.2471" y="8.24695" width="23.542" height="23.5418"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_419_32345"/>
                </filter>
            </defs>
        </svg>
    );
}
