import {Modal, ModalBackdrop, ModalBody, PrimaryButton, PureButton} from "components/Common";
import React, {useCallback} from "react";
import {IconClose} from "components/Icons";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {useTour} from "@reactour/tour";
import {useDispatch, useSelector} from "react-redux";
import {toggleWelcomeModal} from "modules/actions";
import {LocalStorageService} from "modules/utils/LocalStorageService";
import {TUTORIAL_KEY} from "modules/constants";
import {getIsWelcomeModalOpen} from "modules/selectors";

const SModalBody = styled(ModalBody)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};

	h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		text-transform: capitalize;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		padding-left: 12px;
		padding-right: 12px;
	}

	.submit {
		width: auto;
		margin-top: 20px;
	}
`;

const CloseButton = styled(PureButton)`
	width: 24px;
	margin-left: auto;
`;

export const WelcomeModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {setIsOpen} = useTour();
	const isOpen = useSelector(getIsWelcomeModalOpen);

	const closeModal = useCallback(() => {
		dispatch(toggleWelcomeModal(false));
		LocalStorageService.SET(TUTORIAL_KEY, true);
	}, [dispatch]);

	const openTutorial = useCallback(() => {
		setIsOpen(true);
		closeModal();
	}, [closeModal, setIsOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalBackdrop>
			<Modal>
				<SModalBody>
					<CloseButton onClick={closeModal}>
						<IconClose color="#000000" />
					</CloseButton>
					<h2>{t("game.welcome.title")}</h2>
					<p>{t("game.welcome.text_1")}</p>
					<p>{t("game.welcome.text_2")}</p>
					<PrimaryButton className="submit" onClick={openTutorial}>
						{t("game.welcome.submit")}
					</PrimaryButton>
				</SModalBody>
			</Modal>
		</ModalBackdrop>
	);
};
