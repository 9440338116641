import React, {useCallback, useEffect} from "react";
import styled from "@emotion/styled";
import {
	Exist,
	HalfTimeModal,
	IconInfo,
	LocalPreloader,
	PredictButton,
	PredictHalfToggle,
	PredictModal,
	PredictSavedModal,
	PredictTutorialButton,
	ResultsModal,
	SponsorProp,
	WelcomeModal,
} from "components";
import {useTranslation} from "react-i18next";
import {PureButton} from "components/Common";
import {useTour} from "@reactour/tour";

import AssetTable from "assets/img/PredictionTable.svg";
import {useDispatch, useSelector} from "react-redux";
import {
	getActiveContest,
	getContestAnswerById,
	getContestAnswers,
	getIsActiveContestLocked,
	getPropsForMatchAndHalf,
	getSelectedMatch,
} from "modules/selectors";
import {IContestProp} from "modules/types";
import {
	fetchAnswersByContestIdRequest,
	openErrorModal,
	openPredictionCompleteModal,
	openPredictionModal,
	playReceiveChipsAnimation,
	toggleScoringModal,
} from "modules/actions";
import {ContestHalf, ContestStatus} from "modules/enums";
import {ContestService} from "modules/utils/ContestService";
import {PredictModalComplete} from "components/Modals/PredictModalComplete";

const Wrapper = styled("div")`
	padding: 6px 10px 84px 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const FieldImage = styled("img")`
	width: 100%;
	height: auto;
`;

const Field = styled("div")`
	position: relative;
	width: 100%;
	background-size: 100% 100%;
`;

const HeadingBlock = styled("h4")`
	width: 100%;
	position: relative;
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 800;
	font-size: 26px;
	line-height: 31px;
	text-align: center;
	letter-spacing: -1px;
	text-transform: uppercase;
`;

const HalfBlock = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	width: 100%;
`;

const ActionButton = styled(PureButton)`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 26px;
`;

const FieldContent = styled("div")`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 42px 56px;
	gap: 24px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const PredictField: React.FC = () => {
	const dispatch = useDispatch();
	const {isOpen} = useTour();
	const {t} = useTranslation();
	const contest = useSelector(getActiveContest);
	const props = useSelector(getPropsForMatchAndHalf);
	const hasProps = props.items.length > 0;
	const answers = useSelector(getContestAnswers);

	useEffect(() => {
		if (contest) {
			dispatch(fetchAnswersByContestIdRequest(contest.id));
		}
	}, [contest, dispatch]);

	useEffect(() => {
		if (!contest) {
			return;
		}

		if ([ContestStatus.Canceled, ContestStatus.Draft].includes(contest.status)) {
			ContestService.removeContestFromStorage(contest.id);
			return;
		}

		const isPlayAnimation = ContestService.checkIsPlayChipsAnimation(answers, contest.id);
		if (isPlayAnimation) {
			dispatch(playReceiveChipsAnimation());
		}
	}, [answers, contest, dispatch]);

	const openScoringModal = useCallback(() => {
		dispatch(toggleScoringModal(true));
	}, [dispatch]);

	return (
		<Wrapper>
			<PredictHalfToggle />
			<Field>
				<FieldImage src={AssetTable} alt="field" />
				<FieldContent>
					<HeadingBlock>
						<span>{t("game.raiders")}</span>
						<ActionButton className="action-button" onClick={openScoringModal}>
							<IconInfo />
						</ActionButton>
					</HeadingBlock>

					<Exist when={!hasProps}>
						<LocalPreloader />
					</Exist>

					<Exist when={hasProps}>
						<HalfBlock>
							<PropItem property={props.items[0]} />
							<PropItem property={props.items[1]} />
						</HalfBlock>
						<Exist when={Boolean(isOpen)}>
							<PredictTutorialButton
								customClass="pick-item"
								text={t("game.tour.prop")}
								points={100}
							/>
						</Exist>
						<Exist when={!isOpen}>
							<PropItem customClass="first-step" property={props.items[2]} />
						</Exist>

						<HalfBlock>
							<PropItem property={props.items[3]} />
							<PropItem property={props.items[4]} />
						</HalfBlock>
						<PropItem property={props.items[5]} />
					</Exist>

					<HeadingBlock>{t("game.raiders")}</HeadingBlock>
				</FieldContent>
			</Field>
			<PredictModal />
			<PredictModalComplete />
			<PredictSavedModal />
			<HalfTimeModal />
			<ResultsModal />
			<WelcomeModal />
		</Wrapper>
	);
};

interface IPropItemProps {
	property: IContestProp | undefined;
	customClass?: string;
}

const PropItem: React.FC<IPropItemProps> = ({property}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const answer = useSelector(getContestAnswerById)(property?.id);
	const isPicked = Boolean(answer);
	const activeContest = useSelector(getActiveContest);
	const activeMatch = useSelector(getSelectedMatch);
	const {isActive, isComplete} = useSelector(getIsActiveContestLocked);

	const checkCompleteModal = useCallback(() => {
		if (!property) {
			return false;
		}
		if (isActive || isComplete) {
			dispatch(openPredictionCompleteModal(property.id));
			return true;
		}

		return false;
	}, [dispatch, property, isActive, isComplete]);

	const checkErrorModal = useCallback(() => {
		const isSelectedSecondContest = activeContest?.half === ContestHalf.Second;
		const firstActive = activeMatch?.contests?.firstHalf?.status === ContestStatus.Live;

		if (isSelectedSecondContest && firstActive) {
			dispatch(
				openErrorModal({
					title: t("game.error.modal_title"),
					subTitle: t("game.error.modal_subtitle"),
					text: t("game.error.modal_text"),
				})
			);
			return true;
		}

		return false;
	}, [dispatch, t, activeMatch, activeContest]);

	const checkNoAnswer = useCallback(() => {
		return !isPicked && (isComplete || isActive);
	}, [isPicked, isActive, isComplete]);

	const handleClick = useCallback(() => {
		const showNothing = checkNoAnswer();
		if (!property?.id || showNothing) {
			return;
		}

		const showCompleteModal = checkCompleteModal();
		if (showCompleteModal) {
			return;
		}

		const showErrorModal = checkErrorModal();
		if (showErrorModal) {
			return;
		}

		dispatch(openPredictionModal(property.id));
	}, [dispatch, property, checkErrorModal, checkCompleteModal, checkNoAnswer]);

	if (!property) return null;

	if (property.isSponsored) {
		return <SponsorProp prop={property} onClick={handleClick} />;
	}

	return (
		<PredictButton
			id={property.id}
			text={property.name}
			points={property.multiplier}
			onClick={handleClick}
		/>
	);
};
