import React, {useCallback} from "react";
import {IFetchLeagueRankingsPayload} from "modules/types";
import styled from "@emotion/styled";
import {Input} from "components/Common";
import {useSelector} from "react-redux";
import {getLeagueById, getRoundsFrom} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {RoundMenuItem} from "components/RoundMenuItem";
import {MenuItem} from "@mui/material";
import {LeaderboardOrder} from "modules/enums";

const Wrapper = styled("div")`
	padding: 12px 20px 0 20px;
`;

interface IProps {
	filters: IFetchLeagueRankingsPayload;
	onFiltersChange: (filters: IFetchLeagueRankingsPayload) => void;
}

export const StandingsFilters: React.FC<IProps> = ({filters, onFiltersChange}) => {
	const params = useParams();
	const leagueId = Number(params.id);
	const league = useSelector(getLeagueById)(leagueId);
	const rounds = useSelector(getRoundsFrom)(league?.start_round);
	const {t} = useTranslation();

	const onRoundChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number(event.target.value);
			if (value === null || value === undefined) {
				return;
			}

			onFiltersChange({
				...filters,
				round: value,
				offset: 0,
				order: value === 0 ? LeaderboardOrder.Season : LeaderboardOrder.Weekly,
			});
		},
		[filters, onFiltersChange]
	);

	return (
		<Wrapper>
			<Input
				select
				name="start_round"
				label={t("leaderboard.page.game_week")}
				variant="outlined"
				value={filters.round}
				defaultChecked={true}
				onChange={onRoundChange}
				required={true}>
				<MenuItem value={0}>
					<span>{t("leagues.form.overall")}</span>
				</MenuItem>
				{rounds.map(RoundMenuItem)}
			</Input>
		</Wrapper>
	);
};
