import {createReducer} from "redux-act";
import {IChecksums, IJSONReducer, IRound, ISquad} from "modules/types/JSON";
import {
	fetchChecksumsSuccess,
	fetchRoundsSuccess,
	fetchSquadsSuccess,
	subscribeLiveScoring,
	unsubscribeLiveScoring,
} from "modules/actions";
import {IReducerAction} from "modules/types";

type IJSONReducerPayload<TP = null> = IReducerAction<IJSONReducer, TP>;

const initialState: IJSONReducer = {
	squads: [],
	rounds: [],
	checksums: {},
	isLiveSubscribed: false,
};

const onFetchSquadsSuccess: IJSONReducerPayload<ISquad[]> = (state, payload) => ({
	...state,
	squads: payload,
});

const onSubscribeLiveScoring: IJSONReducerPayload = (state) => ({
	...state,
	isLiveSubscribed: true,
});

const onUnsubscribeLiveScoring: IJSONReducerPayload = (state) => ({
	...state,
	isLiveSubscribed: false,
});

const onFetchRoundsSuccess: IJSONReducerPayload<IRound[]> = (state, payload) => ({
	...state,
	rounds: payload,
});

const onFetchChecksumsSuccess: IJSONReducerPayload<IChecksums> = (state, payload) => ({
	...state,
	checksums: payload,
});

export const JSONReducer = createReducer<IJSONReducer>({}, initialState)
	.on(fetchSquadsSuccess, onFetchSquadsSuccess)
	.on(subscribeLiveScoring, onSubscribeLiveScoring)
	.on(unsubscribeLiveScoring, onUnsubscribeLiveScoring)
	.on(fetchRoundsSuccess, onFetchRoundsSuccess)
	.on(fetchChecksumsSuccess, onFetchChecksumsSuccess);
