import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import styled from "@emotion/styled";

export const RadioElement = styled(Radio)({
	"&.MuiRadio-root": {
		padding: "0 10px 0 0",
		color: "#FFFFFF",
	},
	"&.Mui-checked": {
		color: "#FFFFFF",
	},
	"&.MuiFormControlLabel-root": {
		color: "#FFFFFF",
	},
});

export const RadioWrapper = styled(RadioGroup)({
	".MuiFormControlLabel-root": {
		margin: "0 0 12px 0",
	},
});

export const RadioLabel = styled(FormControlLabel)({
	color: "#FFFFFF",
	fontSize: "16px",
	fontWeight: 400,
	letterSpacing: "0.15px",
});
