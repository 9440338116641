import React, {useCallback, useEffect} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton, SheetCloseButton} from "components/Common";
import AssetsTopImage from "assets/img/icons/ReturningUserChipsImage.svg";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {getIsCameBack, getIsReturningUserModalOpen, getSelectedMatch} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {toggleReturningUserModal} from "modules/actions";
import {LocalStorageService} from "modules/utils/LocalStorageService";
import {PLAYED_MATCHES} from "modules/constants";
import {MatchStatus} from "modules/enums";
import {IconClose} from "components/Icons";

const SBottomSheet = styled(BottomSheet)`
	padding-top: 60px;
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	text-align: center;
	gap: 20px;
	justify-content: flex-end;

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
	}

	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
	}
`;

export const ReturningUserModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getIsReturningUserModalOpen);
	const activeMatch = useSelector(getSelectedMatch);
	const isBack = useSelector(getIsCameBack);

	const closeModal = useCallback(() => {
		dispatch(toggleReturningUserModal(false));
	}, [dispatch]);

	useEffect(() => {
		const playedMatches = JSON.parse(
			LocalStorageService.GET(PLAYED_MATCHES) ?? "[]"
		) as number[];

		if (!activeMatch || activeMatch?.status === MatchStatus.COMPLETE) {
			return;
		}

		if (playedMatches.includes(activeMatch?.id) || !isBack) {
			return;
		}

		playedMatches.push(activeMatch.id);
		dispatch(toggleReturningUserModal(true));
		LocalStorageService.SET(PLAYED_MATCHES, JSON.stringify(playedMatches));
	}, [activeMatch, dispatch, isBack]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<SheetCloseButton onClick={closeModal}>
					<IconClose color="#000000" />
				</SheetCloseButton>
				<h2>{t("game.returning_user.title")}</h2>
				<p>{t("game.returning_user.text_1")}</p>
				<img src={AssetsTopImage} alt="Info chips" />
				<p>{t("game.returning_user.text_2")}</p>

				<PrimaryButton className="active" onClick={closeModal}>
					<span>{t("game.returning_user.play")}</span>
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
