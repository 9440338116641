import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {getPredictionSliderData, getPredictModalData} from "modules/selectors";
import {DEFAULT_CHIPS_STEP} from "modules/constants";
import styled from "@emotion/styled";

import InputRange, {Range} from "react-input-range-ios-fix";
import "react-input-range/lib/css/index.css";

const Wrapper = styled.div`
	padding: 4px 0;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	* {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}

	.input-range__label {
		display: none;
	}

	.input-range__track--background {
		height: 30px;
		margin-top: -15px;
		background: transparent;
		position: relative;

		> div {
			transform: translateY(12px);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 4px;
			background: rgba(235, 235, 235, 0.38);
			border-radius: 2px;
		}
	}

	.input-range__track--active {
		transform: translateY(15px);
		background-color: ${({theme}) => theme.actionColors.picks};
		transition: unset;
		z-index: 2;
	}

	.input-range__slider-container {
		transition: unset;
		z-index: 2;
		width: 200%;
		height: 20px;
		transform: translateX(-50%);

		> div {
			width: 100%;
			background: none;
			border: none;
			z-index: 2;

			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: 0;

				width: 20px;
				height: 20px;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
				background-color: ${({theme}) => theme.actionColors.picks};
				border: none;
			}
		}
	}
`;

interface IProps {
	value: number;
	onChange: (value: number) => void;
}

export const RangeSlider: React.FC<IProps> = ({value, onChange}) => {
	const {predictionId} = useSelector(getPredictModalData);
	const {min, max, disabled} = useSelector(getPredictionSliderData)(predictionId);

	const handleChange = useCallback(
		(changedValue: number | Range) => {
			if (changedValue === undefined || changedValue === null || disabled) {
				return;
			}
			onChange(changedValue as number);
		},
		[disabled, onChange]
	);

	return (
		<Wrapper>
			<InputRange
				step={DEFAULT_CHIPS_STEP}
				minValue={min}
				maxValue={max}
				value={value}
				onChange={handleChange}
			/>
		</Wrapper>
	);
};
