import React from "react";
import {IGeneralIconProps} from "modules/types";

export const IconSearch: React.FC<IGeneralIconProps> = ({
	width = 15,
	height = 16,
	color = "#FFFFFF",
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 16"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg">
			<title>Search</title>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="UI-/-Icon-/-Search" transform="translate(-3.000000, -2.000000)">
					<g transform="translate(3.000000, 2.000000)">
						<path
							d="M10.5,10.1129032 C11.4919355,9.02419355 12.0967742,7.62096774 12.0967742,6.0483871 C12.0967742,2.70967742 9.41129032,0 6.09677419,0 C2.78225806,0 0.0967741935,2.70967742 0.0967741935,6 C0.0967741935,9.29032258 2.80645161,12 6.09677419,12 C7.35483871,12 8.54032258,11.5887097 9.45967742,10.9596774 L13.766129,15.266129 C13.9112903,15.4112903 14.0564516,15.4596774 14.2258065,15.4596774 C14.3951613,15.4596774 14.5645161,15.3870968 14.6854839,15.266129 C14.9516129,15 14.9516129,14.5887097 14.6854839,14.3225806 L10.5,10.1129032 Z M6.12096774,10.6693548 C3.55645161,10.6693548 1.4516129,8.56451613 1.4516129,6 C1.4516129,3.43548387 3.55645161,1.33064516 6.12096774,1.33064516 C8.68548387,1.33064516 10.7903226,3.43548387 10.7903226,6 C10.7903226,8.56451613 8.70967742,10.6693548 6.12096774,10.6693548 Z"
							id="Shape"
							fill={color}
							fillRule="nonzero"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};
