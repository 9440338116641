import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons/PureButton";

export const SecondaryButton = styled(PureButton)`
	width: 100%;
	padding: 7px 20px;
	background: ${({theme}) => theme.button.secondary.default.background};
	color: ${({theme}) => theme.button.secondary.default.color};
	border: 1px solid ${({theme}) => theme.button.secondary.default.color};

	&:active,
	&:focus {
		border-color: ${({theme}) => theme.button.secondary.hover.background};
		background: ${({theme}) => theme.button.secondary.hover.background};
		color: ${({theme}) => theme.button.secondary.hover.color};
		cursor: pointer;
	}
`;
