import {Modal, ModalBackdrop, ModalBody, PrimaryButton, PureButton} from "components/Common";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsTutorialModalOpen} from "modules/selectors/Modal";
import {useTranslation} from "react-i18next";
import {IconClose} from "components/Icons";
import styled from "@emotion/styled";
import {toggleTutorialModal} from "modules/actions";
import {TUTORIAL_KEY} from "modules/constants";
import {LocalStorageService} from "modules/utils/LocalStorageService";
import {useTour} from "@reactour/tour";

const ActionBlock = styled("div")`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 12px;
	width: 100%;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
	}
`;

const ModalButton = styled(PrimaryButton)`
	margin-top: 22px;
	width: unset;
`;

export const ModalTutorial: React.FC = () => {
	const dispatch = useDispatch();
	const isModalOpen = useSelector(getIsTutorialModalOpen);
	const {t} = useTranslation();
	const {setIsOpen} = useTour();

	const declineTutorial = useCallback(() => {
		LocalStorageService.SET(TUTORIAL_KEY, true);
		dispatch(toggleTutorialModal(false));
	}, [dispatch]);

	const acceptTutorial = useCallback(() => {
		LocalStorageService.SET(TUTORIAL_KEY, true);
		dispatch(toggleTutorialModal(false));
		setIsOpen(true);
	}, [dispatch, setIsOpen]);

	if (!isModalOpen) {
		return null;
	}

	return (
		<ModalBackdrop>
			<Modal>
				<ModalBody>
					<ActionBlock>
						<PureButton onClick={declineTutorial}>
							<IconClose color="#000000" />
						</PureButton>
					</ActionBlock>
					<h2 className="title">{t("game.tour.start_title")}</h2>
					<p className="body-text">{t("game.tour.start_text_1")}</p>
					<p className="body-text">{t("game.tour.start_text_2")}</p>

					<ModalButton className="active" onClick={acceptTutorial}>
						{t("game.tour.start_button")}
					</ModalButton>
				</ModalBody>
			</Modal>
		</ModalBackdrop>
	);
};
