import styled from "@emotion/styled";

export const TertiaryButton = styled("button")`
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	border: none;
	background: transparent;
	min-width: unset;

	text-transform: uppercase;
	padding: 16px 0;

	transition: 0.25s;

	&:disabled {
		opacity: 0.5;
	}
`;
