import React, { ReactNode } from 'react';

interface IProps {
	when: boolean;
	children: ReactNode
}

export const Exist: React.FC<IProps> = ({when, children}) => (
	<React.Fragment>{when ? children : null}</React.Fragment>
);
