import {
	ICreateLeaguePayload,
	IJoinLeaguePayload,
	ILeagueEmailPayload,
	ILeagueRemoveUserModalAction,
	ILeaguesFetchPayload,
	ISagaAction,
	IUpdateLeaguePayload,
} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, delay, put} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {
	createLeagueSuccess,
	fetchLeagueUsersRequest,
	fetchLeagueUsersSuccess,
	fetchSingleLeagueSuccess,
	fetchUserLeaguesConcat,
	fetchUserLeaguesSuccess,
	leaveLeagueSuccess,
	removeLeagueUserSuccess,
	showForJoinConcat,
	showForJoinSuccess,
	toggleLeaguesRequestState,
	updateLeagueSuccess,
} from "modules/actions/Leagues";
import {RequestStateType} from "modules/enums";
import {showApiErrorModal, toggleInvitesModal} from "modules/actions";

export const fetchUserLeaguesSaga = function* ({
	payload,
}: ISagaAction<ILeaguesFetchPayload>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.userLeagues, payload);
		ApiError.CHECK(response);

		if (payload.offset > 0) {
			yield* put(fetchUserLeaguesConcat(response.success));
			return;
		}

		yield* put(fetchUserLeaguesSuccess(response.success));
	} catch (e) {
		const error = e as Error;
		yield* put(showApiErrorModal(error.message));
		yield* put(
			toggleLeaguesRequestState({
				key: "userLeagues",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const createLeagueSaga = function* ({
	payload,
}: ISagaAction<ICreateLeaguePayload>): SagaIterator {
	try {
		const data = {...payload, description: ""};
		const response = yield* call(Api.Leagues.create, data);
		yield* put(createLeagueSuccess(response.success.league));
	} catch (e) {
		const error = e as Error;
		yield* put(showApiErrorModal(error.message));
		yield* put(
			toggleLeaguesRequestState({
				key: "createLeague",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const sendLeagueEmailInviteSaga = function* ({
	payload,
}: ISagaAction<ILeagueEmailPayload>): SagaIterator {
	try {
		yield* call(Api.Leagues.emailInvite, {
			leagueId: payload.leagueId,
			invites: payload.invites,
		});
		yield* put(toggleInvitesModal(true));
	} catch (e) {
		const error = e as Error;
		yield* put(showApiErrorModal(error.message));
	}
};

export const showForJoinSaga = function* ({
	payload,
}: ISagaAction<IJoinLeaguePayload>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.showForJoin, payload);

		if (payload.page > 1) {
			yield* put(showForJoinConcat(response.success));
			return;
		}
		yield* put(showForJoinSuccess(response.success));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	}
};

export const joinLeagueSaga = function* ({payload}: ISagaAction<string>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.joinLeague, payload);
		ApiError.CHECK(response);

		yield* put(
			toggleLeaguesRequestState({
				key: "joinLeague",
				state: RequestStateType.Success,
			})
		);

		yield* delay(2000);
		yield* put(
			toggleLeaguesRequestState({
				key: "joinLeague",
				state: RequestStateType.Idle,
			})
		);
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
		yield* put(
			toggleLeaguesRequestState({
				key: "joinLeague",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchSingleLeagueSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.leagueById, payload);
		ApiError.CHECK(response);

		yield* put(fetchSingleLeagueSuccess(response.success));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));

		yield* put(
			toggleLeaguesRequestState({
				key: "singleLeague",
				state: RequestStateType.Error,
			})
		);
	} finally {
		yield* delay(2000);
		yield* put(
			toggleLeaguesRequestState({
				key: "singleLeague",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchLeagueUsersSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.leagueUsers, payload);
		yield* put(fetchLeagueUsersSuccess(response.success.users));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));

		yield* put(
			toggleLeaguesRequestState({
				key: "fetchLeagueUsers",
				state: RequestStateType.Error,
			})
		);
	}
};

export const removeLeagueUserSaga = function* ({
	payload,
}: ISagaAction<ILeagueRemoveUserModalAction>): SagaIterator {
	try {
		const leagueId = Number(payload.leagueId);

		if (!leagueId) {
			throw new ApiError("Invalid league id");
		}

		yield* call(Api.Leagues.leagueUserRemove, {
			userId: payload.user!.userId,
			leagueId: leagueId,
		});
		yield* put(removeLeagueUserSuccess());
		yield* put(fetchLeagueUsersRequest(leagueId));
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(1000);
		yield* put(
			toggleLeaguesRequestState({
				key: "removeLeagueUser",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const leaveLeagueSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		yield* call(Api.Leagues.leaveLeague, payload);
		yield* put(leaveLeagueSuccess());
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(1000);
		yield* put(
			toggleLeaguesRequestState({
				key: "leaveLeague",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const updateLeagueSaga = function* ({
	payload,
}: ISagaAction<IUpdateLeaguePayload>): SagaIterator {
	try {
		const response = yield* call(Api.Leagues.updateLeague, payload);
		ApiError.CHECK(response);
		yield* put(updateLeagueSuccess());
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(2000);
		yield* put(
			toggleLeaguesRequestState({
				key: "updateLeague",
				state: RequestStateType.Idle,
			})
		);
	}
};
