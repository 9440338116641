import {IRound, IStore, NullOrEmpty} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {first, last, memoize} from "lodash";
import {RoundStatus} from "modules/enums";
import {RAIDERS_ID} from "modules/constants";

const getState = ({JSONReducer}: IStore) => JSONReducer;
const getStateMatches = ({matchReducer}: IStore) => matchReducer;
const getMatches = createSelector(getStateMatches, (state) => state.matches);

export const getSquads = createSelector(getState, (state) => state.squads);
export const getSquadsById = createSelector(getSquads, (squads) =>
	memoize((id: number) => squads.find((squad) => squad.id === id))
);

export const getChecksums = createSelector(getState, (state) => state.checksums);
export const getIsLiveScoringSubscribed = createSelector(
	getState,
	(state) => state.isLiveSubscribed
);

export const getRounds = createSelector(getState, (state) => state.rounds);
export const getRoundById = createSelector(getRounds, (rounds) =>
	memoize((roundId: NullOrEmpty<number> | "") => {
		return rounds.find((item) => item.id === roundId);
	})
);

export const getIsHomeMatchById = createSelector(getMatches, (matches) =>
	memoize((roundId: NullOrEmpty<number> | "") => {
		const match = matches.find((item) => item.roundId === roundId);

		if (!match) {
			// True to not hide In Stadium Leaderboard
			return true;
		}
		return match.homeSquad === Number(RAIDERS_ID);
	})
);
export const getScheduleRounds = createSelector(getRounds, (rounds) =>
	rounds.filter((round) => round.status === RoundStatus.Scheduled)
);
export const getRoundsFrom = createSelector(getRounds, (rounds) =>
	memoize((roundId: NullOrEmpty<number>) => {
		if (!roundId) {
			return rounds;
		}
		return rounds.filter((round) => round.id >= roundId);
	})
);

const getLastActiveFromArray = (rounds: IRound[]) => {
	const lastActive = rounds.find((round) => round.status === RoundStatus.Live);
	if (lastActive) {
		return lastActive;
	}

	const completed = rounds.filter((round) => round.status === RoundStatus.Complete);
	if (completed.length > 0) {
		return last(completed);
	}

	return first(rounds);
};

export const getLastActiveRound = createSelector(getRounds, (rounds) =>
	getLastActiveFromArray(rounds)
);

export const getLastActiveRoundFrom = createSelector(getRounds, (rounds) =>
	memoize((roundId: NullOrEmpty<number>) => {
		const filteredById = rounds.filter((round) => round.id >= (roundId ?? 0));
		return getLastActiveFromArray(filteredById);
	})
);
