import React, {FormEvent} from "react";
import {IJoinLeagueFilters, IJoinLeaguePayload} from "modules/types";
import styled from "@emotion/styled";
import {Input} from "components/Common";
import {IconButton, InputAdornment, MenuItem} from "@mui/material";
import {LeaguePrivacy} from "modules/enums";
import {useTranslation} from "react-i18next";
import {serializeForm} from "modules/utils/helpers";
import {IconSearch} from "components/Icons";

const Form = styled("form")`
	padding: 0 20px;
`;

interface IProps {
	filters: IJoinLeaguePayload;
	onFiltersChange: (filters: IJoinLeaguePayload) => void;
}

export const LeagueJoinFilters: React.FC<IProps> = ({filters, onFiltersChange}) => {
	const {t} = useTranslation();
	const {search, privacy} = filters;

	const onChange = (e: FormEvent<HTMLFormElement>) => {
		const data = serializeForm<IJoinLeagueFilters>(e.currentTarget);
		onFiltersChange({
			...filters,
			...data,
		});
	};

	const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onFiltersChange({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<Form onChange={onChange}>
			<Input
				name="search"
				value={search}
				label={t("leagues.join.search")}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton>
								<IconSearch />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<Input
				select
				name="privacy"
				label={t("leagues.privacy.privacy")}
				onChange={onSelectChange}
				value={privacy}>
				<MenuItem value={LeaguePrivacy.Public}>{t("leagues.privacy.public")}</MenuItem>
				<MenuItem value={LeaguePrivacy.Private}>{t("leagues.privacy.private")}</MenuItem>
			</Input>
		</Form>
	);
};
