import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons/PureButton";

export const PrimaryButton = styled(PureButton)`
	width: 100%;
	padding: 7px 20px;
	background: ${({theme}) => theme.button.primary.default.background};
	color: ${({theme}) => theme.button.primary.default.color};
	border: 1px solid ${({theme}) => theme.button.primary.default.background};

	&.active {
		border-color: ${({theme}) => theme.button.primary.hover.background} !important;
		background: ${({theme}) => theme.button.primary.hover.background} !important;
		color: ${({theme}) => theme.button.primary.hover.color} !important;
	}

	&:hover,
	&:active,
	&:focus {
		border-color: ${({theme}) => theme.button.primary.hover.background};
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
		cursor: pointer;
	}

	&.active {
		border-color: ${({theme}) => theme.button.primary.hover.background};
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
	}
`;
