import {createAction} from "redux-act";
import {IChecksums, IRound, ISquad} from "modules/types/JSON";

export const fetchSquadsRequest = createAction();
export const fetchSquadsSuccess = createAction<ISquad[]>();

export const fetchChecksumsRequest = createAction();
export const fetchChecksumsSuccess = createAction<IChecksums>();

export const fetchRoundsRequest = createAction();
export const fetchRoundsSuccess = createAction<IRound[]>();

export const subscribeLiveScoring = createAction();
export const unsubscribeLiveScoring = createAction();
export const subscribeAnswers = createAction();
