import React, {Component, useCallback, useState} from "react";
import {Exist, IconChevron} from "components";
import {PureButton} from "components/Common";
import styled from "@emotion/styled";
import {NavLink, useLocation} from "react-router-dom";

interface IAccordionProps {
	item: React.ComponentElement<unknown, Component>;
	children: React.ReactNode;
}

const AccordionButton = styled("span")`
	color: ${({theme}) => theme.text.color.white};
	width: 24px;

	svg {
		width: 18px;
		height: 18px;
	}
`;

const AccordionWrapper = styled.div`
	border-bottom: 1px solid #757575;
`;

const AccordionLink = styled(NavLink)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding: 12px 0;

	&.open {
		> div,
		a,
		button {
			border-bottom: none;
		}
	}

	.text {
		color: ${({theme}) => theme.text.color.white};
		font-family: ${({theme}) => theme.text.fontFamily};
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
	}

	.accordion-icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const AccordionItem = styled(PureButton)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	padding: 12px 0;

	&.open {
		> div,
		a,
		button {
			border-bottom: none;
		}
	}

	.text {
		color: ${({theme}) => theme.text.color.white};
		font-family: ${({theme}) => theme.text.fontFamily};
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
	}

	.accordion-icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const Content = styled("div")`
	padding-bottom: 24px;
`;

export const HOCAccordion: React.FC<IAccordionProps> = ({item, children = null}) => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const toggleMenu = useCallback(() => {
		setMenuOpen(!isMenuOpen);
	}, [isMenuOpen]);

	const chevronDirection = isMenuOpen ? "top" : "bottom";
	const isMenuOpenClass = isMenuOpen ? "open" : "";

	return (
		<AccordionWrapper className="accordion">
			<AccordionItem className={isMenuOpenClass} onClick={toggleMenu}>
				{item}
				<AccordionButton className="accordion-icon">
					<IconChevron color="currentColor" width={12} height={6} to={chevronDirection} />
				</AccordionButton>
			</AccordionItem>
			<Exist when={isMenuOpen}>
				<Content className="content">{children}</Content>
			</Exist>
		</AccordionWrapper>
	);
};

interface IAccordionButtonProps {
	text: string;
	children: React.ReactNode;
}

export const HOCAccordionButton: React.FC<IAccordionButtonProps> = ({text, children = null}) => {
	return <HOCAccordion item={<span className="text">{text}</span>}>{children}</HOCAccordion>;
};

interface IAccordionLinkProps extends IAccordionButtonProps {
	to: string;
}

export const HOCLinkButton: React.FC<IAccordionLinkProps> = ({text, to, children = null}) => {
	const location = useLocation();
	const isOpen = location.pathname === to;

	const chevronDirection = isOpen ? "top" : "bottom";
	const isMenuOpenClass = isOpen ? "open" : "";
	const nextLink = isOpen ? "/help" : to;

	return (
		<AccordionWrapper className="accordion">
			<AccordionLink className={isMenuOpenClass} to={nextLink}>
				<span className="text">{text}</span>
				<AccordionButton className="accordion-icon">
					<IconChevron color="currentColor" width={12} height={6} to={chevronDirection} />
				</AccordionButton>
			</AccordionLink>
			<Exist when={isOpen}>
				<Content className="content">{children}</Content>
			</Exist>
		</AccordionWrapper>
	);
};
