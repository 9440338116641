import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons/PureButton";

export const SheetCloseButton = styled(PureButton)`
	position: absolute;
	right: 24px !important;
	top: 24px !important;
	width: 24px !important;
	height: 24px !important;
`;
