import {createTheme} from "@mui/material";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	text: {
		color: {
			dark: "#151515",
			grey: "#757575",
			white: "#FFFFFF",
			links: "#B2B2B2",
		},
		fontSize: {
			heading: {
				sm: "18px",
				md: "20px",
				lg: "24px",
				xl: "32px",
			},
			regular: {
				small_11: "11px",
				small_13: "13px",
				text: "14px",
			},
		},
		fontFamily: "All-Pro Sans, sans-serif",
	},
	brandColor: {
		primary: "#000000",
		secondary: "#FFFFFF",
	},
	background: {
		black: "#000000",
		white: "#FFFFFF",
		grey: "#EBEBEB",
	},
	actionColors: {
		live: "#00D258",
		correct: "#0CDD63",
		incorrect: "#DD0C0C",
		picks: "#00AAF4",
		inPlay: "#C0C0C0",
	},
	tableColors: {
		gold: "#E3C983",
		silver: "#E0DEDE",
		bronze: "#DEB387",
	},
	input: {
		color: {
			label: "#757575",
			error: "#F45252",
			field: "#FFFFFF",
		},
	},
	button: {
		primary: {
			default: {
				background: "#9D9EA0",
				color: "#151515",
			},
			hover: {
				background: "#000000",
				color: "#FFFFFF",
			},
			disabled: {
				background: "#9D9EA0",
				color: "151515",
				opacity: 0.5,
			},
		},
		secondary: {
			default: {
				background: "transparent",
				color: "#FFFFFF",
			},
			hover: {
				background: "#333333",
				color: "#FFFFF",
			},
			disabled: {
				background: "transparent",
				color: "FFFFFF",
				opacity: 0.5,
			},
		},
		tertiary: {
			background: "transparent",
			color: "#000000",
			disabledOpacity: 0.5,
		},
	},
	typography: {
		fontFamily: ["All-Pro Sans, sans-serif"].join(","),
	},
};

type ITheme = typeof themeObject;
export const theme = createTheme(themeObject);

declare module "@mui/material/styles" {
	/**
	 *Disabled as it's allowing to avoid `any` type per MUI documentation
	 * https://mui.com/material-ui/customization/theming/#custom-variables
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}

declare module "@emotion/react" {
	/**
	 *Disabled as it's allowing to avoid `any` type per Emotion documentation
	 * https://emotion.sh/docs/typescript#define-a-theme
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}
