import {PrimaryButton, SubTitle} from "components/Common";
import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "modules/utils/hooks";
import {Exist} from "components/Exist";
import {LanguagesShort, ShareTarget, ShareType} from "modules/enums";
import {share} from "modules/utils/Sharing";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/Analytics";
import {ILeague} from "modules/types";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import {IS_SHARING_ENABLED, SHARE_TRANSLATIONS_MAP} from "modules/constants";

const Section = styled("section")`
	margin-bottom: 4px;
	margin-top: 20px;

	button {
		margin-bottom: 10px;
	}
`;

interface IProps {
	league: ILeague;
}
export const SocialInvite: React.FC<IProps> = ({league}) => {
	const {isMobileShare} = useIsMobile();
	const user = useSelector(getUser);
	const {t, i18n} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const translationText = t(SHARE_TRANSLATIONS_MAP.league, {X: league.code});

	const onShare = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			const shareTarget = event.currentTarget.dataset.target as ShareTarget;

			if (!shareTarget) {
				return;
			}

			void share({
				target: shareTarget,
				type: ShareType.League,
				league,
				ln: language,
				message: translationText,
			});
			createConnextraScriptTag(ConnextraType.SHARE, user);
		},
		[user, league, language, translationText]
	);

	if (!IS_SHARING_ENABLED) {
		return null;
	}

	return (
		<Section>
			<SubTitle>{t("leagues.create.social_title")}</SubTitle>
			<Exist when={isMobileShare}>
				<PrimaryButton onClick={onShare} data-target={ShareTarget.Native}>
					{t("leagues.form.share")}
				</PrimaryButton>
			</Exist>
			<Exist when={!isMobileShare}>
				<Exist when={false}>
					<PrimaryButton onClick={onShare} data-target={ShareTarget.Facebook}>
						{t("leagues.form.share_facebook")}
					</PrimaryButton>
				</Exist>

				<PrimaryButton onClick={onShare} data-target={ShareTarget.Twitter}>
					{t("leagues.form.share_twitter")}
				</PrimaryButton>
			</Exist>
		</Section>
	);
};
