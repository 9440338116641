import React from "react";
import {TourProvider} from "@reactour/tour";
import {useTranslation} from "react-i18next";
import {ModalTutorial} from "components";
import {TutorialBottomSheet} from "components/Tutorial/TutorialBottomSheet";
import {TutorialModal} from "components/Tutorial/TutorialModal";
import {doPopoverArrow} from "modules/utils/helpers";
import {StylesObj} from "@reactour/tour/dist/styles";
import {ITutorialState} from "modules/types";

interface IProps {
	children: React.ReactNode;
}

export const HOCTutorial: React.FC<IProps> = ({children}) => {
	const {t} = useTranslation();
	const buttonClass = ".pick-item";
	const fixSecondStep = window.innerHeight < 680;
	const steps = [
		{
			selector: buttonClass,
			content: () => (
				<TutorialModal
					title={t("game.tour.step_1_title")}
					text={t("game.tour.step_1_text")}
				/>
			),
			position: "top",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "bottom"),
			},
		},
		{
			selector: ".tutorial-sheet",
			content: () => (
				<TutorialModal
					title={t("game.tour.step_2_title")}
					text={t("game.tour.step_2_text")}
				/>
			),
			position: fixSecondStep ? [10, 10] : "top",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "bottom"),
			},
		},
		{
			selector: buttonClass,
			content: () => (
				<TutorialModal
					title={t("game.tour.step_3_title")}
					text={t("game.tour.step_3_text_1")}
					text2={t("game.tour.step_3_text_2")}
				/>
			),
			position: "top",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "bottom"),
			},
		},
		{
			selector: ".action-button",
			content: () => (
				<TutorialModal
					title={t("game.tour.step_4_title")}
					text={t("game.tour.step_4_text")}
				/>
			),
			position: "bottom",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "right-corner"),
			},
		},
		{
			selector: buttonClass,
			content: () => (
				<TutorialModal
					title={t("game.tour.step_5_title")}
					text={t("game.tour.step_5_text")}
				/>
			),
			position: "top",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "bottom"),
			},
		},
		{
			selector: ".half-toggle",
			content: () => (
				<TutorialModal
					title={t("game.tour.step_6_title")}
					text={t("game.tour.step_6_text")}
				/>
			),
			position: "bottom",
			styles: {
				popover: (base: StylesObj, state: ITutorialState) =>
					doPopoverArrow(base, state, "top"),
			},
		},
	];

	const onTourOpen = () => {
		document.body.style.overflowY = "hidden";
	};

	const onTourClose = () => {
		document.body.style.overflowY = "auto";
	};

	return (
		<React.Fragment>
			{/* steps required interface StepType, but if add it
            - linter breaks whole site by checking rectour npm package */}
			<TourProvider
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				steps={steps}
				showBadge={false}
				showNavigation={false}
				afterOpen={onTourOpen}
				beforeClose={onTourClose}
				scrollDuration="300">
				<React.Fragment>
					{children}
					<ModalTutorial />
					<TutorialBottomSheet />
				</React.Fragment>
			</TourProvider>
		</React.Fragment>
	);
};
