import {ILeaguesReducer, IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";
import {memoize} from "lodash";
import {parseStateType} from "modules/utils/helpers";

const getState = ({leaguesReducer}: IStore) => leaguesReducer;

export const getLeaguesRequestState = createSelector(getState, (state) => state.requestState);

export const getLeaguesRequestStateByKey = createSelector(getLeaguesRequestState, (states) =>
	memoize((key: keyof ILeaguesReducer["requestState"]) => parseStateType(states[key]))
);

export const getLeagues = createSelector(getState, (state) => state.userLeagues);
export const getLeagueById = createSelector(getLeagues, (userLeagues) =>
	memoize((id: number) => userLeagues.leagues.find((league) => league.id === id))
);
export const getNewLeague = createSelector(getState, (state) => state.newLeague);
export const getShowForJoin = createSelector(getState, (state) => state.forJoinLeagues);
export const getLeagueUsers = createSelector(getState, (state) => state.leagueUsers);
export const getSingleLeague = createSelector(getState, (state) => state.singleLeague);
