import {Link} from "react-router-dom";
import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons";

export const MenuList = styled("div")`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const MenuLink = styled(Link)`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	text-transform: capitalize;
	padding: 8px 20px;
	display: block;
	width: 100%;
`;

export const MenuButton = styled(PureButton)`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	text-transform: capitalize;
	padding: 8px 20px;
	display: block;
	width: 100%;
	text-align: left;
`;

export const MenuSubLink = styled(Link)`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-transform: capitalize;
	padding: 8px 20px;
	padding-left: 16px;
`;
