import React, {useCallback, useEffect, useState} from "react";
import {FSModal, FSModalBody, FSModalHeading, PrimaryButton, PureButton} from "components/Common";
import {IconClose} from "components/Icons";
import styled from "@emotion/styled";
import advertisementImage from "assets/img/COXadvertisment.jpeg";
import {fetchMatchResultRequest, toggleResultModal} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {
	getContestStateTypeByKey,
	getIsResultModalOpen,
	getIsStatsVisible,
	getSelectedMatch,
	getUserChipsObject,
} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {Exist} from "components/Exist";
import {ADContainer} from "components/ADContainer";
import {ResultModalService} from "modules/utils/ResultModalService";
import {LocalPreloader} from "components/Preloader";
import {BetTable} from "components/Modals/ResultsModal/BetTable";
import {LeaderboardResultTable} from "components/Modals/ResultsModal/LeaderboardResultTable";
import {LanguagesShort} from "modules/enums";

const CloseButton = styled(PureButton)`
	width: 24px;
	height: 24px;
	margin-left: auto;
`;

const Wrapper = styled("div")`
	margin-top: 12px;
	padding: 0 20px 20px 20px;
	max-width: 100%;
	box-sizing: border-box;
`;

const Card = styled("div")`
	position: relative;
	padding: 16px;
	width: 100%;
	background: #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	transform: rotate(-1deg);

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

const OuterCard = styled("div")`
	background: #ffffff;
	border-radius: 6px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
	transform: rotate(-3deg);
`;
const OuterCard2 = styled("div")`
	background: #ffffff;
	border-radius: 6px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
	transform: rotate(4deg);
`;

const Title = styled("h2")`
	font-family: ${({theme}) => theme.text.fontFamily};
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 800;
	font-size: 26px;
	line-height: 31px;
	letter-spacing: -1px;
	text-transform: uppercase;
`;

const ToggleButtons = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	width: 100%;

	button {
		width: 50%;
		height: 41px;
		padding-left: 8px;
		padding-right: 8px;
	}
`;

enum ModalTabs {
	Betting,
	Leaderboard,
}

export const ResultsModal: React.FC = () => {
	const [activeTab, setActiveTab] = useState<ModalTabs>(ModalTabs.Betting);
	const {t, i18n} = useTranslation();
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsResultModalOpen);
	const activeMatch = useSelector(getSelectedMatch);
	const {isLoading} = useSelector(getContestStateTypeByKey)("fetchResults");
	const isStatsVisible = useSelector(getIsStatsVisible);
	const chipsObject = useSelector(getUserChipsObject);
	const hasNoData = chipsObject.remainFirstHalf === null;
	const language = i18n.language as LanguagesShort;

	useEffect(() => {
		if (!activeMatch || hasNoData) {
			return;
		}

		const viewed = ResultModalService.CheckMatchId(activeMatch.id);
		if (viewed || !isStatsVisible) {
			return;
		}

		dispatch(toggleResultModal(true));
		ResultModalService.StoreMatchId(activeMatch.id);
	}, [dispatch, activeMatch, isStatsVisible, hasNoData]);

	const closeModal = useCallback(() => {
		dispatch(toggleResultModal(false));
	}, [dispatch]);

	useEffect(() => {
		if (!isOpen || !activeMatch) {
			return;
		}
		dispatch(fetchMatchResultRequest(activeMatch.id));
	}, [dispatch, isOpen, activeMatch]);

	const isActiveTab = useCallback(
		(tab: ModalTabs): boolean => {
			return tab === activeTab;
		},
		[activeTab]
	);

	const getActiveTabClass = useCallback(
		(tab: ModalTabs) => {
			return isActiveTab(tab) ? "active" : "";
		},
		[isActiveTab]
	);

	const tabClickHandler = useCallback((event: React.SyntheticEvent<HTMLButtonElement>) => {
		const tab = event.currentTarget.dataset.tab;

		if (!tab) {
			return;
		}

		setActiveTab(Number(tab));
	}, []);

	if (!isOpen) {
		return null;
	}

	return (
		<FSModal>
			<FSModalHeading>
				<CloseButton onClick={closeModal}>
					<IconClose color="#FFFFFF" />
				</CloseButton>
			</FSModalHeading>
			<FSModalBody>
				<Wrapper>
					<OuterCard>
						<OuterCard2>
							<Card>
								<Title>{t("game.raiders")}</Title>

								<Exist when={isLoading}>
									<LocalPreloader color="#000000" />
								</Exist>

								<Exist when={!isLoading}>
									<ToggleButtons>
										<PrimaryButton
											onClick={tabClickHandler}
											className={getActiveTabClass(ModalTabs.Betting)}
											data-tab={ModalTabs.Betting}>
											{t("game.results_modal.betting_tab")}
										</PrimaryButton>
										<PrimaryButton
											onClick={tabClickHandler}
											className={`${getActiveTabClass(
												ModalTabs.Leaderboard
											)} ${language}`}
											data-tab={ModalTabs.Leaderboard}>
											{t("leaderboard.page.title")}
										</PrimaryButton>
									</ToggleButtons>
									<Exist when={isActiveTab(ModalTabs.Betting)}>
										<BetTable />
										<ADContainer image={advertisementImage} alt="COX" />
									</Exist>

									<Exist when={isActiveTab(ModalTabs.Leaderboard)}>
										<LeaderboardResultTable />
										<ADContainer image={advertisementImage} alt="COX" />
									</Exist>
								</Exist>
							</Card>
						</OuterCard2>
					</OuterCard>
				</Wrapper>
			</FSModalBody>
		</FSModal>
	);
};
