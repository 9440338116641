import React from "react";
import styled from "@emotion/styled";

import imageChipBlue from "assets/img/animation/ChipBlue.svg";
import imageChipYellow from "assets/img/animation/ChipYellow.svg";
import imageChipPurple from "assets/img/animation/ChipPurple.svg";
import imageChipMint from "assets/img/animation/ChipMint.svg";
import imageChipPink from "assets/img/animation/ChipPink.svg";
import {
	blueChipAnimation,
	mintChipAnimation,
	pinkChipAnimation,
	purpleChipAnimation,
	yellowChipAnimation,
} from "assets/animation";
import {useSelector} from "react-redux";
import {getIsReceiveAnimationPlaying} from "modules/selectors";

const Wrapper = styled("div")`
	img {
		position: absolute;
		z-index: 20;
		opacity: 0;

		&.playing {
			&.blue {
				${blueChipAnimation};
			}

			&.purple {
				${purpleChipAnimation};
			}

			&.yellow {
				${yellowChipAnimation};
			}

			&.mint {
				${mintChipAnimation};
			}

			&.pink {
				${pinkChipAnimation};
			}
		}
	}
`;

export const ChipsAnimation: React.FC = () => {
	const isPlaying = useSelector(getIsReceiveAnimationPlaying);

	const className = isPlaying ? "playing" : "";
	return (
		<Wrapper>
			<img src={imageChipBlue} alt="Chips Blue" className={`${className} blue`} />
			<img src={imageChipYellow} alt="Chips Yellow" className={`${className} yellow`} />
			<img src={imageChipPurple} alt="Chips Purple" className={`${className} purple`} />
			<img src={imageChipMint} alt="Chips Mint" className={`${className} mint`} />
			<img src={imageChipPink} alt="Chips Pink" className={`${className} pink`} />
		</Wrapper>
	);
};
