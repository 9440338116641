import React, {useEffect, useRef} from "react";
import {Exist} from "components/Exist";
import {IconChip} from "components/Icons";
import {
	getBetClass,
	getButtonClass,
	getChipColor,
	getDashedValue,
	getSuggestion,
	resizeToFit,
} from "modules/utils/helpers";
import {Chip, PointsLabel, PredictButtonBlock} from "components/Common";
import {useSelector} from "react-redux";
import {getContestAnswerById, getIsActiveContestLocked} from "modules/selectors";
import {LanguagesShort} from "modules/enums";
import {IPropName} from "modules/types";
import {useTranslation} from "react-i18next";

interface IProps {
	onClick: () => void;
	readonly id: number;
	readonly text: IPropName;
	readonly points: number;
	readonly customClass?: string;
}

export const PredictButton: React.FC<IProps> = ({id, text, points, customClass = "", onClick}) => {
	const {i18n} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const answer = useSelector(getContestAnswerById)(id);
	const {isActive, isComplete} = useSelector(getIsActiveContestLocked);
	const completeClass = isComplete || isActive ? "complete" : "";

	const containerRef = useRef<HTMLButtonElement>(null);
	const textRef = useRef<HTMLSpanElement>(null);

	const isPicked = Boolean(answer);
	const suggestion = getSuggestion(answer?.outcome);
	const pickedClass = getButtonClass(suggestion, isPicked);

	useEffect(() => {
		const container = containerRef.current;
		const text = textRef.current;

		if (container && text) {
			resizeToFit<HTMLButtonElement, HTMLSpanElement>(container, text, 4, 55);
		}
		// Dependency for rerender ref
	}, [text]);

	return (
		<PredictButtonBlock
			ref={containerRef}
			disableRipple
			className={`${pickedClass} ${customClass} ${completeClass}`}
			onClick={onClick}>
			<Exist when={Boolean(answer?.bet)}>
				<Chip>
					<IconChip color={getChipColor(answer?.bet)} width={40} height={40} />
					<span className={getBetClass(answer?.bet)}>{getDashedValue(answer?.bet)}</span>
				</Chip>
			</Exist>

			<PointsLabel>{points}Х</PointsLabel>
			<span ref={textRef}>{text[language ?? LanguagesShort.English]}</span>
		</PredictButtonBlock>
	);
};
