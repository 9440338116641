import {createReducer} from "redux-act";
import {toggleMenu, toggleReceiveChipsAnimation} from "modules/actions";
import {IReducerAction} from "modules/types";

interface IUIReducer {
	isMenuOpen: boolean;
	isChipsAnimationPlaying: boolean;
}

type IUIReducerPayload<TP> = IReducerAction<IUIReducer, TP>;

const initialState: IUIReducer = {
	isMenuOpen: false,
	isChipsAnimationPlaying: false,
};

const onToggleMenu: IUIReducerPayload<boolean> = (state, payload) => ({
	...state,
	isMenuOpen: payload,
});

const onToggleReceiveChipsAnimation: IUIReducerPayload<boolean> = (state, payload) => ({
	...state,
	isChipsAnimationPlaying: payload,
});

export const UIReducer = createReducer<IUIReducer>({}, initialState)
	.on(toggleMenu, onToggleMenu)
	.on(toggleReceiveChipsAnimation, onToggleReceiveChipsAnimation);
