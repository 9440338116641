import React from "react";
import {SecondaryButton, SubTitle, TextBlock} from "components/Common";
import styled from "@emotion/styled";
import useClipboard from "react-use-clipboard";
import {useTranslation} from "react-i18next";

const CopyButton = styled(SecondaryButton)`
	margin-bottom: 20px;
`;

interface IProps {
	code: string;
}

export const CopyCode: React.FC<IProps> = ({code}) => {
	const {t} = useTranslation();
	const [isCopied, setCopied] = useClipboard(String(code || ""), {
		successDuration: 3000,
	});

	return (
		<section>
			<SubTitle>{t("leagues.create.copy_title")}</SubTitle>
			<TextBlock>
				{t("leagues.create.copy_text")} <b>{code}</b>
			</TextBlock>
			<CopyButton onClick={setCopied}>
				{isCopied ? t("leagues.form.copied") : t("leagues.form.copy")}
			</CopyButton>
		</section>
	);
};
