import {IAnswer} from "modules/types";
import {LocalStorageService} from "modules/utils/LocalStorageService";

const CONTEST_STORAGE_KEY = "lvr_contest_";

export class ContestService {
	public static checkIsPlayChipsAnimation(answers: IAnswer[], compareWithId: number): boolean {
		if (!answers || answers.length === 0) {
			return false;
		}
		return this.compareAnswersWithStorageVersion(answers, compareWithId);
	}

	public static removeContestFromStorage(contestId: number): void {
		LocalStorageService.REMOVE(this.getKey(contestId));
	}

	private static compareAnswersWithStorageVersion(
		answers: IAnswer[],
		compareWithId: number
	): boolean {
		const storageAnswers = JSON.parse(
			LocalStorageService.GET(this.getKey(compareWithId)) ?? "[]"
		) as IAnswer[];
		if (storageAnswers.length === 0) {
			this.saveAnswersToStorage(answers, compareWithId);
			return false;
		}

		for (const answer of answers) {
			const localAnswer = storageAnswers.find(
				(localProp) => Number(localProp.id) === Number(answer.id)
			);
			if (!localAnswer) {
				continue;
			}

			if (localAnswer.outcome !== answer.outcome && answer.outcome !== 0) {
				this.saveAnswersToStorage(answers, compareWithId);
				return true;
			}
		}
		this.saveAnswersToStorage(answers, compareWithId);
		return false;
	}

	private static getKey(id: number): string {
		return `${CONTEST_STORAGE_KEY}${id}`;
	}

	private static saveAnswersToStorage(answers: IAnswer[], contestId: number): void {
		LocalStorageService.SET(this.getKey(contestId), answers);
	}
}
