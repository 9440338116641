import React, {useCallback, useEffect} from "react";
import {BottomSheet, ModalBackdrop, PrimaryButton, SheetCloseButton} from "components/Common";
import AssetsTopImage from "assets/img/icons/InfoModalChipsImage.svg";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {useDispatch, useSelector} from "react-redux";
import {getActiveContest, getIsPredictionSavedModalOpen} from "modules/selectors";
import {fetchAnswersByContestIdRequest, togglePredictSavedModal} from "modules/actions";
import {IconClose} from "components/Icons";

const SBottomSheet = styled(BottomSheet)`
	gap: 12px;
	padding-top: 60px;
	min-height: 360px;

	img {
		max-width: 190px;
		margin-bottom: 14px;
	}

	h2 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}

	h4 {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
	}

	p {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
	}

	button {
		width: 100%;
		margin-top: auto;
	}
`;

export const PredictSavedModal: React.FC = () => {
	const contest = useSelector(getActiveContest);
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getIsPredictionSavedModalOpen);

	useEffect(() => {
		if (!isOpen) {
			return;
		}

		if (contest) {
			dispatch(fetchAnswersByContestIdRequest(contest.id));
		}
	}, [contest, isOpen, dispatch]);

	const closeModal = useCallback(() => {
		dispatch(togglePredictSavedModal(false));
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<ModalBackdrop />
			<SBottomSheet className="open">
				<SheetCloseButton onClick={closeModal}>
					<IconClose color="#000000" />
				</SheetCloseButton>
				<img src={AssetsTopImage} alt="Info chips" />
				<h2>{t("game.prediction.saved_title")}</h2>
				<h4>{t("game.prediction.saved_subtitle")}</h4>
				<p>{t("game.prediction.saved_text")}</p>

				<PrimaryButton className="info-button-submit active" onClick={closeModal}>
					{t("common.close")}
				</PrimaryButton>
			</SBottomSheet>
		</React.Fragment>
	);
};
