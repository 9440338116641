import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {
	fetchMatchesRequest,
	fetchRoundsRequest,
	fetchSquadsRequest,
	getUserRequest,
} from "modules/actions";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {LanguagesShort} from "modules/enums";
import {SharedLanguageService} from "modules/utils/helpers/SharedLanguageService";

const MainContainer = styled("div")`
	max-width: 820px;
	width: 100%;
	margin: 0 auto;
`;

interface IProps {
	children: React.ReactNode;
}

export const HOCMain: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const language = useTranslation().i18n.language as LanguagesShort;

	useEffect(() => {
		dispatch(getUserRequest());
		dispatch(fetchSquadsRequest());
		dispatch(fetchRoundsRequest());
		dispatch(fetchMatchesRequest());
	}, [dispatch]);

	useEffect(() => {
		SharedLanguageService.setLanguage(language ?? LanguagesShort.English);
	}, [language]);

	return <MainContainer>{children}</MainContainer>;
};
