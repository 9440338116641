import React from "react";
import styled from "@emotion/styled";
import {useSelector} from "react-redux";
import {getSquadsById} from "modules/selectors/JSON";
import {getDashedValue} from "modules/utils/helpers";
import {IMAGES_URL} from "modules/constants";

const Player = styled.div`
	min-width: 96px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	img {
		width: 100%;
		height: auto;
		max-width: 36px;
		max-height: 36px;
	}

	p {
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 700;
		font-size: 13px;
		line-height: 16px;
		margin-top: 4px;
		text-transform: uppercase;
		text-align: center;
	}
`;

const SquadWrapper = styled.div`
	min-height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface IProps {
	squadId: number;
}

export const SquadBlock: React.FC<IProps> = ({squadId}) => {
	const squad = useSelector(getSquadsById)(squadId);

	return (
		<Player>
			<SquadWrapper>
				<img src={getSquadImageUrl(squad?.logo)} alt="logo" />
			</SquadWrapper>
			<p>{getDashedValue(squad?.shortName)}</p>
		</Player>
	);
};

export function getSquadImageUrl(path: string | undefined) {
	return `${IMAGES_URL}${String(path)}`;
}
