import React, {useCallback} from "react";
import {Input, SecondaryButton, SubTitle} from "components/Common";
import styled from "@emotion/styled";
import {serializeForm} from "modules/utils/helpers";
import {EMAIL_PATTERN} from "modules/constants";
import {useDispatch} from "react-redux";
import {sendLeagueEmailInviteRequest, showApiErrorModal} from "modules/actions";
import {InvitesSentModal} from "components";
import {useTranslation} from "react-i18next";
import {ILeague} from "modules/types";

const Section = styled("section")``;

interface IFieldProps {
	invites: string;
}

interface IProps {
	league: ILeague;
}

export const EmailInvite: React.FC<IProps> = ({league}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const errorMessage = t("leagues.invite.email_invalid");

	const onSubmit = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();
			const serializedForm = serializeForm<IFieldProps>(e.currentTarget);

			const invites = serializedForm.invites
				.split(",")
				.map((e) => e.trim())
				.filter((e) => EMAIL_PATTERN.test(e));

			if (invites.length === 0) {
				dispatch(showApiErrorModal(errorMessage));
				return;
			}

			dispatch(
				sendLeagueEmailInviteRequest({
					leagueId: league.id,
					invites,
				})
			);
		},
		[dispatch, errorMessage, league.id]
	);

	return (
		<Section>
			<SubTitle>{t("leagues.create.email_title")}</SubTitle>
			<form onSubmit={onSubmit}>
				<Input
					name="invites"
					label={t("leagues.form.invites")}
					variant="outlined"
					placeholder={t("leagues.form.invites_placeholder")}
					multiline
					rows={3}
					required={true}
				/>
				<SecondaryButton type="submit">{t("leagues.form.invites_send")}</SecondaryButton>
			</form>
			<InvitesSentModal />
		</Section>
	);
};
