import {css} from "@emotion/react";

export const blueChipAnimation = css`
	width: 256px;
	height: 200px;
	bottom: 0;
	left: -12px;
	opacity: 0;

	@keyframes blueChipAnimationKeyframe {
		0% {
			width: 256px;
			height: 200px;
			bottom: 0;
			left: -12px;
			opacity: 0;
		}

		10% {
			opacity: 1;
		}

		20% {
			width: 161px;
			height: 132px;
		}

		40% {
			width: 110px;
			height: 87px;
		}

		60% {
			width: 78px;
			height: 62px;
		}

		80% {
			opacity: 1;
			width: 52px;
			height: 41px;
			left: 26px;
			bottom: 0;
		}

		100% {
			opacity: 0;
			width: 52px;
			height: 41px;
			left: 26px;
			bottom: 0;
		}
	}

	animation: blueChipAnimationKeyframe 2s linear;
	animation-fill-mode: forwards;
`;

export const yellowChipAnimation = css`
	width: 143px;
	height: 113px;
	left: 100%;
	transform: translateX(-100%);
	bottom: 44px;

	@keyframes yellowChipAnimationKeyframe {
		0% {
			width: 143px;
			height: 113px;
			left: 100%;
			transform: translateX(-100%);
			bottom: 44px;
			opacity: 0;
		}

		10% {
			opacity: 1;
		}

		80% {
			opacity: 1;
			width: 29px;
			height: 23px;
			left: 90px;
			bottom: 25px;
			transform: translateX(0);
		}

		100% {
			opacity: 0;
			width: 29px;
			height: 23px;
			left: 90px;
			bottom: 25px;
			transform: translateX(0);
		}
	}
	opacity: 1;
	animation: yellowChipAnimationKeyframe 2s ease-in-out;
	animation-fill-mode: forwards;
`;

export const purpleChipAnimation = css`
	width: 146px;
	height: 116px;
	left: 96px;
	bottom: 108px;

	@keyframes purpleChipAnimationKeyframe {
		0% {
			width: 146px;
			height: 116px;
			left: 96px;
			bottom: 108px;
			opacity: 0;
		}

		10% {
			opacity: 1;
		}

		80% {
			opacity: 1;
			width: 29px;
			height: 23px;
			left: 60px;
			bottom: 39px;
		}

		100% {
			opacity: 0;
			width: 29px;
			height: 23px;
			left: 60px;
			bottom: 39px;
		}
	}
	opacity: 1;
	animation: purpleChipAnimationKeyframe 2s ease-in-out;
	animation-fill-mode: forwards;
`;

export const mintChipAnimation = css`
	width: 169px;
	height: 136px;
	left: 120px;
	bottom: 0;
	opacity: 0;

	@keyframes mintChipAnimationKeyframe {
		0% {
			width: 169px;
			height: 136px;
			left: 120px;
			bottom: 0;
			opacity: 0;
		}

		10% {
			opacity: 0;
		}

		20% {
			opacity: 1;
			width: 169px;
			height: 136px;
			left: 120px;
			bottom: 0;
			transform: rotate(0);
		}

		40% {
			transform: rotate(15.5deg);
		}

		60% {
			transform: rotate(30deg);
		}

		80% {
			opacity: 1;
			width: 52px;
			height: 42px;
			bottom: 0;
			left: 68px;
		}

		100% {
			opacity: 0;
			width: 52px;
			height: 42px;
			bottom: 0;
			left: 68px;
			transform: rotate(30deg);
		}
	}

	animation: mintChipAnimationKeyframe 2s ease-in-out;
	animation-fill-mode: forwards;
`;

export const pinkChipAnimation = css`
	width: 220px;
	height: 168px;
	left: -20px;
	bottom: 100px;
	transform: rotate(24.5deg);
	opacity: 0;

	@keyframes pinkChipAnimationKeyframe {
		0% {
			width: 220px;
			height: 168px;
			left: -20px;
			bottom: 100px;
			transform: rotate(24.5deg);
			opacity: 0;
		}

		10% {
			opacity: 0;
		}

		20% {
			width: 161px;
			height: 126px;
			left: -20px;
			bottom: 100px;
			transform: rotate(24.5deg);
			opacity: 1;
		}

		40% {
			width: 106px;
			height: 82px;
		}

		60% {
			transform: rotate(-9.5deg);
		}

		80% {
			opacity: 1;
			width: 50.49px;
			height: 39.51px;
			left: 4.63px;
			bottom: 23px;
			transform: rotate(9deg);
		}

		100% {
			opacity: 0;
			width: 50.49px;
			height: 39.51px;
			left: 4.63px;
			bottom: 23px;
			transform: rotate(9deg);
		}
	}

	animation: pinkChipAnimationKeyframe 2s ease-in-out;
	animation-fill-mode: forwards;
`;
