import {IStore} from "modules/types";
import {createSelector} from "@reduxjs/toolkit";

const getState = ({modalReducer}: IStore) => modalReducer;

export const getIsTutorialModalOpen = createSelector(
	getState,
	(state) => state.isTutorialModalOpen
);
export const getIsInvitesModalOpen = createSelector(getState, (state) => state.isInvitesModalOpen);
export const getErrorModalData = createSelector(getState, (state) => state.errorModalData);
export const getIsPredictionSavedModalOpen = createSelector(
	getState,
	(state) => state.isPicksSavedModalOpen
);
export const getIsScoringModalOpen = createSelector(getState, (state) => state.isScoringModalOpen);
export const getIsReturningUserModalOpen = createSelector(
	getState,
	(state) => state.isReturningUserModalOpen
);
export const getIsHalfTimeModalOpen = createSelector(
	getState,
	(state) => state.isHalfTimeModalOpen
);

export const getRemoveLeagueUserModalData = createSelector(
	getState,
	(state) => state.removeLeagueUserData
);

export const getIsResultModalOpen = createSelector(getState, (state) => state.isResultModalOpen);
export const getIsWelcomeModalOpen = createSelector(getState, (state) => state.isWelcomeModalOpen);
