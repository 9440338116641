import {SagaIterator} from "redux-saga";
import {call, delay, put} from "typed-redux-saga";
import Api, {ApiError} from "modules/utils/Api";
import {
	fetchHelpSectionSuccess,
	sendContactUsClear,
	sendContactUsSuccess,
	showApiErrorModal,
} from "modules/actions";
import {IContactUsPayload, ISagaAction} from "modules/types";
import {LanguagesShort} from "modules/enums";

export const fetchHelpSectionSaga = function* ({
	payload,
}: ISagaAction<LanguagesShort>): SagaIterator {
	try {
		const faq = yield* call(Api.JSON.faq, payload);
		yield* put(fetchHelpSectionSuccess({section: "faq", data: faq}));
		const terms = yield* call(Api.JSON.terms, payload);
		yield* put(fetchHelpSectionSuccess({section: "terms", data: terms}));
	} catch (e) {
		console.log(e);
	}
};

export const sendContactUsSaga = function* ({
	payload,
}: ISagaAction<IContactUsPayload>): SagaIterator {
	try {
		const response = yield* call(Api.JSON.contactUs, payload);
		ApiError.CHECK(response);
		yield* put(sendContactUsSuccess());
	} catch (e) {
		const error = e as ApiError;
		yield* put(showApiErrorModal(error.message));
	} finally {
		yield* delay(1000);
		yield* put(sendContactUsClear());
	}
};
