import styled from "@emotion/styled";
import {Slider} from "@mui/material";

export const CustomSlider = styled(Slider)(({theme}) => ({
	"& .MuiSlider-rail": {
		background: theme.background.grey,
	},

	"& .MuiSlider-track": {
		background: theme.actionColors.picks,
		borderColor: theme.actionColors.picks,
	},

	"& .MuiSlider-thumb": {
		background: theme.actionColors.picks,
	},
}));
