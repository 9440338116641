import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";

export const PrimaryLink = styled(NavLink)`
	font-family: ${({theme}) => theme.text.fontFamily};
	font-style: normal;
	font-size: 14px;
	text-align: center;
	font-weight: bold;

	text-transform: uppercase;
	width: 100%;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	transition: 0.25s;
	padding: 12px 8px;
	background: #000000;
	color: ${({theme}) => theme.text.color.white};

	&:active,
	&:focus {
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.5;
	}
`;
