import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconInfo: React.FC<IGeneralIconProps> = ({
                                                          width = 26,
                                                          height = 26,
                                                          color = '#FFFFFF'
                                                      }) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg"
             width={width}
             height={height}
             viewBox="0 0 26 27">
            <path d="M12.2778 8.22217H14.3889V10.3333H12.2778V8.22217ZM12.2778 12.4444H14.3889V18.7777H12.2778V12.4444ZM13.3334 2.9444C7.50672 2.9444 2.77783 7.67329 2.77783 13.5C2.77783 19.3266 7.50672 24.0555 13.3334 24.0555C19.1601 24.0555 23.8889 19.3266 23.8889 13.5C23.8889 7.67329 19.1601 2.9444 13.3334 2.9444ZM13.3334 21.9444C8.67839 21.9444 4.88894 18.155 4.88894 13.5C4.88894 8.84495 8.67839 5.05551 13.3334 5.05551C17.9884 5.05551 21.7778 8.84495 21.7778 13.5C21.7778 18.155 17.9884 21.9444 13.3334 21.9444Z"
                  fill={color}/>
        </svg>
    );
}
