import React, {useCallback, useEffect} from "react";
import {Table, TableCell, TableRow} from "@mui/material";
import {CenteredCell, LoadMoreWrapper, PrimaryButton, TBody, THead} from "components/Common";
import {useTranslation} from "react-i18next";
import {LeaderboardRow} from "components/Leaderboards/LeaderboardRow";
import {IFetchOverallRankingsPayload, ILeaderboardProps} from "modules/types";
import {celebrityRankingsClear, fetchCelebrityRankingsRequest} from "modules/actions/Rankings";
import {useDispatch, useSelector} from "react-redux";
import {Exist} from "components/Exist";
import {getCelebrityRanks, getRequestStateObject} from "modules/selectors/Rankings";
import {LocalPreloader} from "components/Preloader";
import {LeaderboardOrder} from "modules/enums";

export const LeaderboardCelebrity: React.FC<ILeaderboardProps<IFetchOverallRankingsPayload>> = ({
	filters,
	onLoadMore,
	onOrderChange,
}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const tableData = useSelector(getCelebrityRanks);
	const {isLoading} = useSelector(getRequestStateObject);
	const hasNoData = tableData.rankings.length === 0 && !isLoading;
	const isOverall = filters.order === LeaderboardOrder.Season;

	useEffect(() => {
		dispatch(fetchCelebrityRankingsRequest(filters));
	}, [dispatch, filters]);

	const onChangeOrderHandler = useCallback(
		(event: React.SyntheticEvent<HTMLTableCellElement>) => {
			const order = event.currentTarget.dataset.name as LeaderboardOrder;
			if (!order || order === filters.order) {
				return;
			}

			onOrderChange(order);
			dispatch(celebrityRankingsClear());
		},
		[onOrderChange, filters, dispatch]
	);

	const getIsHeaderActive = useCallback(
		(order: LeaderboardOrder) => {
			return filters.order === order ? "active" : "";
		},
		[filters]
	);

	return (
		<React.Fragment>
			<Table>
				<THead>
					<TableRow>
						<TableCell>{t("leagues.standings.rank")}</TableCell>
						<TableCell className="username">
							{t("leagues.standings.username")}
						</TableCell>
						<TableCell
							className={`points ${getIsHeaderActive(LeaderboardOrder.Weekly)}`}
							data-name={LeaderboardOrder.Weekly}
							onClick={onChangeOrderHandler}>
							{t("leagues.standings.week_t")}
						</TableCell>
						<TableCell
							data-name={LeaderboardOrder.Season}
							className={`points ${getIsHeaderActive(LeaderboardOrder.Season)}`}
							onClick={onChangeOrderHandler}>
							{t("leagues.standings.season_t")}
						</TableCell>
					</TableRow>
				</THead>
				<TBody>
					{tableData.rankings.map((row) => (
						<LeaderboardRow
							key={row.overallRank}
							item={row}
							isOverall={isOverall}
							showRoundPoints={Boolean(filters.round)}
							orderBy={filters.order}
						/>
					))}
					<Exist when={hasNoData}>
						<TableRow>
							<CenteredCell colSpan={4}>{t("leagues.standings.empty")}</CenteredCell>
						</TableRow>
					</Exist>
					<Exist when={Boolean(tableData.user && !hasNoData)}>
						<LeaderboardRow
							item={tableData.user}
							isOverall={isOverall}
							showRoundPoints={Boolean(filters.round)}
							customClass="user-row"
						/>
					</Exist>
				</TBody>
			</Table>

			<Exist when={isLoading}>
				<LocalPreloader />
			</Exist>

			<Exist when={tableData.next}>
				<LoadMoreWrapper>
					<PrimaryButton disabled={isLoading} onClick={onLoadMore}>
						{t("common.load_more")}
					</PrimaryButton>
				</LoadMoreWrapper>
			</Exist>
		</React.Fragment>
	);
};
