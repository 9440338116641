import React, {useCallback} from "react";
import {ModalBody, PrimaryButton} from "components/Common";
import {useTour} from "@reactour/tour";
import {useTranslation} from "react-i18next";
import {Exist} from "components/Exist";
import {TutorialSteps} from "components/Tutorial/TutorialSteps";

interface IProps {
	title: string;
	text: string;
	text2?: string;
}

export const TutorialModal: React.FC<IProps> = ({title, text, text2}) => {
	const {t} = useTranslation();
	const {steps, currentStep, setCurrentStep, setIsOpen} = useTour();

	const isEnd = currentStep + 1 === steps.length;

	const goToNextStep = useCallback(() => {
		if (isEnd) {
			setIsOpen(false);
			return;
		}
		setCurrentStep(currentStep + 1);
	}, [isEnd, setCurrentStep, currentStep, setIsOpen]);

	return (
		<ModalBody>
			<h2 className="title">{title}</h2>
			<p className="body-text">{text}</p>
			<Exist when={Boolean(text2)}>
				<p className="body-text">{text2}</p>
			</Exist>

			<TutorialSteps />

			<PrimaryButton onClick={goToNextStep} className="active">
				<Exist when={isEnd}>{t("game.tour.finish")}</Exist>
				<Exist when={!isEnd}>{t("game.tour.next")}</Exist>
			</PrimaryButton>
		</ModalBody>
	);
};
