import React from "react";
import {useTranslation} from "react-i18next";
import {Nullable} from "modules/types";
import {getDashedValue} from "modules/utils/helpers";

interface IProps {
	placed: number;
	predictionId: Nullable<number>;
}

export const RemainingChips: React.FC<IProps> = ({placed, predictionId}) => {
	const {t} = useTranslation();
	return (
		<div className="remaining">
			<h4>{t("game.prediction.bet")}</h4>
			<p>{getDashedValue(placed)}</p>
		</div>
	);
};
