import {createAction} from "redux-act";
import {
	IContestAnswersResponse,
	IEditPickPayload,
	IPickRequestPayload,
	IPredictionStateChangePayload,
	IUserChipsObject,
} from "modules/types";

export const togglePredictionRequestState = createAction<IPredictionStateChangePayload>();

export const openPredictionModal = createAction<number>();
export const closePredictionModal = createAction();

export const openPredictionCompleteModal = createAction<number>();
export const closePredictionCompleteModal = createAction();

export const makePredictionRequest = createAction<IPickRequestPayload>();
export const makePredictionSuccess = createAction();

export const editPredictionRequest = createAction<IEditPickPayload>();

export const fetchAnswersByContestIdRequest = createAction<number>();
export const fetchAnswersByContestIdSuccess = createAction<IContestAnswersResponse>();

export const fetchUserChipsByMatch = createAction<number>();
export const fetchUserChipsByMatchSuccess = createAction<IUserChipsObject>();

export const clearChips = createAction();
