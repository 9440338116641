import {createAction} from "redux-act";
import {IErrorModalPayload, ILeagueRemoveUserModalAction} from "modules/types";

export const toggleTutorialModal = createAction<boolean>();

export const openErrorModal = createAction<IErrorModalPayload>();
export const showApiErrorModal = createAction<string>();
export const closeErrorModal = createAction();

export const toggleInvitesModal = createAction<boolean>();

export const openRemoveLeagueUserModal = createAction<ILeagueRemoveUserModalAction>();
export const closeRemoveLeagueUserModal = createAction();

export const togglePredictSavedModal = createAction<boolean>();
export const toggleScoringModal = createAction<boolean>();
export const toggleReturningUserModal = createAction<boolean>();
export const toggleHalfTimeModal = createAction<boolean>();
export const toggleResultModal = createAction<boolean>();
export const toggleWelcomeModal = createAction<boolean>();
