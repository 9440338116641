export abstract class LocalStorageService {
    private static storageKey = "lvr_"

    private static GENERATE_KEY(key: string) {
        return LocalStorageService.storageKey + key;
    }

    public static SET<T>(key: string, value: T) {
        const val = typeof value === "string" ? value : JSON.stringify(value || "");
        localStorage.setItem(LocalStorageService.GENERATE_KEY(key), val);
    }

    public static GET(key: string) {
        return localStorage.getItem(LocalStorageService.GENERATE_KEY(key));
    }

    public static REMOVE(key: string): void {
        localStorage.removeItem(LocalStorageService.GENERATE_KEY(key));
    }

    public static FLUSH(): void {
        localStorage.clear();
    }
}
