import {IApiResponse} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
    public static AUTH_REQUIRED = 401;
    // public static USER_EXPIRED_TOKEN = 510;
    public static USER_NOT_REGISTERED = 409;

    public static HAS_ERROR({errors}: IApiResponse) {
        return Boolean(Array.isArray(errors) && errors.length);
    }

    /**
     * Should be called after each request when we expect some errors or validations
     */
    public static CHECK(response: IApiResponse) {
        if (ApiError.HAS_ERROR(response)) {
            const error = response.errors[0];
            throw new ApiError(error.message, error.code);
        }
    }

    public code?: number;

    constructor(m: string, code = 0) {
        super(m);
        this.name = "API Error";
        this.message = m;
        this.code = code;
    }
}

export default ApiError;
