import React, {useCallback, useEffect, useRef} from "react";
import styled from "@emotion/styled";
import AssetWeekCard from "assets/img/WeekCard.svg";
import {PureButton} from "components/Common";
import {IconChevron} from "components/Icons";
import {PredictWeekTimer} from "components/PredictWeekTimer";
import {useDispatch, useSelector} from "react-redux";
import {getAvailableMatches, getSelectedMatch} from "modules/selectors";
import {LocalPreloader} from "components/Preloader";
import {DateTime} from "luxon";
import {SquadBlock} from "components/SquadBlock";
import {LocaleByLanguages, RAIDERS_ID} from "modules/constants";
import Slider, {Settings} from "react-slick";
import "slick-carousel/slick/slick.css";
import {checkHalf, setSelectedMatch} from "modules/actions";
import {LanguagesShort} from "modules/enums";
import {useTranslation} from "react-i18next";
import {get} from "lodash";

const Wrapper = styled("div")`
	position: relative;

	.slick-slider {
		max-width: calc(335px + 24px);
		margin: 0 auto;
	}
`;

const PredictWeek = styled("div")`
	position: relative;
	background: url(${AssetWeekCard});
	background-size: 100% 100%;
	min-height: 106px; // 126px;
	padding: 7px 40px 16px 40px;
	max-width: 335px;
	margin: 0 auto;
	//border-top: 10px solid #ffffff;
	//border-bottom: 10px solid #ffffff;

	//&:after {
	//	content: "";
	//	position: absolute;
	//	top: -10px;
	//	left: 0;
	//	right: 0;
	//	height: 20px;
	//	background: #ffffff;
	//	z-index: -1;
	//}
	//
	//&:before {
	//	content: "";
	//	position: absolute;
	//	bottom: -10px;
	//	left: 0;
	//	right: 0;
	//	height: 15px;
	//	background: #ffffff;
	//	z-index: -1;
	//}

	.loader-wrapper {
		padding: 28px 0 0 0;

		span {
			color: ${({theme}) => theme.text.color.dark};
		}
	}
`;

const ActionButton = styled(PureButton)`
	position: absolute;
	top: 50%;
	width: 36px;
	height: 36px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4;

	&.slick-disabled {
		opacity: 0.5;
		//pointer-events: none;
	}
`;

const ButtonPrev = styled(ActionButton)`
	left: 12px;
	transform: translate(-14px, -50%);
`;

const ButtonNext = styled(ActionButton)`
	right: 12px;
	transform: translate(14px, -50%);
`;

const TimerWrapper = styled("div")`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
`;

const InnerSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	p.time {
		color: ${({theme}) => theme.text.color.dark};
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		line-height: 13px;
		text-align: center;
	}

	div.players {
		margin-top: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 18px;
	}
`;

const Versus = styled.div`
	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: center;
	text-align: center;
`;

const Preloader = () => {
	return (
		<Wrapper>
			<PredictWeek>
				<LocalPreloader />
			</PredictWeek>
		</Wrapper>
	);
};

export const PredictNavigation: React.FC = () => {
	const dispatch = useDispatch();
	const match = useSelector(getSelectedMatch);
	const availableMatches = useSelector(getAvailableMatches);
	const sliderRef = useRef<Slider>(null);

	const {i18n} = useTranslation();
	const language = i18n.language as LanguagesShort;

	const onSliderChange = useCallback(
		(currentSlide: number) => {
			const match = availableMatches[currentSlide];
			if (!match) {
				return;
			}

			dispatch(setSelectedMatch(match));
			dispatch(checkHalf());
		},
		[availableMatches, dispatch]
	);

	useEffect(() => {
		const index = availableMatches.findIndex((item) => item.id === match?.id);

		if (index !== -1) {
			sliderRef.current?.slickGoTo(index);
		}
	}, [availableMatches, match?.id]);

	const settings: Settings = {
		dots: false,
		infinite: false,
		arrows: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		afterChange: onSliderChange,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	if (!match) {
		return <Preloader />;
	}

	const isAway = Number(RAIDERS_ID) === match.awaySquad;
	const locale = get(LocaleByLanguages, language, LocaleByLanguages[LanguagesShort.English]);

	// Sun, 10/12 - 1:25pm ET
	const startDate = DateTime.fromISO(match.startAt)
		.setLocale(locale)
		.toFormat("ccc, LL/dd hh:mm a ZZZZ");

	return (
		<Wrapper>
			<Slider {...settings} ref={sliderRef}>
				{availableMatches.map((item) => (
					<div key={item.id}>
						<PredictWeek>
							<InnerSection>
								<p className="time">{startDate}</p>
								<div className="players">
									<SquadBlock
										squadId={isAway ? match.awaySquad : match.homeSquad}
									/>
									<Versus>{isAway ? "@" : "VS"}</Versus>
									<SquadBlock
										squadId={isAway ? match.homeSquad : match.awaySquad}
									/>
								</div>
							</InnerSection>
						</PredictWeek>
					</div>
				))}
			</Slider>
			<TimerWrapper>
				<PredictWeekTimer />
			</TimerWrapper>
		</Wrapper>
	);
};

interface IButtonProps {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	className?: string;
}

const NextArrow = ({onClick, className}: IButtonProps) => {
	const disabled = className === "slick-disabled";
	return (
		<ButtonNext onClick={onClick} className={className} disabled={disabled}>
			<IconChevron color="#FFFFFF" to="right" />
		</ButtonNext>
	);
};

const PrevArrow = ({onClick, className}: IButtonProps) => {
	const disabled = className === "slick-disabled";
	return (
		<ButtonPrev onClick={onClick} className={className} disabled={disabled}>
			<IconChevron color="#FFFFFF" to="left" />
		</ButtonPrev>
	);
};
