import {createAction} from "redux-act";
import {IMatch, IMatchResponse, IMatchResult} from "modules/types";
import {ContestHalf} from "modules/enums";

export const fetchMatchesRequest = createAction();
export const fetchMatchesSuccess = createAction<IMatchResponse>();

export const setSelectedMatch = createAction<IMatch>();
export const setSelectedHalf = createAction<ContestHalf>();

export const goToNextMatch = createAction();
export const goToPrevMatch = createAction();

export const fetchMatchResultRequest = createAction<number>();
export const fetchMatchResultSuccess = createAction<IMatchResult>();
export const fetchMatchResultClear = createAction();

export const checkHalf = createAction();
