import {LocalStorageService} from "modules/utils/LocalStorageService";

export abstract class ResultModalService {
	private static LOCALSTORAGE_KEY = "viewed_results";

	public static CheckMatchId(matchId: number): boolean {
		return this.getViewedModals().includes(matchId);
	}

	public static StoreMatchId(matchId: number): void {
		const matches = this.getViewedModals().filter((id) => id !== matchId);
		matches.push(matchId);
		LocalStorageService.SET(this.LOCALSTORAGE_KEY, JSON.stringify(matches));
	}

	private static getViewedModals(): number[] {
		return JSON.parse(LocalStorageService.GET(this.LOCALSTORAGE_KEY) || "[]") as number[];
	}
}
