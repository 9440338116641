import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {ILocationState} from "modules/types";
import {useSelector} from "react-redux";
import {getIsLogged} from "modules/selectors/User";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const loggedInLink = "/predict" + location.search;
	const backURL = String((location.state as ILocationState)?.from || loggedInLink);
	const isLoggedIn = useSelector(getIsLogged);

	if (!backURL && isLoggedIn) {
		return <Navigate to={loggedInLink} />;
	}

	if (isLoggedIn) {
		return <Navigate to={backURL} />;
	}
	return <Outlet />;
};
