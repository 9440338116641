import styled from "@emotion/styled";
import {PrimaryButton, SecondaryButton} from "components/Common";
import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagueById, getLeaguesRequestStateByKey, getUser} from "modules/selectors";
import {Exist} from "components/Exist";
import {useNavigate} from "react-router-dom";
import {fetchSingleLeagueRequest, leaveLeagueRequest} from "modules/actions";
import {ButtonPreloader} from "components/Preloader";
import {useTranslation} from "react-i18next";

const Wrapper = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 12px;

	h2 {
		color: ${({theme}) => theme.text.color.white};
		font-family: ${({theme}) => theme.text.fontFamily};
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.4px;
		text-transform: capitalize;
	}

	p {
		color: ${({theme}) => theme.text.color.white};
		font-family: ${({theme}) => theme.text.fontFamily};
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.4px;
	}
`;

interface IProps {
	leagueId: number;
}
export const LeaveLeague: React.FC<IProps> = ({leagueId}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const navigate = useNavigate();
	const league = useSelector(getLeagueById)(leagueId);
	const user = useSelector(getUser);
	const {isLoading, isSuccess} = useSelector(getLeaguesRequestStateByKey)("leaveLeague");

	const isOwner = user?.id === league?.user_id;
	const isJoined = true;

	useEffect(() => {
		if (!isSuccess) {
			return;
		}

		if (!isOwner) {
			navigate("/leagues");
			return;
		}
		dispatch(fetchSingleLeagueRequest(leagueId));
	}, [isSuccess, navigate, dispatch, league, isOwner, leagueId]);

	const leaveLeague = useCallback(() => {
		dispatch(leaveLeagueRequest(leagueId));
	}, [dispatch, leagueId]);

	useEffect(() => {
		if (!league) {
			return;
		}

		if (!isOwner && !isJoined) {
			navigate("/leagues");
		}
	}, [navigate, isJoined, isOwner, league]);

	return (
		<Wrapper>
			<h2>{t("leagues.leave.title")}</h2>
			<p>{t("leagues.leave.text")}</p>
			<Exist when={isJoined}>
				<PrimaryButton onClick={leaveLeague} disabled={isLoading}>
					<Exist when={!isLoading}>
						<span>{t("leagues.leave.leave")}</span>
					</Exist>
					<Exist when={isLoading}>
						<ButtonPreloader />
					</Exist>
				</PrimaryButton>
			</Exist>
			<Exist when={!isJoined}>
				<SecondaryButton>{t("leagues.leave.join")}</SecondaryButton>
			</Exist>
		</Wrapper>
	);
};
