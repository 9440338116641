import styled from "@emotion/styled";
import {PrimaryButton, SecondaryButton} from "components/Common";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Wrapper = styled("div")`
	padding: 16px;
	border: 1px solid #ffffff;
	border-radius: 4px;
	margin-bottom: 24px;

	> * {
		font-family: ${({theme}) => theme.text.fontFamily};
		color: ${({theme}) => theme.text.color.white};
	}

	h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.4px;
		text-transform: capitalize;
		margin-bottom: 8px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.4px;
		margin-bottom: 20px;
	}
`;

const Buttons = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;

	button {
		width: 50%;
	}
`;

export const LeagueCreateJoinBlock: React.FC = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const navigateTo = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const path = e.currentTarget.dataset.to;

			if (!path) {
				return;
			}

			navigate(path);
		},
		[navigate]
	);

	return (
		<Wrapper>
			<h2>{t("leagues.page.create_join")}</h2>
			<p className="desc">{t("leagues.page.create_join_desc")}</p>
			<Buttons>
				<PrimaryButton onClick={navigateTo} data-to="join">
					{t("leagues.page.join")}
				</PrimaryButton>
				<SecondaryButton onClick={navigateTo} data-to="create">
					{t("leagues.page.create")}
				</SecondaryButton>
			</Buttons>
		</Wrapper>
	);
};
