import React from 'react';
import { IGeneralIconProps } from 'modules/types';

export const IconClose: React.FC<IGeneralIconProps> = ({
                                                           width = 24,
                                                           height = 24,
                                                           color = '#FFFFFF'
                                                       }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <path
                d="M19 6.33676L17.59 4.94287L12 10.469L6.41 4.94287L5 6.33676L10.59 11.8629L5 17.389L6.41 18.7829L12 13.2568L17.59 18.7829L19 17.389L13.41 11.8629L19 6.33676Z"
                fill={color}/>
        </svg>
    );
}
