import {createReducer} from "redux-act";
import {IOverallRankingsResponse, IRankingsReducer, IReducerAction} from "modules/types";
import {
	celebrityRankingsClear,
	clearRequestProcess,
	fetchCelebrityRankingsConcat,
	fetchCelebrityRankingsSuccess,
	fetchInVenueRankingsConcat,
	fetchInVenueRankingsSuccess,
	fetchLeagueRankingsConcat,
	fetchLeagueRankingsSuccess,
	fetchOverallRankingsConcat,
	fetchOverallRankingsSuccess,
	inVenueRankingsClear,
	leagueRankingsClear,
	overallRankingsClear,
	setRequestProcess,
} from "modules/actions/Rankings";
import {RequestStateType} from "modules/enums";

type IRankingsReducerPayload<TP = null> = IReducerAction<IRankingsReducer, TP>;

const initialState: IRankingsReducer = {
	requestState: RequestStateType.Idle,
	singleLeague: {
		rankings: [],
		user: null,
		next: false,
	},
	overall: {
		rankings: [],
		user: null,
		next: false,
	},
	celebrity: {
		rankings: [],
		user: null,
		next: false,
	},
	inVenue: {
		rankings: [],
		user: null,
		next: false,
	},
};

const onFetchOverallRankingsSuccess: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	overall: payload,
	requestState: RequestStateType.Idle,
});

const onFetchOverallRankingsConcat: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	overall: {
		user: payload.user ?? state.overall.user,
		rankings: [...state.overall.rankings, ...payload.rankings],
		next: payload.next,
	},
	requestState: RequestStateType.Idle,
});

const onFetchLeagueRankingsSuccess: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	singleLeague: payload,
	requestState: RequestStateType.Idle,
});

const onFetchLeagueRankingsConcat: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	singleLeague: {
		user: payload.user ?? state.singleLeague.user,
		rankings: [...state.singleLeague.rankings, ...payload.rankings],
		next: payload.next,
	},
	requestState: RequestStateType.Idle,
});

const onFetchCelebrityRankingsSuccess: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	celebrity: payload,
	requestState: RequestStateType.Idle,
});

const onFetchCelebrityRankingsConcat: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	celebrity: {
		user: payload.user ?? state.celebrity.user,
		rankings: [...state.celebrity.rankings, ...payload.rankings],
		next: payload.next,
	},
	requestState: RequestStateType.Idle,
});

const onFetchInVenueRankingsSuccess: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	inVenue: payload,
	requestState: RequestStateType.Idle,
});

const onFetchInVenueRankingsConcat: IRankingsReducerPayload<IOverallRankingsResponse> = (
	state,
	payload
) => ({
	...state,
	inVenue: {
		user: payload.user ?? state.inVenue.user,
		rankings: [...state.inVenue.rankings, ...payload.rankings],
		next: payload.next,
	},
	requestState: RequestStateType.Idle,
});

const onSetRequestProcess: IRankingsReducerPayload = (state) => ({
	...state,
	requestState: RequestStateType.Loading,
});

const onClearRequestProcess: IRankingsReducerPayload = (state) => ({
	...state,
	requestState: RequestStateType.Idle,
});

const onOverallRankingsClear: IRankingsReducerPayload = (state) => ({
	...state,
	overall: {
		user: null,
		rankings: [],
		next: false,
	},
});

const onCelebrityRankingsClear: IRankingsReducerPayload = (state) => ({
	...state,
	celebrity: {
		user: null,
		rankings: [],
		next: false,
	},
});

const onInVenueRankingsClear: IRankingsReducerPayload = (state) => ({
	...state,
	inVenue: {
		user: null,
		rankings: [],
		next: false,
	},
});

const onLeagueRankingsClear: IRankingsReducerPayload = (state) => ({
	...state,
	singleLeague: {
		user: null,
		rankings: [],
		next: false,
	},
});

export const rankingsReducer = createReducer({}, initialState)
	.on(fetchOverallRankingsSuccess, onFetchOverallRankingsSuccess)
	.on(fetchOverallRankingsConcat, onFetchOverallRankingsConcat)
	.on(fetchLeagueRankingsSuccess, onFetchLeagueRankingsSuccess)
	.on(fetchLeagueRankingsConcat, onFetchLeagueRankingsConcat)
	.on(fetchCelebrityRankingsSuccess, onFetchCelebrityRankingsSuccess)
	.on(fetchCelebrityRankingsConcat, onFetchCelebrityRankingsConcat)
	.on(fetchInVenueRankingsSuccess, onFetchInVenueRankingsSuccess)
	.on(fetchInVenueRankingsConcat, onFetchInVenueRankingsConcat)
	.on(setRequestProcess, onSetRequestProcess)
	.on(clearRequestProcess, onClearRequestProcess)

	.on(celebrityRankingsClear, onCelebrityRankingsClear)
	.on(inVenueRankingsClear, onInVenueRankingsClear)
	.on(overallRankingsClear, onOverallRankingsClear)
	.on(leagueRankingsClear, onLeagueRankingsClear);
