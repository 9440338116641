import styled from "@emotion/styled";
import React, {useCallback} from "react";
import {PureButton} from "components/Common";
import {IconHelp} from "components/Icons";
import {ChipsAnimation} from "components/ChipsAnimation";
import {useTour} from "@reactour/tour";
import {ChipsCount} from "components/PredictBottomSheet/ChipsCount";
import {ChipsImage} from "components/PredictBottomSheet/ChipsImage";
import {BottomSheetSharing} from "components/PredictBottomSheet/BottomSheetSharing";

const Wrapper = styled("div")`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	background: #ffffff;
	border-radius: 28px 28px 0 0;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 6;
`;

const ButtonsWrapper = styled.div`
	padding: 4px 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const ActionButton = styled(PureButton)`
	width: 38px;
	height: 38px;
	background: ${({theme}) => theme.background.grey};
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
`;

const ChipsBlock = styled("div")`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

export const PredictBottomSheet: React.FC = () => {
	const {setIsOpen} = useTour();

	const startTutorial = useCallback(() => {
		window.scrollTo({left: 0, top: 150, behavior: "smooth"});
		setTimeout(() => {
			setIsOpen(true);
		}, 300);
	}, [setIsOpen]);

	return (
		<Wrapper>
			<ChipsBlock>
				<ChipsAnimation />
				<ChipsImage />
				<ChipsCount />
			</ChipsBlock>

			<ButtonsWrapper>
				<ActionButton onClick={startTutorial}>
					<IconHelp width={22} height={22} />
				</ActionButton>
				<BottomSheetSharing />
			</ButtonsWrapper>
		</Wrapper>
	);
};
