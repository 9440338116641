import React from "react";
import {TableCell} from "@mui/material";
import {IconPrize} from "components/Icons";
import {getDashedValue, getIsHeaderActive, getZeroValue} from "modules/utils/helpers";
import {IOverallRankingsRow, Nullable} from "modules/types";
import {STableRow} from "components/Common";
import {LeaderboardOrder} from "modules/enums";

interface IProps {
	item: Nullable<IOverallRankingsRow>;
	customClass?: string;
	isOverall?: boolean;
	showRoundPoints: boolean;
	orderBy?: LeaderboardOrder;
}

export const LeaderboardRow: React.FC<IProps> = ({
	item,
	customClass = "",
	isOverall = false,
	orderBy,
	showRoundPoints,
}) => {
	const rank = isOverall ? item?.overallRank : item?.roundRank;
	const className = `rank-${getDashedValue(rank)}`;

	if (!item) {
		return null;
	}

	return (
		<STableRow className={`${className} ${customClass}`}>
			<TableCell className="prize-section">
				<span>{getDashedValue(rank, true)}</span>
				<IconPrize width={19} height={19} />
			</TableCell>
			<TableCell className="username">{item?.userName}</TableCell>
			<TableCell
				className={`points
				 ${getIsHeaderActive(LeaderboardOrder.Weekly, orderBy)}
				  ${className}`}>
				{showRoundPoints ? getZeroValue(item?.roundPoints) : "-"}
			</TableCell>
			<TableCell
				className={`points
				 ${getIsHeaderActive(LeaderboardOrder.Season, orderBy)}
				  ${className}`}>
				{getZeroValue(item?.overallPoints)}
			</TableCell>
		</STableRow>
	);
};
