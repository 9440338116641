import React from "react";
import {SubTitle} from "components/Common";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ENV} from "modules/constants";
import {ENVS} from "modules/enums";
import {get} from "lodash";

import AssetQRUAT from "assets/img/qr/qr_uat.svg";
import AssetQRPROD from "assets/img/qr/qr_production.png";

const Section = styled("section")`
	margin-bottom: 20px;

	img {
		width: 140px;
		height: auto;
		color: #ffffff;
	}
`;

const QR_MAPPER = {
	[ENVS.UAT]: AssetQRUAT,
	[ENVS.PREPROD]: AssetQRPROD,
	[ENVS.PROD]: AssetQRPROD,
};

interface IProps {
	code: string;
}

export const QrInvite: React.FC<IProps> = ({code}) => {
	const {t} = useTranslation();
	const QrImage = get(QR_MAPPER, ENV, QR_MAPPER[ENVS.PROD]) as string;
	return (
		<Section>
			<SubTitle>{t("leagues.create.qr_title")}</SubTitle>
			<img src={QrImage} alt={code} />
		</Section>
	);
};
