import React from "react";
import {BottomSheet} from "components/Common";
import {useTour} from "@reactour/tour";

import AssetTutorialSheet from "assets/img/TutorialBottomSheet.png";
import styled from "@emotion/styled";

const SBottomSheet = styled(BottomSheet)`
	padding: 0;
	min-height: 400px;
	justify-content: flex-end;
`;

const Image = styled("img")`
	width: 100%;
	height: 100%;
`;

export const TutorialBottomSheet: React.FC = () => {
	const {currentStep} = useTour();
	const openClass = currentStep === 1 ? "open" : "";

	return (
		<SBottomSheet className={`tutorial-sheet ${openClass}`}>
			<Image src={AssetTutorialSheet} alt="step-2" />
		</SBottomSheet>
	);
};
