import {IContestProp, IMatch, ITutorialState, NullOrEmpty} from "modules/types";
import {StylesObj} from "@reactour/tour/dist/styles";
import {LeaderboardOrder, PredictionSuggest, RequestStateType} from "modules/enums";
import {DateTime} from "luxon";

export function doPopoverArrow(base: StylesObj, state: ITutorialState, position: string) {
	let arrowStyles = {};
	const colorBorderStyle = "12px solid #FFFFFF";

	if (position === "top") {
		arrowStyles = {
			borderBottom: colorBorderStyle,
			right: "calc(50% - 12px)",
			top: "-10px",
		};
	}

	if (position === "bottom") {
		arrowStyles = {
			borderTop: colorBorderStyle,
			right: "calc(50% - 12px)",
			bottom: "-10px",
		};
	}

	if (position === "right-corner") {
		arrowStyles = {
			borderBottom: colorBorderStyle,
			right: "15%",
			top: "-10px",
		};
	}

	return {
		...base,
		borderRadius: 6,
		"&::after": {
			content: '""',
			position: "absolute",
			width: 0,
			height: 0,
			borderLeft: "8px solid transparent",
			borderRight: "8px solid transparent",
			...arrowStyles,
		},
	};
}

export function serializeForm<T>(form: HTMLFormElement): T {
	return Object.fromEntries(new FormData(form)) as unknown as T;
}

export function parseStateType(state: RequestStateType) {
	const isIdle = state === RequestStateType.Idle;
	const isLoading = state === RequestStateType.Loading;
	const isSuccess = state === RequestStateType.Success;
	const isError = state === RequestStateType.Error;
	const isLoadingOrSuccess = isLoading || isSuccess;

	return {isIdle, isLoading, isSuccess, isError, isLoadingOrSuccess};
}

export function sortMatchesByDate(first: IMatch, second: IMatch) {
	const firstStart = DateTime.fromISO(first.startAt);
	const secondsStart = DateTime.fromISO(second.startAt);

	if (firstStart === secondsStart) {
		return 0;
	}

	return firstStart > secondsStart ? 1 : -1;
}

export function getDashedValue(value: string | number | undefined | null, parseZero = false) {
	if (parseZero && !value) {
		return "-";
	}

	if (value === undefined || value === null) {
		return "-";
	}
	return String(value);
}

export function getZeroValue(value: string | number | undefined | null) {
	if (value === undefined || value === null) {
		return 0;
	}
	return Number(value);
}

export function getButtonClass(suggest: NullOrEmpty<PredictionSuggest>, isPicked: boolean) {
	let className = "";
	if (isPicked) {
		className += "picked ";
	}

	switch (suggest) {
		case PredictionSuggest.Correct:
			className += "correct ";
			break;
		case PredictionSuggest.Incorrect:
			className += "incorrect ";
	}
	return className;
}

export function getChipColor(bet: NullOrEmpty<number>) {
	if (bet === null || bet === undefined) {
		return "";
	}
	if (bet < 25) {
		return "#00AAF4";
	}

	if (bet < 50) {
		return "#DD0C0C";
	}

	if (bet < 100) {
		return "#0CDD63";
	}

	return "#000000";
}

export function calculatePredictionOutcome(placed: number, pick: NullOrEmpty<IContestProp>) {
	if (!pick) {
		return placed;
	}

	return placed * pick.multiplier;
}

export function parseCookie(cookie: string): Record<string, string> {
	if (!cookie) {
		return {};
	}

	const keyValue = cookie.split(";").map((c) => c.trim());
	const mapper = keyValue.map((item) => item.split("="));
	return Object.fromEntries(mapper) as Record<string, string>;
}

export function withTimeStamp(path: string) {
	const t = new Date().getTime().toString();
	return `${path}?t=${t}`;
}

function resizeAndCall<T extends HTMLElement, TS extends HTMLSpanElement>(
	this: unknown,
	container: T,
	text: TS,
	adjustLineHeight?: number,
	forceTextHeight?: number,
	maxFontSize?: number
) {
	const iteration = (this as TIteration)?.iteration ?? 0;
	const font_size_float = window.getComputedStyle(text, null).getPropertyValue("font-size");
	let font_size = parseFloat(font_size_float);

	if (font_size - 1 > (maxFontSize ?? 0)) {
		font_size = maxFontSize ?? font_size - 1;
	}

	text.style.fontSize = `${font_size}px`;

	if (adjustLineHeight !== null && adjustLineHeight !== undefined) {
		text.style.lineHeight = `${font_size + Number(adjustLineHeight)}px`;
	}

	resizeToFit.call(
		{iteration: iteration + 1},
		container,
		text,
		adjustLineHeight,
		forceTextHeight
	);
}

function checkTransition<T extends HTMLElement>(text: T) {
	const textTransition = window.getComputedStyle(text, null).getPropertyValue("transition");

	if (textTransition) {
		text.style.transition = "none";
	}
}

type TIteration = {iteration: number};

export function resizeToFit<T extends HTMLElement, TS extends HTMLSpanElement>(
	this: unknown,
	container: T,
	text: TS,
	adjustLineHeight?: number,
	forceTextHeight?: number
) {
	const iteration = (this as TIteration)?.iteration ?? 0;
	// Fix if infinity loop
	if (iteration > 100) {
		return;
	}

	checkTransition(text);

	if (forceTextHeight !== null && forceTextHeight !== undefined) {
		if (text.clientHeight > forceTextHeight) {
			resizeAndCall.call(
				{iteration: iteration + 1},
				container,
				text,
				adjustLineHeight,
				forceTextHeight,
				15
			);
		}
		return;
	}

	if (container.scrollHeight > container.clientHeight) {
		resizeAndCall.call(
			{iteration: iteration + 1},
			container,
			text,
			adjustLineHeight,
			forceTextHeight,
			15
		);
	}
}

export function parseParams(query: string) {
	return query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split("&")
				.reduce((params: Record<string, string>, param) => {
					const [key, value] = param.split("=");
					params[key] = value ?? "";
					return params;
				}, {})
		: {};
}

export function getChipClassAccordingOutcome(outcome: NullOrEmpty<number>) {
	if (outcome === 0) {
		return "incorrect";
	}

	if (outcome === null) {
		return "picked";
	}

	if (Number(outcome) > 0) {
		return "correct";
	}

	return "";
}

export function getIsHeaderActive(
	order: LeaderboardOrder,
	currentOrder: NullOrEmpty<LeaderboardOrder>
) {
	return currentOrder === order ? "active" : "";
}

export function getSuggestion(outcome: NullOrEmpty<number>) {
	if (outcome === null || outcome === undefined) {
		return null;
	}

	return outcome === 0 ? PredictionSuggest.Incorrect : PredictionSuggest.Correct;
}

export function getBetClass(bet: NullOrEmpty<number>) {
	if (Number(bet) >= 1000) {
		return "thousand";
	}

	if (Number(bet) >= 100) {
		return "hundred";
	}

	return "";
}
