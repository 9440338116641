import styled from "@emotion/styled";
import {CircularProgress} from "@mui/material";
import React from "react";

const Wrapper = styled("div")`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	background: #000;
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		color: ${({theme}) => theme.text.color.white};
		font-family: ${({theme}) => theme.text.fontFamily};
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		text-align: center;
		text-transform: uppercase;
	}
`;

const LocalWrapper = styled("div")<{color?: string}>`
	color: ${(props) => props.color || props.theme.text.color.white};
	text-align: center;
	padding: 20px;

	span {
		color: ${(props) => props.color || props.theme.text.color.white};
	}
`;

export const ButtonPreloader: React.FC = () => {
	return <CircularProgress className="loader" size={24} color="secondary" />;
};

interface IProps {
	color?: string;
}
export const LocalPreloader: React.FC<IProps> = ({color}) => {
	return (
		<LocalWrapper className="loader-wrapper" color={color}>
			<CircularProgress className="loader" color="secondary" />
		</LocalWrapper>
	);
};

export const Preloader: React.FC = () => {
	return (
		<Wrapper className="loader-wrapper">
			<CircularProgress className="loader" color="secondary" />
		</Wrapper>
	);
};
