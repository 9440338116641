import React, {useCallback, useState} from "react";
import {Popover} from "@mui/material";
import {IconShare, IconTwitter} from "components/Icons";
import {ConnextraType, createConnextraScriptTag} from "modules/utils/Analytics";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import styled from "@emotion/styled";
import {PureButton} from "components/Common";
import {IconFacebook} from "components/Icons/IconFacebook";
import {useIsMobile} from "modules/utils/hooks";
import {LanguagesShort, ShareTarget, ShareType} from "modules/enums";
import {share} from "modules/utils/Sharing";
import {IS_SHARING_ENABLED, SHARE_TRANSLATIONS_MAP} from "modules/constants";
import {useTranslation} from "react-i18next";

const ActionButton = styled(PureButton)`
	width: 38px;
	height: 38px;
	background: ${({theme}) => theme.background.grey};
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
`;

const ShareContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 6px;
	padding: 12px;

	button {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const BottomSheetSharing: React.FC = () => {
	const {i18n, t} = useTranslation();
	const language = i18n.language as LanguagesShort;
	const translationText = t(SHARE_TRANSLATIONS_MAP.general);

	const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const user = useSelector(getUser);
	const {isMobileShare} = useIsMobile();

	const onClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
	};

	const onBeforeShare = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			if (isMobileShare) {
				void share({
					target: ShareTarget.Native,
					type: ShareType.General,
					ln: language,
					message: translationText,
				});
				createConnextraScriptTag(ConnextraType.SHARE, user);
				return;
			}

			setAnchorEl(event.currentTarget);
			setIsOpen(true);
		},
		[user, isMobileShare, language, translationText]
	);

	const onShare = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			const shareTarget = event.currentTarget.dataset.target as ShareTarget;

			if (!shareTarget) {
				return;
			}

			void share({
				target: shareTarget,
				type: ShareType.General,
				ln: language,
				message: translationText,
			});
			onClose();
			createConnextraScriptTag(ConnextraType.SHARE, user);
		},
		[user, language, translationText]
	);

	if (!IS_SHARING_ENABLED) {
		return null;
	}

	return (
		<React.Fragment>
			<ActionButton onClick={onBeforeShare}>
				<IconShare width={22} height={22} />
			</ActionButton>
			<Popover
				open={isOpen}
				anchorEl={anchorEl}
				onClose={onClose}
				anchorOrigin={{
					vertical: -60,
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}>
				<ShareContainer>
					<PureButton onClick={onShare} data-target={ShareTarget.Twitter}>
						<IconTwitter color="#222222" height={20} />
					</PureButton>
					<PureButton onClick={onShare} data-target={ShareTarget.Facebook}>
						<IconFacebook color="#222222" height={20} />
					</PureButton>
				</ShareContainer>
			</Popover>
		</React.Fragment>
	);
};
