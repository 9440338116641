import React, {useCallback} from "react";
import {MenuButton, MenuLink, MenuList, MenuSubLink} from "components/Common";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {toggleMenu, toggleScoringModal} from "modules/actions";

const SubWrapper = styled("div")`
	padding-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const MenuLogged: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const openScoringModal = useCallback(() => {
		dispatch(toggleMenu(false));
		dispatch(toggleScoringModal(true));
	}, [dispatch]);

	return (
		<MenuList>
			<MenuLink to="/">{t("menu.gameplay")}</MenuLink>
			<MenuLink to="/leaderboard">{t("menu.leaderboard")}</MenuLink>
			<MenuLink to="/leagues">{t("menu.leagues.view")}</MenuLink>
			<SubWrapper>
				<MenuSubLink to="/leagues/join">{t("menu.leagues.join")}</MenuSubLink>
				<MenuSubLink to="/leagues/create">{t("menu.leagues.create")}</MenuSubLink>
			</SubWrapper>
			<MenuButton onClick={openScoringModal}>{t("menu.scoring")}</MenuButton>
			<MenuLink to="/help">{t("menu.help")}</MenuLink>
		</MenuList>
	);
};
