import {LocalStorageService} from "modules/utils/LocalStorageService";

export abstract class PredictSavedModalService {
	private static LOCALSTORAGE_KEY = "voted_contests";
	public static CheckMatchId(matchId: number | undefined): boolean {
		if (!matchId) {
			return false;
		}
		return this.getVotedMatches().includes(matchId);
	}

	public static StoreMatchId(matchId: number | undefined): void {
		if (!matchId) {
			return;
		}

		const matches = this.getVotedMatches().filter((id) => id !== matchId);
		matches.push(matchId);
		LocalStorageService.SET(this.LOCALSTORAGE_KEY, JSON.stringify(matches));
	}

	private static getVotedMatches(): number[] {
		return JSON.parse(LocalStorageService.GET(this.LOCALSTORAGE_KEY) || "[]") as number[];
	}
}
