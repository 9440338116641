import styled from "@emotion/styled";
import {PureButton} from "components/Common/Buttons/PureButton";

export const PredictButtonBlock = styled(PureButton)`
	position: relative;
	height: 79px;
	width: 100%;

	color: ${({theme}) => theme.text.color.white};
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;

	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;

	&:after {
		content: "";
		position: absolute;
		left: -3px;
		top: -3px;
		right: -3px;
		bottom: -3px;
		border: 1px solid #ffffff;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		border-radius: 6px;
		z-index: 2;
	}

	&.picked,
	&:focus:not(.complete),
	&:active:not(.complete) {
		background-color: ${({theme}) => theme.actionColors.picks};
		color: ${({theme}) => theme.text.color.dark} !important;
	}

	&.correct {
		background: ${({theme}) => theme.actionColors.correct};
		color: ${({theme}) => theme.text.color.dark} !important;
	}

	&.incorrect {
		background: ${({theme}) => theme.actionColors.incorrect};
		color: ${({theme}) => theme.text.color.white} !important;
	}

	span {
		max-width: 160px;
		padding: 0 6px;
	}

	&:disabled {
		opacity: 1 !important;
		pointer-events: none;
		color: ${({theme}) => theme.text.color.white};
	}
`;

export const PointsLabel = styled("div")`
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 4;
	transform: translate(-50%, -50%);
	padding: 3px 8px;
	background: #ffffff;
	border: 1px solid #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 21px;

	color: ${({theme}) => theme.text.color.dark};
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	text-transform: capitalize;
`;

export const Chip = styled("div")`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 5;
	transform: translate(50%, -50%);
	width: 40px;
	height: 40px;

	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -65%);
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 10px;

		&.hundred {
			font-size: 10px;
		}

		&.thousand {
			font-size: 8px;
		}
	}
`;
